import { Grid, GridItem, Flex, Box, Text, Heading } from "@chakra-ui/react";
import StatsCard from "../StatsCard/StatsCard";
import React from "react";
import DateRangePicker from "../DateRangePicker/DateRangePicker";

type KPI = {
  title: string;
  stat: string;
  helpText?: string;
  arrowType?: "increase" | "decrease";
};

interface GridPageOverviewProps {
  heading: string;
  subheading: string;
  mainKPI?: KPI;
  secondaryKPI?: KPI;
  stats?: KPI[];
  showDateRangePicker?: boolean;
  endElement?: React.ReactNode;
}
function GridPageOverview({
  heading,
  subheading,
  mainKPI,
  secondaryKPI,
  stats,
  showDateRangePicker,
  endElement,
}: GridPageOverviewProps) {
  const getEndElementColSpan = () => {
    let colSpan = 2;
    if (!mainKPI) colSpan++;
    if (!secondaryKPI) colSpan++;
    if (!stats) colSpan++;
    return colSpan;
  };
  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
        md: "repeat(7, 1fr)",
      }}
      gap={4}
      w={"100%"}
      marginLeft={36}
      marginTop={8}
      textColor={"rgba(255,255,255,0.87)"}
      maxW={"fit-content"}
    >
      <GridItem w="100%" colSpan={{ base: 1, sm: 2, md: 2 }}>
        <Heading as={"h2"} paddingLeft={12}>
          {heading}
        </Heading>
        {showDateRangePicker ? (
          <DateRangePicker />
        ) : (
          <Heading size={"lg"} as={"h4"} minH={"36px"}>
            {subheading}
          </Heading>
        )}
      </GridItem>
      {mainKPI && (
        <GridItem w="100%">
          <Flex flexDirection={"column"}>
            <Text fontSize={"4xl"} fontWeight={"bold"}>
              {mainKPI.stat}
            </Text>
            <Box fontSize={"sm"}>{mainKPI.helpText}</Box>
          </Flex>
        </GridItem>
      )}
      {secondaryKPI && (
        <GridItem w="100%">
          <Flex flexDirection={"column"}>
            <Text fontSize={"4xl"} fontWeight={"bold"}>
              {secondaryKPI.stat}
            </Text>
            <Box fontSize={"sm"}>{secondaryKPI.helpText}</Box>
          </Flex>
        </GridItem>
      )}
      {stats &&
        stats.map((stat, index) => (
          <GridItem w="100%" key={index}>
            <StatsCard
              title={stat.title}
              stat={stat.stat}
              helpText={stat.helpText}
              arrowType={stat.arrowType}
            />
          </GridItem>
        ))}
      {endElement && (
        <GridItem w="100%" colSpan={getEndElementColSpan()}>
          <Flex justifyContent={"flex-end"} marginTop={"1rem"} marginX={"3rem"}>
            {endElement}
          </Flex>
        </GridItem>
      )}
    </Grid>
  );
}

export default GridPageOverview;
