import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  ChakraProvider,
  Flex,
  IconButton,
  Select,
  Text,
  useOutsideClick,
  Button,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  DateRangeOptions,
  DateState,
  filterDatesSlice,
} from "../../redux/filterDatesSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { formatISODate, getLocalDate } from "../../utils/dateUtils";
import {
  Calendar,
  CalendarControls,
  CalendarDays,
  CalendarDefaultTheme,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from "../Calendar";

function DateRangePicker() {
  const { getStartDate, getEndDate, getOption } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  const option = useAppSelector(getOption);
  const dispatch = useAppDispatch();

  const { setOption, shift, update } = filterDatesSlice.actions;

  const [dates, setDates] = useState<CalendarValues>({
    start: getLocalDate(startDate),
    end: getLocalDate(endDate),
  });

  const [leftButtonHover, setLeftButtonHover] = useState<boolean>(false);
  const [rightButtonHover, setRightButtonHover] = useState<boolean>(false);

  const [selectingDates, setSelectingDates] = useState<boolean>(false);

  const calendarRef = React.useRef(null);
  useOutsideClick({
    ref: calendarRef,
    handler: () => setSelectingDates(false),
    enabled: selectingDates,
  });

  const handleSelectDate = (values: any) => {
    if (values.start && values.end) {
      dispatch(
        update({
          start_date: formatISODate(values.start),
          end_date: formatISODate(values.end),
        } as DateState),
      );
    }
    setDates(values);
  };

  const handleDoneClick = () => {
    setSelectingDates(false);
  };

  return (
    <Box ref={calendarRef} position={"relative"}>
      <Flex gap={4} alignItems={"center"} flexDirection={"column"}>
        <Flex gap={2} alignItems={"center"}>
          <IconButton
            size="sm"
            backgroundColor="gray.700"
            aria-label="prev-date"
            icon={
              <ChevronLeftIcon
                width="20px"
                height="20px"
                color={leftButtonHover ? "teal.300" : "white"}
              />
            }
            onMouseEnter={() => setLeftButtonHover(true)}
            onMouseLeave={() => setLeftButtonHover(false)}
            onClick={() => dispatch(shift({ direction: "backward" }))}
            borderColor="gray.500"
            _hover={{ bg: "gray.600" }}
          />
          <Flex
            alignItems={"center"}
            flexDirection={["column", "row"]}
            cursor="pointer"
            onClick={() => setSelectingDates(true)}
          >
            <Text fontWeight={"bold"} as="span" mr={1}>
              {startDate}
            </Text>
            {" - "}
            <Text fontWeight={"bold"} as="span" ml={1}>
              {endDate}
            </Text>
          </Flex>
          <IconButton
            size="sm"
            backgroundColor="gray.700"
            aria-label="next-date"
            icon={
              <ChevronRightIcon
                width="20px"
                height="20px"
                color={rightButtonHover ? "teal.300" : "white"}
              />
            }
            onMouseEnter={() => setRightButtonHover(true)}
            onMouseLeave={() => setRightButtonHover(false)}
            onClick={() => dispatch(shift({ direction: "forward" }))}
            borderColor="gray.500"
            _hover={{ bg: "gray.600" }}
          />
        </Flex>
        {/*<Select*/}
        {/*  size="sm"*/}
        {/*  rounded="md"*/}
        {/*  defaultValue={option}*/}
        {/*  onChange={(e) => {*/}
        {/*    dispatch(*/}
        {/*      setOption(*/}
        {/*        DateRangeOptions[*/}
        {/*          e.target.value as keyof typeof DateRangeOptions*/}
        {/*        ],*/}
        {/*      ),*/}
        {/*    );*/}
        {/*    setSelectingDates(false);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {Object.values(DateRangeOptions).map((option) => (*/}
        {/*    <option key={option} value={option} style={{ width: "100px" }}>*/}
        {/*      {option}*/}
        {/*    </option>*/}
        {/*  ))}*/}
        {/*</Select>*/}
      </Flex>

      {selectingDates && (
        <Box
          onKeyDown={(e) => {
            // Close the date picker when the escape or enter key is pressed
            if (e.key === "Escape" || e.key === "Enter") {
              setSelectingDates(false);
            }
          }}
          style={{ paddingLeft: "2rem" }}
        >
          <ChakraProvider theme={CalendarDefaultTheme}>
            <Calendar
              value={dates}
              onSelectDate={handleSelectDate}
              allowSelectSameDay={true}
            >
              <CalendarControls>
                <CalendarPrevButton />
                <CalendarNextButton />
              </CalendarControls>

              <CalendarMonths>
                <CalendarMonth>
                  <CalendarMonthName />
                  <CalendarWeek />
                  <CalendarDays />
                </CalendarMonth>
              </CalendarMonths>

              <Button
                colorScheme="teal"
                size="sm"
                onClick={handleDoneClick}
                position="absolute"
                bottom="5px"
                right="5px"
              >
                Done
              </Button>
            </Calendar>
          </ChakraProvider>
        </Box>
      )}
    </Box>
  );
}

export default DateRangePicker;
