import { Box, Image, Link } from "@chakra-ui/react";
import React from "react";

interface LogoProps {
  width?: number;
  redirectUrl?: string;
}

export const Logo = ({
  width = 48, // Default width in pixels
  redirectUrl = "/login",
}: LogoProps): React.ReactElement => (
  <Link href={redirectUrl}>
    <Box display="flex" justifyContent="center">
      <Image
        width={`${width}px`}
        height={`${width}px`}
        minWidth={`${width}px`}
        src={
          new URL("../../assets/images/stockyCart.png", import.meta.url).href
        }
        objectFit="contain"
      />
    </Box>
  </Link>
);
