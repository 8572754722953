import { Order, Organisation } from "../../types";
import { Badge, Checkbox, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import OrderDetailModal from "./OrderDetailModal";
import AvatarEntry from "../../components/AvatarEntry/AvatarEntry";
import * as React from "react";

interface OrderViewEntryProps {
  itemIndex: number;
  order: Order;
  organisations: Organisation[];
  supplierOrCustomerName: string;
  isSupplierView?: boolean;
  showSelect?: boolean;
  isChecked?: boolean;
  setChecked?: (value: boolean) => void;
}
export default function OrderViewEntry({
  itemIndex,
  order,
  supplierOrCustomerName,
  isSupplierView = false,
  showSelect = false,
  isChecked,
  setChecked,
}: OrderViewEntryProps): React.ReactElement {
  const [bgColor, setBgColor] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getBadgeColor = (status: string | undefined) => {
    switch (status) {
      case "PENDING":
        return "yellow";
      case "PROCESSING":
        return "green";
      case "IN_TRANSIT":
        return "blue";
      case "DELIVERED":
        return "grey";
      default:
        return "red";
    }
  };

  return (
    <Tr
      key={itemIndex}
      style={{ cursor: "pointer" }}
      onClick={() => {
        onOpen();
        setBgColor("");
      }}
      bg={bgColor}
      onMouseEnter={() => setBgColor("lightgray")}
      onMouseLeave={() => setBgColor("")}
    >
      {isOpen && (
        <OrderDetailModal
          isOpen={isOpen}
          onClose={onClose}
          order={order}
          supplierOrCustomerName={supplierOrCustomerName}
          isSupplierView={isSupplierView}
        />
      )}

      {showSelect && (
        <Td fontSize="sm">
          <Checkbox
            isChecked={isChecked}
            colorScheme="teal"
            onChange={(e) => {
              e.stopPropagation();
              if (setChecked) setChecked(!isChecked);
              onClose();
            }}
          />
        </Td>
      )}
      <Td fontSize="sm">{order.id}</Td>
      <Td fontSize="sm">
        <AvatarEntry name={supplierOrCustomerName} />{" "}
      </Td>
      <Td fontSize="sm">{order.order_date.slice(0, 10)}</Td>
      <Td fontSize="sm">{order.delivery_date.slice(0, 10)}</Td>
      <Td fontSize="sm">
        <Badge colorScheme={getBadgeColor(order.status)}>{order.status}</Badge>
      </Td>
      <Td>${Number(order.total).toFixed(2)}</Td>
    </Tr>
  );
}
