import React, { useEffect, useState } from "react";
import { Badge, Box, HStack, Text } from "@chakra-ui/react";
import { Invoice } from "../../types";

const InvoicePaymentStatusUpdateBadge = ({
  invoice,
  amountAssigned,
  originalAmountAssigned,
  amountAlreadyPaid,
}: {
  invoice: Invoice;
  amountAssigned: string;
  originalAmountAssigned: string;
  amountAlreadyPaid: number;
}): React.ReactElement => {
  const [amount, setAmount] = useState<number>(Number(amountAssigned));
  const [totalPaid, setTotalPaid] = useState<number>(
    amountAlreadyPaid + Number(amountAssigned),
  );

  useEffect(() => {
    setAmount(Number(amountAssigned));
    setTotalPaid(Number(amountAlreadyPaid) + Number(amountAssigned));
  }, [amountAssigned]);

  const getUpdateBadge = () => {
    if (Number(totalPaid).toFixed(2) === Number(invoice.total).toFixed(2)) {
      return <Badge colorScheme="green">PAID</Badge>;
    } else if (totalPaid > Number(invoice.total)) {
      return <Badge colorScheme="red">OVERPAID</Badge>; // should be prevented
    } else if (
      invoice.total_due &&
      Number(totalPaid).toFixed(2) === Number(invoice.total_due).toFixed(2)
    ) {
      return <Badge colorScheme="blue">BALANCED</Badge>;
    } else if (amount > 0.0001) {
      return <Badge colorScheme="yellow">PARTIAL</Badge>;
    } else {
      return <Badge colorScheme="red">UNPAID</Badge>;
    }
  };

  return (
    <>
      {amountAssigned &&
        amountAssigned.length > 0 &&
        Number(originalAmountAssigned).toFixed(2) !==
          Number(amountAssigned).toFixed(2) && (
          <HStack>
            <Box h={"18px"}>
              <Text fontSize={"xs"} color={"gray.500"}>
                {"->"}
              </Text>
            </Box>
            {getUpdateBadge()}
          </HStack>
        )}
    </>
  );
};

export default InvoicePaymentStatusUpdateBadge;
