import { format } from "date-fns";
import { CalendarDate } from "../components/Calendar";

export const formatISODate = (date: Date | CalendarDate): string => {
  return format(date, "yyyy-MM-dd");
};

export const getLocalDate = (dateString: any): Date => {
  let [year, month, day] = dateString.split("-").map(Number);
  // Note: JavaScript counts months from 0 to 11. January is 0, February is 1, and so on.
  return new Date(year, month - 1, day);
};
