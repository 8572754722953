import React, { Fragment } from "react";
import {
  Document,
  Page,
  StyleSheet,
  Svg,
  Text,
  View,
  Line,
} from "@react-pdf/renderer";
import { Order, OrderItem, Product, ProductUnit } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import { getLocationAndOrgName } from "../../utils/invoiceUtils";

interface OrderListPdfProps {
  orders: Order[];
}

const OrderListPdf = ({ orders }: OrderListPdfProps): React.ReactElement => {
  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },

    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },

    titleContainer: { flexDirection: "row", marginTop: 24 },

    logo: { width: 90 },

    reportTitle: { fontSize: 16, textAlign: "center" },

    addressTitle: { fontSize: 12, fontStyle: "bold" },

    invoice: { fontWeight: "bold", fontSize: 20 },

    invoiceNumber: { fontSize: 11, fontWeight: "bold" },

    address: { fontWeight: 400, fontSize: 10 },

    theader: {
      marginTop: 20,
      fontSize: 10,
      fontStyle: "bold",
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: "#DEDEDE",
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    theader2: {
      flex: 2,
      borderRightWidth: 0,
      borderBottomWidth: 1,
      minWidth: "50%",
    },

    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    total: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1.5,
      borderColor: "whitesmoke",
      borderBottomWidth: 1,
    },

    tbody2: { flex: 2, borderRightWidth: 1, minWidth: "50%" },
  });

  const getOrderName = (order: Order) => {
    return order.location &&
      order.organisation_id !== order.supplier_organisation_id
      ? getLocationAndOrgName(order.location)
      : order.location?.name;
  };

  const OrderTitle = ({ order }: { order: Order }) => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <Text style={styles.addressTitle}>Order No.: {order.id}</Text>
        <Text style={styles.addressTitle}>For: {getOrderName(order)}</Text>
        <Text style={styles.addressTitle}>
          Delivery date: {order.delivery_date.slice(0, 10)}
        </Text>
      </View>
    </View>
  );

  const TableHead = () => (
    <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
      <View style={[styles.theader, styles.theader2]}>
        <Text>Item</Text>
      </View>
      <View style={styles.theader}>
        <Text>Unit</Text>
      </View>
      <View style={styles.theader}>
        <Text>Qty</Text>
      </View>
    </View>
  );

  const TableBody = ({ order }: { order: Order }) => (
    <>
      {order.order_items.map((item: OrderItem) => (
        <Fragment key={item.id}>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <View style={[styles.tbody, styles.tbody2]}>
              <Text>{item.product.name}</Text>
            </View>
            <View style={styles.tbody}>
              <Text>{getProductUnitLabel(item.product_unit)} </Text>
            </View>
            <View style={styles.tbody}>
              <Text>{item.quantity ? item.quantity : ""}</Text>
            </View>
          </View>
        </Fragment>
      ))}
    </>
  );

  const Divider = () => (
    <View
      style={{
        ...styles.spaceBetween,
        marginTop: 10,
        marginBottom: 16,
      }}
    >
      <Svg height="10" width="100%" style={{ alignContent: "center" }}>
        <Line
          x1="0"
          y1="5"
          x2="600"
          y2="5"
          strokeWidth={1}
          stroke="rgba(0,0,0,0.32)"
        />
      </Svg>
    </View>
  );

  const TotalSummary = ({ orders }: OrderListPdfProps) => {
    const unitIdToQuantityMap = new Map<number, number>();
    const unitIdToProductUnit = new Map<number, ProductUnit | undefined>();
    const unitIdToProduct = new Map<number, Product>();
    const unitIdToOrderItems = new Map<number, OrderItem[]>();
    orders.forEach((order) => {
      order.order_items.forEach((item) => {
        const quantity = unitIdToQuantityMap.get(item.product_unit_id) || 0;
        unitIdToQuantityMap.set(
          item.product_unit_id,
          quantity + Number(item.quantity),
        );
        unitIdToProductUnit.set(item.product_unit_id, item.product_unit);
        unitIdToProduct.set(item.product_unit_id, item.product);
        const ordersItems = unitIdToOrderItems.get(item.product_unit_id) || [];
        ordersItems.push({
          ...item,
          product: {
            ...item.product,
            name: "   ".concat(
              ` -- ${getLocationAndOrgName(order.location)} - ${
                item.quantity
              } x ${getProductUnitLabel(item.product_unit)}`,
            ),
          },
          product_unit: undefined,
          quantity: 0,
        } as OrderItem);
        unitIdToOrderItems.set(item.product_unit_id, ordersItems);
      });
    });

    const orderItemTotals: OrderItem[] = Array.from(
      unitIdToQuantityMap.entries(),
    )
      .sort((a, b) => {
        const unitIdA = a[0];
        const unitIdB = b[0];
        const productANameAndUnit = `${unitIdToProduct.get(unitIdA)
          ?.name} ${unitIdA}`;
        const productBNameAndUnit = `${unitIdToProduct.get(unitIdB)
          ?.name} ${unitIdB}`;
        return productANameAndUnit.localeCompare(productBNameAndUnit);
      })
      .map(([unitId, quantity]) => {
        const totalOrder = {
          id: 0,
          product: unitIdToProduct.get(unitId),
          product_unit: unitIdToProductUnit.get(unitId),
          product_unit_id: unitId,
          quantity: quantity,
        } as OrderItem;
        return totalOrder;
      });

    const orderItemTotalWithOrderQuantities: OrderItem[] = [];
    orderItemTotals.forEach((item) => {
      orderItemTotalWithOrderQuantities.push(item);
      const orderItems = unitIdToOrderItems.get(item.product_unit_id) || [];
      orderItemTotalWithOrderQuantities.push(...orderItems);
    });

    return (
      <View style={{ width: "100%" }}>
        <View style={styles.titleContainer}>
          <Text style={styles.addressTitle}>Total Quantities</Text>
        </View>
        <TableHead />
        <TableBody
          order={
            { id: 0, order_items: orderItemTotalWithOrderQuantities } as Order
          }
        />
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {orders.map((order) => (
          <View key={order.id}>
            <OrderTitle order={order} />
            <TableHead />
            <TableBody order={order} />
            <Divider />
          </View>
        ))}
        <TotalSummary orders={orders} />
      </Page>
    </Document>
  );
};

export default OrderListPdf;
