import { Box, Text, Skeleton, Stack } from "@chakra-ui/react";
import React from "react";

interface PageProps {
  isLoading: boolean;
  isError?: boolean;
  children?: React.ReactNode;
}
function Page({ isLoading, isError, children }: PageProps) {
  return (
    <Box
      p={2}
      my={10}
      mx={[0, 0, 10]}
      minH="300px"
      bg={"gray.200"}
      border="1px solid"
      borderColor="gray.400"
      rounded="md"
      boxShadow="lg"
      overflow="hidden"
    >
      {isLoading ? (
        <Stack>
          {/*TODO: implement skeletons for height of display - get view window height and divide by size*/}
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
        </Stack>
      ) : (
        <>
          {isError ? (
            <Text>
              Error loading data - please refresh the page or contact support if
              the issue persists.
            </Text>
          ) : (
            children
          )}
        </>
      )}
    </Box>
  );
}

export default Page;
