import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Link,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Card,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Logo } from "../components/Logo/Logo";
import { PasswordField } from "../components/PasswordField/PasswordField";
import {
  usePostRegisterUserMutation,
  usePostResetPasswordMutation,
} from "../redux/apiSlice";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";

function SignUpPage() {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [emailKey, setEmailKey] = useState("");
  const [urlKey, setUrlKey] = useState("");
  const [token, setToken] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (location.search.includes("url_key")) {
      setIsPasswordReset(true);
      const urlParams = new URLSearchParams(location.search);
      const urlKey = urlParams.get("url_key");
      const token = urlParams.get("token");
      setUrlKey(urlKey || "");
      setToken(token || "");
    }
  }, [location]);

  const [postRegister] = usePostRegisterUserMutation();
  const [postReset] = usePostResetPasswordMutation();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (passwordError) {
      checkPassword(e);
    }
  };

  const checkPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegex.test(e.target.value)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleSubmit = () => {
    if (isPasswordReset) {
      postReset({
        email: email,
        password: password,
        email_key: emailKey,
        url_key: urlKey,
        token: token,
      })
        .unwrap()
        .then(() => {
          toast({
            title: "Registration success!",
            description: "Redirecting to sign in page.",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        })
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          toast({
            title: "Reset password failed.",
            description:
              "Please try again later and contact support if the issue persists.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else {
      postRegister({ email: email, password: password })
        .unwrap()
        .then(() => {
          toast({
            title: "Registration success!",
            description: "Redirecting to sign in page.",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        })
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          toast({
            title: "Registration failed.",
            description:
              "Please try again later and contact support if the issue persists.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Container w={"100%"} h={"100%"}>
      <Card
        marginTop={"2rem"}
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
        bg={useColorModeValue("gray.100", "gray.900")}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Logo />
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>
                {isPasswordReset ? "Reset" : "Register"} your account password
              </Heading>
              <Text>
                Don't have an account?{" "}
                <Link href="mailto:support@stocky-ai.com">Email support</Link>{" "}
                to request an account.
              </Text>
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <PasswordField
                  onChange={handlePasswordChange}
                  isInvalid={passwordError}
                  onBlur={checkPassword}
                />
                {passwordError && (
                  <Text color="red.500" fontSize="sm">
                    Password must have at least 8 characters, 1 uppercase
                    letter, 1 lowercase letter, 1 number, and 1 special
                    character
                  </Text>
                )}
                {isPasswordReset && (
                  // Add input for 6 digit code
                  <FormControl>
                    <FormLabel htmlFor="email_key">
                      Reset Code (in Email)
                    </FormLabel>
                    <Input
                      id="email_key"
                      type="email_key"
                      onChange={(e) => setEmailKey(e.target.value)}
                    />
                  </FormControl>
                )}
              </Stack>
              <Stack spacing="6">
                <Button
                  isDisabled={password.length < 8 || passwordError}
                  onClick={handleSubmit}
                >
                  {isPasswordReset ? "Reset" : "Register"}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Card>
    </Container>
  );
}

export default SignUpPage;
