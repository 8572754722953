import type { ButtonProps } from "@relume_io/relume-ui";
import clsx from "clsx";
import React from "react";
import { RxChevronRight } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";

type ImageProps = {
  src: string;
  alt?: string;
};

type Anchor = {
  url: string;
  number: string;
  title: string;
};

type Feature = {
  anchor: Anchor;
  tagline: string;
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

type Props = {
  features: Feature[];
};

export type FeatureScrollProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

const calculatingCosts = new URL(
  "../../assets/images/calculating-costs.jpg",
  import.meta.url,
).href;
const stockCounter = new URL(
  "../../assets/images/stock-counter.jpg",
  import.meta.url,
).href;
const invoicingMan = new URL(
  "../../assets/images/invoicing-man.jpg",
  import.meta.url,
).href;

export const FeatureScroll = (props: FeatureScrollProps) => {
  const { features } = {
    ...FeatureScrollDefaults,
    ...props,
  } as Props;
  return (
    <section className="feature-scroll-section">
      <div className="sticky top-0">
        {features.map((feature, index) => (
          <React.Fragment key={index}>
            <div className="relative -top-32 h-0" />
            <div
              key={index}
              className={clsx(
                "relative lg:h-screen border-t border-border-primary bg-neutral-white md:pb-14 lg:sticky lg:pb-0",
                { "top-16 pb-8 lg:mb-16": index === 0 },
                { "lg:top-16 lg:-mt-16 lg:mb-0": index === 1 },
                { "lg:top-16 lg:mb-0": index === 2 },
              )}
            >
              <FeatureCard {...feature} />
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

const FeatureCard = (feature: Feature) => {
  const navigate = useNavigate();
  return (
    <div
      className="feature-scroll-card-container px-[5%] h-fit lg:h-screen"
      id={feature.anchor.url.replace("#", "")}
    >
      <div className="container">
        <a
          href={feature.anchor.url}
          className="flex h-16 w-full items-center underline"
        >
          <span className="mr-5 font-semibold md:mr-6 md:text-md">
            {feature.anchor.number}
          </span>
          <h1 className="font-semibold md:text-md">{feature.anchor.title}</h1>
        </a>
        <div className="pb-16 py-8 md:py-10 lg:py-12">
          <div className="grid grid-cols-1 gap-y-12 md:items-center md:gap-x-12 lg:grid-cols-2 lg:gap-x-20">
            <div>
              <p className="mb-3 font-semibold md:mb-4">{feature.tagline}</p>
              <h2 className="mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl">
                {feature.heading}
              </h2>
              <p className="md:text-md">{feature.description}</p>
              <div className="mt-6 flex items-center gap-x-4 md:mt-8">
                {/*{feature.buttons.map((button, index) => (*/}
                {/*  <Button key={index} {...button}>*/}
                {/*    {button.title}*/}
                {/*  </Button>*/}
                {/*))}*/}
                <Button
                  colorScheme="teal"
                  onClick={() => navigate("/request-demo")}
                >
                  Request demo
                </Button>
              </div>
            </div>
            <div className="relative">
              <img
                src={feature.image.src}
                className="h-[25rem] w-full object-cover sm:h-[30rem] lg:h-[60vh]"
                alt={feature.image.alt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FeatureScrollDefaults: FeatureScrollProps = {
  features: [
    {
      anchor: {
        url: "#inventory-management-feature-scroll",
        number: "01",
        title: "Inventory Management",
      },
      tagline: "Accurate, Reliable, Dependable, & Traceable",
      heading:
        "Real-time inventory management with built-in traceability and FIFO tracking",
      description:
        "Inventory is at the core of your food and beverage business. " +
        "Our inventory management system is designed to help you manage your inventory efficiently and accurately. " +
        "With real-time inventory tracking, you can keep track of your stock levels and make informed decisions about your business - from order fulfillment to stock replenishment, and short-term planning to long-term forecasting.",
      buttons: [
        { title: "Button", variant: "secondary" },
        {
          title: "Button",
          variant: "link",
          size: "link",
          iconRight: <RxChevronRight />,
        },
      ],
      image: {
        src: stockCounter,
        alt: "Stock counting",
      },
    },
    {
      anchor: {
        url: "#",
        number: "02",
        title: "Order & Invoice Management",
      },
      tagline: "Effortless, Efficient, and Error-Free Transactions",
      heading: "Send and receive goods and payments with ease and precision",
      description:
        "Remove the hassle of paper-based ordering and invoicing with our streamlined ordering platform. " +
        "Increase order accuracy and get feedback from your customers instantly. Manage your accounts and ensure that your invoices are paid on time.",
      buttons: [
        { title: "Button", variant: "secondary" },
        {
          title: "Button",
          variant: "link",
          size: "link",
          iconRight: <RxChevronRight />,
        },
      ],
      image: {
        src: invoicingMan,
        alt: "Man invoicing with pen & paper",
      },
    },
    {
      anchor: {
        url: "#",
        number: "03",
        title: "Product Pricing & Profit Margin Calculator",
      },
      tagline: "Guaranteeing Growth and Profitability",
      heading: "Calculate your product pricing and protect your profit margins",
      description:
        "With our product pricing and profit margin calculator, you can seamlessly calculate your cost and sales prices " +
        "to protect your profit margins, even in the most complex scenarios. Ordering containers of produce, with " +
        "potential demurrage charges and partial taxes? No problem. " +
        "Need to factor in shipping costs? We've got you covered. Leave the calculators and spreadsheets behind and let our " +
        "AI-powered system do the work for you.",
      buttons: [
        { title: "Button", variant: "secondary" },
        {
          title: "Button",
          variant: "link",
          size: "link",
          iconRight: <RxChevronRight />,
        },
      ],
      image: {
        src: calculatingCosts,
        alt: "Person calculating prices",
      },
    },
  ],
};
