import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const Calendar: ComponentMultiStyleConfig = {
  parts: ["calendar", "months"],

  baseStyle: {
    calendar: {
      position: "absolute",
      w: "min-content",
      borderWidth: "1px",
      rounded: "md",
      shadow: "xl",
      bg: "white",
      textColor: "gray.800",
      zIndex: 1,
      backgroundColor: "gray.50",
      left: "0px",
      top: "100px",
    },

    months: {
      p: 4,
      w: "100%",
      gridTemplateColumns: "1fr",
    },
  },
};
