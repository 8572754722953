import {
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import React from "react";

interface StatsCardProps {
  title: string;
  stat: string;
  helpText?: string;
  arrowType?: "increase" | "decrease";
}

function StatsCard(props: StatsCardProps): React.ReactElement {
  const { title, stat } = props;
  return (
    <Stat
      w={props.helpText ? "10rem" : "8rem"}
      h={props.helpText ? "8rem" : "4rem"}
      px={4}
      py={2}
      shadow={"xl"}
      border={"1px solid"}
      borderColor={"gray.400"}
      rounded={"lg"}
      bg={"gray.200"}
      textColor={"gray.800"}
    >
      <StatLabel fontWeight={"medium"} isTruncated={false}>
        {title}
      </StatLabel>
      <StatNumber
        noOfLines={1}
        maxW={"100%"}
        fontSize={"lg"}
        fontWeight={"medium"}
        isTruncated={false}
      >
        {stat}
      </StatNumber>
      {props.helpText && (
        <StatHelpText>
          <>
            {props.arrowType ? (
              <StatArrow type={props.arrowType} />
            ) : (
              () => null
            )}
          </>
          {props.helpText}
        </StatHelpText>
      )}
    </Stat>
  );
}

export default StatsCard;
