import React from "react";
import { FormLabel, Input, Select, Stack } from "@chakra-ui/react";
import { Address } from "../../types";

interface AddressCreateProps {
  updateAddress: (address: Address) => void;
}
const AddressCreate = ({ updateAddress }: AddressCreateProps) => {
  const [street1, setStreet1] = React.useState<string>("");
  const [street2, setStreet2] = React.useState<string | null>(null);
  const [city, setCity] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");
  const [postalCode, setPostalCode] = React.useState<string>("");
  const [country, setCountry] = React.useState<string>("Canada");

  const handleUpdateField = (field: string, value: string) => {
    switch (field) {
      case "street1":
        setStreet1(value);
        break;
      case "street2":
        setStreet2(value);
        break;
      case "city":
        setCity(value);
        break;
      case "state":
        setState(value);
        break;
      case "postalCode":
        setPostalCode(value);
        break;
      case "country":
        setCountry(value);
        break;
    }
    updateAddress({
      id: undefined,
      street_1: street1,
      street_2: street2,
      city,
      state,
      postal_code: postalCode,
      country,
    });
  };

  return (
    <>
      <Stack>
        <FormLabel mt={4}>Street 1</FormLabel>
        <Input
          placeholder="Street 1"
          value={street1}
          onChange={(e) => handleUpdateField("street1", e.target.value)}
          onBlur={(e) => handleUpdateField("street1", e.target.value)}
        />
        <FormLabel mt={4}>Street 2</FormLabel>
        <Input
          placeholder="Street 2"
          value={street2 || ""}
          onChange={(e) => handleUpdateField("street2", e.target.value)}
          onBlur={(e) => handleUpdateField("street2", e.target.value)}
        />
        <FormLabel mt={4}>City</FormLabel>
        <Input
          placeholder="City"
          value={city}
          onChange={(e) => handleUpdateField("city", e.target.value)}
          onBlur={(e) => handleUpdateField("city", e.target.value)}
        />
        <FormLabel mt={4}>State</FormLabel>
        <Input
          placeholder="State"
          value={state}
          onChange={(e) => handleUpdateField("state", e.target.value)}
          onBlur={(e) => handleUpdateField("state", e.target.value)}
        />
        <FormLabel mt={4}>Postal Code</FormLabel>
        <Input
          placeholder="Postal Code"
          value={postalCode}
          onChange={(e) => handleUpdateField("postalCode", e.target.value)}
          onBlur={(e) => handleUpdateField("postalCode", e.target.value)}
        />
        <FormLabel mt={4}>Country</FormLabel>
        <Select
          placeholder="Select Country"
          value={country}
          onChange={(e) => handleUpdateField("country", e.target.value)}
        >
          <option value="Canada">Canada</option>
          <option value="USA">USA</option>
        </Select>
      </Stack>
    </>
  );
};

export default AddressCreate;
