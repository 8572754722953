import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import { jwtDecode } from "jwt-decode";
import ErrorPage from "./ErrorPage";
import { Location, Organisation } from "../types";
import { Spinner } from "@chakra-ui/react";
import {
  checkIsHarvestPath,
  checkIsProductPath,
  checkPath,
} from "../utils/routerUtils";
import { routeAccess } from "../index";

interface PrivateRouteProps {
  children: React.ReactNode;
  route: string;
  locationFilters?: string[];
}

export const checkIfOrgHasAccess = (
  organisation: Organisation,
  route: string,
) => {
  if (organisation.id === 1) {
    // Enable all for admin
    return true;
  }
  if (Object.prototype.hasOwnProperty.call(routeAccess, route)) {
    const includedOrgs: string[] = routeAccess[route];
    for (const org of includedOrgs) {
      if (org === "ALL" || organisation.name.includes(org)) {
        return true;
      }
    }
  }
  return false;
};

export const checkIfLocationHasAccess = (
  location: Location,
  locationFilters: string[],
) => {
  // Return true by default, if filters are present, return true if any filter is present, false if none are found
  return location.organisation_id === 1 || locationFilters
    ? locationFilters.some((filter) => location[filter as keyof Location])
    : true;
};

const PrivateRoute = ({
  children,
  route,
  locationFilters,
}: PrivateRouteProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navLocation = useLocation();
  const token = useAppSelector(userSlice.selectors.getToken);
  const isAuthenticated = useAppSelector(
    userSlice.selectors.getIsAuthenticated,
  );

  const isLoginPath = checkPath(navLocation, "/login");

  const navigateToLogin = () => {
    if (!isLoginPath) {
      navigate("/login");
    }
  };

  const navigateToDashboard = () => {
    // TODO: temp for release to Klippers, update when dashboard is available
    if (currentLocation && currentLocation.is_producer) {
      if (!checkIsHarvestPath(navLocation)) {
        navigate("/harvest");
      }
    } else {
      if (!checkIsProductPath(navLocation)) {
        navigate("/products");
      }
    }
  };

  const { getCurrentLocationId, getCurrentLocation, getCurrentOrganisation } =
    userSlice.selectors;

  const [stateLoaded, setStateLoaded] = React.useState<boolean>(false);
  const [orgHasAccess, setOrgHasAccess] = React.useState<boolean>(false);

  const locationId = useAppSelector(getCurrentLocationId);
  const currentLocation = useAppSelector(getCurrentLocation);
  const currentOrganisation = useAppSelector(getCurrentOrganisation);

  useEffect(() => {
    if (currentOrganisation) {
      const hasAccess = checkIfOrgHasAccess(currentOrganisation, route);
      setOrgHasAccess(hasAccess);
      if (!hasAccess) {
        navigateToDashboard();
      }
      setStateLoaded(true);
    }
  }, [currentOrganisation, route]);

  useEffect(() => {
    let authenticated = isAuthenticated;
    let authToken = token.length > 0;
    if (token && isAuthenticated) {
      const decodedToken: { exp: number } = jwtDecode(token);
      const currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        authenticated = false;
        authToken = false;
      }
    }

    const shouldLogin = !authToken || !authenticated;

    if (shouldLogin && !isLoginPath) {
      dispatch(userSlice.actions.logout());
      navigateToLogin();
    }
    if (!shouldLogin && isLoginPath) {
      navigateToDashboard();
    }
  }, [navLocation, token, isAuthenticated]);

  useEffect(() => {
    if (currentLocation && locationFilters) {
      const locationIncluded = checkIfLocationHasAccess(
        currentLocation,
        locationFilters,
      );
      if (!locationIncluded) {
        setOrgHasAccess(false);
        navigateToDashboard();
      }
    }
  }, [currentLocation, locationFilters]);

  return (
    <>
      {!stateLoaded || !locationId || !currentLocation ? (
        <Spinner />
      ) : (
        <>
          {orgHasAccess && children ? (
            children
          ) : (
            <ErrorPage includeRoot={false} />
          )}
        </>
      )}
    </>
  );
};

export default PrivateRoute;
