import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import {
  useGetInventoryItemsQuery,
  useGetProductsQuery,
  usePostHarvestRecordMutation,
} from "../../redux/apiSlice";
import HarvestForm from "./HarvestForm";
import { useEffect, useState } from "react";
import { Harvest, HarvestItem, Product } from "../../types";
import { format } from "date-fns";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import * as Sentry from "@sentry/react";

interface HarvestModalProps {
  isOpen: boolean;
  onClose: () => void;
}
function HarvestModal({ isOpen, onClose }: HarvestModalProps) {
  const toast = useToast();

  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const { data: products } = useGetProductsQuery("");
  const { data: inventoryItems } = useGetInventoryItemsQuery(
    {
      organisationId,
    },
    { skip: !organisationId },
  );
  const [postHarvest, { isLoading: isSaving }] = usePostHarvestRecordMutation();

  const [productList, setProductList] = useState<Product[]>([]);
  const [inventoryUnitIds, setInventoryUnitIds] = useState<Set<number>>(
    new Set(),
  );
  const [harvestItems, setHarvestItems] = useState<HarvestItem[]>([]);
  const [harvestDate, setHarvestDate] = useState(
    format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  );

  useEffect(() => {
    setHarvestItems([]);
    setHarvestDate(format(new Date(), "yyyy-MM-dd'T'HH:mm"));
  }, [isOpen, onClose]);

  useEffect(() => {
    if (
      inventoryItems &&
      inventoryItems.length > 0 &&
      products &&
      products.length > 0
    ) {
      const productIds = new Set(inventoryItems.map((item) => item.product_id));
      setProductList(
        products
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((product) => productIds.has(product.id)),
      );
      setInventoryUnitIds(
        new Set(inventoryItems.map((item) => item.product_unit_id)),
      );
    }
  }, [inventoryItems, products]);

  const handleSave = async () => {
    if (!organisationId || !locationId) {
      toast({
        title: "Invalid Organisation or Location.",
        description: "Please refresh the page and try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!harvestDate || !harvestItems || harvestItems.length < 1) {
      toast({
        title: "Invalid Harvest Record.",
        description: "Please check your inputs and try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const harvest = {
      organisation_id: Number(organisationId),
      location_id: Number(locationId),
      harvest_date: harvestDate,
      harvest_items: harvestItems,
    } as Harvest;

    postHarvest(harvest)
      .unwrap()
      .then(() => {
        toast({
          title: "Harvest Record created.",
          description: "You can review this in the Harvest Records list.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        try {
          error = JSON.stringify({ error, harvest });
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Harvest Record failed to create.",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  return (
    <>
      <Modal
        size={"4xl"}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Harvest</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HarvestForm
              productList={productList}
              inventoryUnitIds={inventoryUnitIds}
              harvestItems={harvestItems}
              setHarvestItems={setHarvestItems}
              harvestDate={harvestDate}
              setHarvestDate={setHarvestDate}
              showErrors={false}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              isLoading={isSaving}
              onClick={handleSave}
              isDisabled={
                !harvestDate ||
                !harvestItems ||
                (harvestItems && harvestItems.length < 1)
              }
            >
              Save
            </Button>
            <Button
              colorScheme="red"
              variant={"ghost"}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default HarvestModal;
