import { Address, InvoiceItem } from "../../types";
import { useEffect } from "react";
import {
  Box,
  Checkbox,
  Heading,
  Input,
  Spacer,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import DataTable from "../../components/DataTable/DataTable";
import * as React from "react";
import EditableColumn from "../../components/DataTable/EditableColumn";
import { getInvoiceCredit, getInvoiceTotal } from "../../utils/invoiceUtils";
import AddressSearchOrCreate from "../../components/AddressSearchOrCreate/AddressSearchOrCreate";

interface InvoiceSummaryProps {
  supplierOrgId: number;
  supplierLocationId: number;
  buyerOrgId: number;
  buyerLocationId: number;
  invoiceItems: InvoiceItem[];
  invoiceDate: string;
  deliveryDate: string;
  invoiceTotal: number;
  totalCredit: number;
  buyerPONumber?: string;
  deliveryAddressId?: number;
  isPickup: boolean;
  deliveryNote: string;
  setInvoiceItems: (value: InvoiceItem[]) => void;
  setInvoiceDate: (value: string) => void;
  setDeliveryDate: (value: string) => void;
  setInvoiceTotal: (value: number) => void;
  setTotalCredit: (value: number) => void;
  setBuyerPONumber: (value: string) => void;
  setDeliveryAddressId: (value: number | undefined) => void;
  setIsPickup: (value: boolean) => void;
  setDeliveryNote: (value: string) => void;
  isSupplierView?: boolean;
  supplierOrCustomerName: string;
  isEditable?: boolean;
}

const StackEntry = ({ children }: { children: React.ReactNode[] }) => (
  <VStack alignItems={"start"}>
    {children && children.map((child, index) => <div key={index}>{child}</div>)}
  </VStack>
);

export default function InvoiceSummary({
  supplierOrgId,
  supplierLocationId,
  buyerOrgId,
  buyerLocationId,
  invoiceItems,
  invoiceDate,
  deliveryDate,
  invoiceTotal,
  totalCredit,
  buyerPONumber,
  deliveryAddressId,
  isPickup,
  deliveryNote,
  setInvoiceItems,
  setInvoiceDate,
  setDeliveryDate,
  setInvoiceTotal,
  setTotalCredit,
  setBuyerPONumber,
  setDeliveryAddressId,
  setDeliveryNote,
  setIsPickup,
  isSupplierView = false,
  supplierOrCustomerName,
  isEditable = true,
}: InvoiceSummaryProps) {
  // const [deliveryAddress, setDeliveryAddress] = useState<Address | undefined>();

  const handleDeliveryAddressChange = (address: Address | undefined) => {
    if (!address) {
      return;
    }
    setDeliveryAddressId(address.id);
  };

  const handleChangedInvoiceDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceDate(e.target.value.slice(0, 10));
  };

  const handleChangedDeliveryDate = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDeliveryDate(e.target.value.slice(0, 10));
  };

  const handleInvoicedQuantityChange = (index: number, quantity: number) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
          invoiced_quantity: quantity,
        };
        if (isSupplierView) {
          updatedInvoiceItem.received_quantity = quantity;
        }
        updatedInvoiceItem.total = getInvoiceTotal(updatedInvoiceItem);
        updatedInvoiceItem.credit = getInvoiceCredit(updatedInvoiceItem);
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const handleReceivedQuantityChange = (index: number, quantity: number) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
          received_quantity: quantity,
        };
        updatedInvoiceItem.credit = getInvoiceCredit(updatedInvoiceItem);
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const handlePriceChange = (index: number, price: number) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
          price: price,
        };
        updatedInvoiceItem.total = getInvoiceTotal(updatedInvoiceItem);
        updatedInvoiceItem.credit = getInvoiceCredit(updatedInvoiceItem);
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const handleNotesChange = (index: number, notes: string) => {
    const newInvoiceItems = invoiceItems.map((invoiceItem, i) => {
      if (i === index) {
        const updatedInvoiceItem = {
          ...invoiceItem,
        };
        if (isSupplierView) {
          updatedInvoiceItem.supplier_notes = notes;
        } else {
          updatedInvoiceItem.buyer_notes = notes;
        }
        return updatedInvoiceItem;
      }
      return invoiceItem;
    });
    setInvoiceItems(newInvoiceItems);
  };

  const columnHelper = createColumnHelper<InvoiceItem>();

  const columns = [
    columnHelper.accessor("product", {
      cell: (info) => info.getValue()?.name,
      header: "Product",
    }),
    columnHelper.accessor("product_unit", {
      cell: (info) => getProductUnitLabel(info.getValue()),
      header: "Unit",
    }),
    columnHelper.accessor(isSupplierView ? "supplier_notes" : "buyer_notes", {
      cell: (info) => (
        <EditableColumn
          value={info.getValue() || ""}
          inputType={"text"}
          setValue={(value: string) => handleNotesChange(info.row.index, value)}
          isEditable={isEditable}
          width={"100px"}
        />
      ),
      header: "Notes",
    }),
    columnHelper.accessor("requested_quantity", {
      cell: (info) => info.getValue(),
      header: "Requested quantity",
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor("invoiced_quantity", {
      cell: (info) => (
        <EditableColumn
          value={info.getValue()}
          setValue={(value: string) =>
            handleInvoicedQuantityChange(info.row.index, Number(value))
          }
          isEditable={isEditable}
        />
      ),
      header: `Invoice${isSupplierView ? "" : "d"} qty`,
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor("received_quantity", {
      cell: (info) => (
        <EditableColumn
          value={info.getValue()}
          setValue={(value: string) =>
            handleReceivedQuantityChange(info.row.index, Number(value))
          }
          isEditable={isEditable}
        />
      ),
      header: "Received qty",
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor("price", {
      cell: (info) => (
        <EditableColumn
          value={Number(info.getValue()).toFixed(2)}
          setValue={(value: string) => {
            handlePriceChange(info.row.index, Number(value));
          }}
          label={"$"}
          isEditable={isEditable}
        />
      ),
      header: "Price",
      meta: {
        isNumeric: true,
        isCurrency: true,
      },
    }),
    columnHelper.accessor("total", {
      cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
      header: `Invoice${isSupplierView ? "" : "d"} total`,
      meta: {
        isNumeric: true,
        isCurrency: true,
      },
    }),
    columnHelper.accessor("credit", {
      cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
      header: "Credit total",
      meta: {
        isNumeric: true,
        isCurrency: true,
      },
    }),
  ];

  useEffect(() => {
    if (invoiceItems && invoiceItems.length > 0) {
      const newInvoiceTotal = invoiceItems.reduce((total, item) => {
        return total + item.price * item.invoiced_quantity;
      }, 0);
      const newTotalCredit = invoiceItems.reduce((total, item) => {
        return (
          total + item.price * (item.invoiced_quantity - item.received_quantity)
        );
      }, 0);
      setInvoiceTotal(newInvoiceTotal);
      setTotalCredit(newTotalCredit);
    } else {
      setInvoiceTotal(0);
      setTotalCredit(0);
    }
  }, [invoiceItems]);

  return (
    <Box p={[2, 4]} minHeight="600px">
      <Heading size={"md"}>Invoice Summary</Heading>
      <Spacer />
      <Stack
        direction={["column", "row"]}
        m={4}
        spacing={4}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <StackEntry>
          <b style={{ marginRight: "8px" }}>
            {isSupplierView ? "Customer" : "Supplier"}:{" "}
          </b>
          {supplierOrCustomerName}
        </StackEntry>
        <StackEntry>
          <b>Invoice date:</b>
          <Input
            size="sm"
            marginLeft="4px"
            w="150px"
            type="date"
            value={invoiceDate}
            onChange={handleChangedInvoiceDate}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>
            Deliver{isSupplierView ? "y" : "ed"} date:{" "}
          </b>
          <Input
            size="sm"
            marginLeft="4px"
            w="150px"
            type="date"
            value={deliveryDate}
            onChange={handleChangedDeliveryDate}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>Ext PO Number: </b>
          <Input
            size="sm"
            marginLeft="4px"
            w="150px"
            type="text"
            value={buyerPONumber || ""}
            onChange={(e) => setBuyerPONumber(e.target.value)}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>Invoice total: </b>
          {`$ ${Number(invoiceTotal).toFixed(2)}`}
        </StackEntry>
        {!isSupplierView && (
          <StackEntry>
            <b style={{ marginRight: "8px" }}>Total credit: </b>
            {`$ ${Number(totalCredit).toFixed(2)}`}
          </StackEntry>
        )}
      </Stack>
      <Stack
        direction={["column", "row"]}
        m={4}
        spacing={4}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <StackEntry>
          <b style={{ marginRight: "8px" }}>Delivery note: </b>
          <Input
            size="sm"
            marginLeft="4px"
            w={["100%", "150px"]}
            type="text"
            value={deliveryNote}
            onChange={(e) => setDeliveryNote(e.target.value)}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>Is Pickup: </b>
          <Checkbox
            isChecked={isPickup}
            onChange={(e) => setIsPickup(e.target.checked)}
          />
        </StackEntry>
        <StackEntry>
          <b style={{ marginRight: "8px" }}>
            {isPickup ? "Pickup at" : "Deliver to"}:
          </b>
          <AddressSearchOrCreate
            organisationId={isPickup ? supplierOrgId : buyerOrgId}
            locationId={isPickup ? supplierLocationId : buyerLocationId}
            setAddress={(address: Address | undefined) =>
              handleDeliveryAddressChange(address)
            }
            selectedAddressId={deliveryAddressId}
          />
        </StackEntry>
      </Stack>
      <Heading size={"md"} marginTop={"2rem"}>
        Invoice Items
      </Heading>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        p={[2, 4, 6]}
        overflowX="auto"
      >
        <DataTable
          columnVisibility={{ requested_quantity: false }}
          data={invoiceItems}
          columns={columns}
        />
      </Box>
      <Stack
        direction={"row"}
        mt={4}
        spacing={4}
        justifyContent="space-between"
      >
        <StackEntry>
          <strong>Invoice Total:</strong>
          <strong>${invoiceTotal.toFixed(2)}</strong>
        </StackEntry>
        {totalCredit && (
          <StackEntry>
            <strong>Total Credit:</strong>
            <strong>${totalCredit.toFixed(2)}</strong>
          </StackEntry>
        )}
        {totalCredit && (
          <StackEntry>
            <strong>Balance Due:</strong>
            <strong>
              ${(Number(invoiceTotal) - Number(totalCredit)).toFixed(2)}
            </strong>
          </StackEntry>
        )}
      </Stack>
    </Box>
  );
}
