import * as React from "react";
import { BoxProps, VStack } from "@chakra-ui/react";

interface StackEntryProps extends BoxProps {
  children: React.ReactNode[];
}

const StackEntry = ({ children, ...props }: StackEntryProps) => (
  <VStack alignItems={"start"} {...props}>
    {children && children.map((child, index) => <div key={index}>{child}</div>)}
  </VStack>
);

export default StackEntry;
