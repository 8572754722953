import { ComponentSingleStyleConfig } from "@chakra-ui/react";

export const CalendarDay: ComponentSingleStyleConfig = {
  baseStyle: {
    rounded: "0px",
    color: "gray.500",
    bgColor: "teal.50",
    transition: "all 0.2s",
    border: "1px solid",
    borderColor: "whiteAlpha.400",
    _hover: {
      bgColor: "teal.200",
    },
    _disabled: {
      color: "whiteAlpha.400",
      _hover: {
        cursor: "not-allowed",
        bgColor: "transparent",
      },
    },
  },

  sizes: {
    sm: {
      h: 8,
      w: 8,
    },
  },

  variants: {
    selected: {
      bgColor: "teal.500",
      color: "white",

      _hover: {
        bgColor: "teal.600",
      },
    },

    range: {
      bgColor: "teal.500",
      color: "white",
      _hover: {
        bgColor: "teal.600",
      },
      _disabled: {
        _hover: {
          bgColor: "teal.500",
        },
      },
    },

    outside: {
      color: "whiteAlpha.600",
    },
    today: {
      bgColor: "teal.100",
      color: "teal.800",
      _hover: {
        bgColor: "teal.200",
      },
    },
  },

  defaultProps: {
    size: "sm",
  },
};
