import React, { useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import {
  useGetBuyerLocationsQuery,
  useGetPaymentsQuery,
  useGetSupplierInvoicesQuery,
} from "../redux/apiSlice";
import { useDisclosure } from "@chakra-ui/react";
import DataTabs from "../components/DataTabs/DataTabs";
import { Location } from "../types";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import Page from "../components/Page/Page";
import AddPaymentModal from "../feature/PaymentsView/AddPaymentModal";
import { getLocationAndOrgName } from "../utils/invoiceUtils";
import CustomerSearch from "../components/CustomerSearch/CustomerSearch";
import AddCustomerModal from "../feature/AccountsView/AddCustomerModal";
import PaymentViewEntry from "../feature/PaymentsView/PaymentViewEntry";
import { AccountInfo } from "../feature/AccountsView/types";
import AccountsViewEntry from "../feature/AccountsView/AccountsViewEntry";

function AccountsPage(): React.ReactElement {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);

  const {
    data: buyerLocations,
    isLoading: isLoadingBuyerLocations,
    error: errorBuyerLocations,
  } = useGetBuyerLocationsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const {
    data: invoices,
    error: invoicesError,
    isLoading: invoicesLoading,
  } = useGetSupplierInvoicesQuery(
    {
      organisationId,
      locationId,
    },
    { skip: !organisationId || !locationId },
  );

  const {
    data: payments,
    error: paymentsError,
    isLoading: paymentsLoading,
  } = useGetPaymentsQuery(
    {
      organisationId,
      locationId,
      paymentDateGte: startDate,
      paymentDateLte: endDate,
    },
    { skip: !organisationId || !locationId },
  );

  const [customerFilter, setCustomerFilter] = useState<string | undefined>(
    undefined,
  );
  const [filteredBuyers, setFilteredBuyers] = useState<Location[]>(
    buyerLocations || [],
  );
  const [accountsData, setAccountsData] = useState<AccountInfo[]>([]);

  const [tabIndex, setTabIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (buyerLocations) {
      if (customerFilter && customerFilter.length > 0) {
        const locationList = buyerLocations.filter(
          (buyer) => getLocationAndOrgName(buyer) === customerFilter,
        );
        setFilteredBuyers(locationList);
      } else {
        setFilteredBuyers(buyerLocations);
      }
    }
  }, [buyerLocations, customerFilter]);

  useEffect(() => {
    if (buyerLocations && invoices) {
      // map location_id to the summed sales total for that location across the entries in sales, setAccountData
      const locations =
        filteredBuyers && filteredBuyers.length > 0
          ? filteredBuyers
          : buyerLocations;

      const accountsData: AccountInfo[] = [];
      locations.forEach((buyer) => {
        const buyerSales = invoices.filter(
          (invoice) => invoice.location_id === buyer.id,
        );
        const totalSales = buyerSales.reduce(
          (acc, sale) => acc + Number(sale.total),
          0,
        );
        const totalCredit = buyerSales.reduce(
          (acc, sale) => acc + Number(sale.credit),
          0,
        );

        const buyerPayments =
          payments?.filter(
            (payment) => payment.customer_location_id === buyer.id,
          ) || [];
        const totalPayments =
          buyerPayments.reduce(
            (acc, payment) => acc + Number(payment.amount),
            0,
          ) || 0;

        let accountEntries = buyerSales.map((invoice) => ({
          date: invoice.invoice_date,
          locationId: invoice.location_id,
          name: ` - (${invoice.invoice_date.slice(0, 10)}) Invoice #${
            invoice.id
          }`,
          amountOwed: Number(invoice.total),
          creditAmount: Number(invoice.credit),
          amountPaid: Number(invoice.amount_paid),
          amountRemaining: Number(invoice.total_due),
          balance: Number(invoice.total) - Number(invoice.credit),
          isInvoice: true,
          status: invoice.status,
        }));

        accountEntries = accountEntries
          .concat(
            buyerPayments.map((payment) => ({
              date: payment.payment_date,
              locationId: payment.customer_location_id,
              name: ` - (${payment.payment_date.slice(0, 10)}) Payment #${
                payment.id
              }`,
              amountOwed: 0,
              creditAmount: 0,
              amountPaid: Number(payment.amount),
              amountRemaining:
                Number(payment.amount) - Number(payment.amount_assigned || 0),
              balance: -Number(payment.amount),
              isInvoice: false,
              status: "",
            })),
          )
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          );

        accountsData.push({
          date: "",
          locationId: buyer.id,
          name: getLocationAndOrgName(buyer),
          amountOwed: totalSales,
          creditAmount: totalCredit,
          amountPaid: totalPayments,
          balance: totalSales - totalCredit - totalPayments,
          accountEntries,
        } as AccountInfo);
      });
      setAccountsData(accountsData);
    } else {
      setAccountsData([]);
    }
  }, [buyerLocations, filteredBuyers, invoices, payments]);

  const getModal = () => (
    <>
      {tabIndex === 0 ? (
        <AddCustomerModal isOpen={isOpen} onClose={onClose} />
      ) : (
        <AddPaymentModal isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );

  return (
    <>
      <PageOverview
        heading={"Accounts"}
        subheading={
          tabIndex === 1
            ? startDate && endDate && `${startDate} - ${endDate}`
            : ""
        }
        showDateRangePicker={tabIndex === 1}
      />
      <Page
        isLoading={
          invoicesLoading || paymentsLoading || isLoadingBuyerLocations
        }
        isError={Boolean(invoicesError || paymentsError || errorBuyerLocations)}
      >
        <>
          <CustomerSearch
            setCustomerName={setCustomerFilter}
            includeSearchIcon
          />
          {isOpen && getModal()}
          <DataTabs
            showButton={tabIndex === 1}
            onChange={(index) => setTabIndex(index)}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            data={[
              {
                label: "Accounts Receivables",
                buttonLabel: "Add Customer",
                isLoading:
                  paymentsLoading || invoicesLoading || isLoadingBuyerLocations,
                headers: [
                  "Name",
                  "Amount Owed",
                  "Credit Amount",
                  "Amount Paid",
                  "Amount Remaining",
                ],
                entries: accountsData
                  ? accountsData
                      .slice()
                      .sort((a, b) => b.balance - a.balance)
                      .map((accountInfo, index) => (
                        <AccountsViewEntry
                          index={index}
                          accountInfo={accountInfo}
                        />
                      ))
                  : [],
              },
              {
                label: "Payments",
                headers: [
                  "Date",
                  "Account",
                  "Amount",
                  "Amount Assigned",
                  "Amount Remaining",
                  "Payment Type",
                ],
                isLoading:
                  paymentsLoading || invoicesLoading || isLoadingBuyerLocations,
                entries: payments
                  ? payments
                      .slice()
                      .sort(
                        (a, b) =>
                          new Date(b.payment_date).getTime() -
                          new Date(a.payment_date).getTime(),
                      )
                      .map((payment, index) => (
                        <PaymentViewEntry
                          key={index}
                          index={index}
                          payment={payment}
                        />
                      ))
                  : [],
              },
            ]}
          />
        </>
      </Page>
    </>
  );
}

export default AccountsPage;
