import { Container, ContainerCostItem, InvoiceItem } from "../../types";
import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  KeyboardEvent,
} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  VStack,
  Button,
  Flex,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../components/DataTable/DataTable";
import * as React from "react";
import EditableColumn from "../../components/DataTable/EditableColumn";
import ContainerInvoiceFormField from "./ContainerInvoiceFormField";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import { useGetInvoicesQuery } from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import StackEntry from "../../components/StackEntry/StackEntry";
import { DeleteIcon } from "@chakra-ui/icons";
import { showDecimalOnlyIfPresent } from "../../utils/invoiceUtils";

interface ContainerCostSummaryProps {
  container: Container;
  costItems: ContainerCostItem[];
  expectedDate: string;
  arrivalDate: string;
  releasedDate: string;
  totalCost: number;
  origin: string;
  setCostItems: (value: ContainerCostItem[]) => void;
  setExpectedDate: (value: string) => void;
  setArrivalDate: (value: string) => void;
  setReleasedDate: (value: string) => void;
  setTotalCost: (value: number) => void;
  setOrigin: (value: string) => void;
}

type InvoiceItemCost = {
  invoice_item: InvoiceItem;
  quantity: {
    invoiced_v_received: string;
  };
  cost: {
    received_total: string;
    percentage: number;
    amount_added: number;
    amount_total: number;
  };
};

const ContainerCostSummary = forwardRef(
  (
    {
      container,
      costItems,
      expectedDate,
      arrivalDate,
      releasedDate,
      totalCost,
      origin,
      setCostItems,
      setExpectedDate,
      setArrivalDate,
      setReleasedDate,
      setTotalCost,
      setOrigin,
    }: ContainerCostSummaryProps,
    ref,
  ) => {
    const [newItemDescription, setNewItemDescription] =
      React.useState<string>("");
    const [newItemCost, setNewItemCost] = React.useState<number>(0);
    const [newItemIsPaid, setNewItemIsPaid] = React.useState<boolean>(false);
    const [invoiceIds, setInvoiceIds] = useState<number[]>([]);
    const [invoiceItemCosts, setInvoiceItemCosts] = useState<InvoiceItemCost[]>(
      [],
    );
    const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
    const [totalCredit, setTotalCredit] = useState<number>(0);

    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

    const { getCurrentOrganisationId, getCurrentLocationId } =
      userSlice.selectors;
    const organisationId = useAppSelector(getCurrentOrganisationId);
    const locationId = useAppSelector(getCurrentLocationId);

    const { data: invoices } = useGetInvoicesQuery({
      organisationId,
      locationId,
    });

    const [newItemError, setNewItemError] = useState<string | null>(null);
    const [costInputValue, setCostInputValue] = useState("");

    const handleCostInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value === "" || /^\d*\.?\d*$/.test(value)) {
        setCostInputValue(value);
        setNewItemCost(value === "" || value === "." ? 0 : parseFloat(value));
      }
    };

    const handleAddNewItem = () => {
      if (newItemDescription.length === 0) {
        setNewItemError("Description is required");
        document.getElementById("new-item-description")?.focus();
        return;
      }
      if (newItemCost <= 0) {
        setNewItemError("Cost must be greater than 0");
        document.getElementById("new-item-cost")?.focus();
        return;
      }

      setCostItems([
        ...costItems,
        {
          id: null,
          container_id: container.id,
          description: newItemDescription,
          cost: newItemCost,
          is_paid: newItemIsPaid,
        },
      ]);
      setNewItemDescription("");
      setCostInputValue("");
      setNewItemCost(0);
      setNewItemIsPaid(false);
      setNewItemError(null);
      document.getElementById("new-item-description")?.focus();
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleAddNewItem();
      }
    };

    useEffect(() => {
      let newTotal = 0;
      if (costItems && costItems.length > 0) {
        newTotal = costItems.reduce((total, item) => {
          return total + Number(item.cost);
        }, 0);
      }
      setTotalCost(newTotal);
      if (invoices) {
        const invoiceTotal = invoices.reduce(
          (acc, invoice) => acc + Number(invoice.total),
          0,
        );
        const creditTotal = invoices.reduce(
          (acc, invoice) => acc + Number(invoice.credit),
          0,
        );

        const receivedTotal = invoiceTotal - creditTotal;
        const totalContainerCost = invoiceTotal + newTotal;

        const invoiceItems = invoices
          .filter((invoice) => invoiceIds.includes(invoice.id))
          .map((invoice) => invoice.invoice_items)
          .flat();

        const invoiceItemCosts = invoiceItems.map((invoiceItem) => {
          const itemPercentage =
            (invoiceItem.total - invoiceItem.credit) / receivedTotal;
          const amountAdded =
            (itemPercentage * totalContainerCost) /
              invoiceItem.received_quantity || 0;
          return {
            invoice_item: invoiceItem,
            quantity: {
              invoiced_v_received: `${showDecimalOnlyIfPresent(
                Number(invoiceItem.invoiced_quantity),
              )}/
            ${showDecimalOnlyIfPresent(Number(invoiceItem.received_quantity))}`,
            },
            cost: {
              received_total: `$${Number(
                invoiceItem.received_quantity * invoiceItem.price,
              ).toFixed(2)}`,
              percentage: itemPercentage,
              amount_added: amountAdded,
              amount_total: invoiceItem.invoiced_quantity * amountAdded,
            },
          };
        });

        setInvoiceItemCosts(invoiceItemCosts);
        setInvoiceTotal(invoiceTotal);
        setTotalCredit(creditTotal);
      } else {
        setInvoiceItemCosts([]);
      }
    }, [costItems, invoices, invoiceIds]);

    const handleChangedExpectedDate = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setExpectedDate(e.target.value.slice(0, 10));
    };

    const handleChangedArrivalDate = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setArrivalDate(e.target.value.slice(0, 10));
    };

    const handleChangedReleasedDate = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setReleasedDate(e.target.value.slice(0, 10));
    };

    const handleDescriptionChange = (index: number, description: string) => {
      const newCostItems = costItems.map((costItem, i) => {
        if (i === index) {
          return {
            ...costItem,
            description,
          };
        }
        return costItem;
      });
      setCostItems(newCostItems);
    };

    const handleCostChange = (index: number, cost: number) => {
      const newCostItems = costItems.map((costItem, i) => {
        if (i === index) {
          return {
            ...costItem,
            cost,
          };
        }
        return costItem;
      });
      setCostItems(newCostItems);
    };

    const handleIsPaidChange = (index: number, isPaid: boolean) => {
      const newCostItems = costItems.map((costItem, i) => {
        if (i === index) {
          return {
            ...costItem,
            is_paid: isPaid,
          };
        }
        return costItem;
      });
      setCostItems(newCostItems);
    };

    const handleDeleteCostItem = (index: number) => {
      setIsDeleteLoading(true);
      const newCostItems = costItems.filter((_, i) => i !== index);
      setCostItems(newCostItems);
      setTimeout(() => {
        setIsDeleteLoading(false);
      }, 200);
    };

    const receivedTotal = () => Number(invoiceTotal) - Number(totalCredit);

    const columnHelper = createColumnHelper<ContainerCostItem>();

    const columns = [
      columnHelper.accessor("description", {
        cell: (info) => (
          <EditableColumn
            inputType={"text"}
            value={info.getValue()}
            setValue={(value: string) => {
              handleDescriptionChange(info.row.index, value);
            }}
            width={"100%"}
          />
        ),
        header: "Description",
      }),
      columnHelper.accessor("cost", {
        cell: (info) => (
          <EditableColumn
            value={Number(info.getValue()).toFixed(2)}
            setValue={(value: string) => {
              handleCostChange(info.row.index, Number(value));
            }}
            label={"$"}
            width={"100%"}
          />
        ),
        header: "Cost",
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      columnHelper.accessor("is_paid", {
        cell: (info) => (
          <Checkbox
            isChecked={info.getValue()}
            onChange={(e) => {
              handleIsPaidChange(
                info.row.index,
                Boolean(e.target.checked ? 1 : 0),
              );
            }}
          />
        ),
        header: "Is paid",
      }),
      columnHelper.accessor("id", {
        cell: (info) => (
          <IconButton
            aria-label="Remove"
            icon={<DeleteIcon />}
            colorScheme="red"
            size="xs"
            onClick={() => handleDeleteCostItem(info.row.index)}
            variant="outline"
            isLoading={isDeleteLoading}
          />
        ),
        header: "Delete",
      }),
    ];

    const invoiceColumnHelper = createColumnHelper<InvoiceItemCost>();

    const invoiceColumns = [
      invoiceColumnHelper.accessor("invoice_item", {
        cell: (info) =>
          `${info.getValue()?.product.name} - ${getProductUnitLabel(
            info.getValue().product_unit,
          )}`,
        header: "Product",
      }),
      // invoiceColumnHelper.accessor("invoice_item", {
      //   cell: (info) => getProductUnitLabel(info.getValue().product_unit),
      //   header: "Unit",
      // }),
      invoiceColumnHelper.accessor("quantity.invoiced_v_received", {
        cell: (info) => info.getValue(),
        header: "Invoice/Rcv quantity",
        meta: {
          isNumeric: true,
        },
      }),
      invoiceColumnHelper.accessor("invoice_item.price", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: "Price",
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      invoiceColumnHelper.accessor("invoice_item.total", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: "Invoiced total",
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      invoiceColumnHelper.accessor("cost.received_total", {
        cell: (info) => info.getValue(),
        header: "Received total",
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      invoiceColumnHelper.accessor("invoice_item.credit", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: "Credit total",
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      invoiceColumnHelper.accessor("cost.amount_added", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: "Added cost per unit",
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
      invoiceColumnHelper.accessor("cost.amount_total", {
        cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
        header: "Total cost",
        meta: {
          isNumeric: true,
          isCurrency: true,
        },
      }),
    ];

    useEffect(() => {
      if (container) {
        setInvoiceIds(
          container.container_invoices?.map((ci) => ci.invoice_id) || [],
        );
      }
    }, [container]);

    useImperativeHandle(ref, () => ({
      getNewItemData: () => {
        if (newItemDescription && newItemCost > 0) {
          return {
            id: null,
            container_id: container.id,
            description: newItemDescription,
            cost: newItemCost,
            is_paid: newItemIsPaid,
          };
        }
        return null;
      },
    }));

    return (
      <Box>
        <Heading size="md" mb={4}>
          Container
        </Heading>
        <Grid templateColumns="repeat(5, 1fr)" gap={4} mb={6}>
          <GridItem>
            <Text fontWeight="bold">Number</Text>
            <Text>{container.number}</Text>
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Origin</Text>
            <Input
              size="sm"
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
            />
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Expected arrival:</Text>
            <Input
              size="sm"
              type="date"
              value={expectedDate}
              onChange={handleChangedExpectedDate}
            />
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Arrived at:</Text>
            <Input
              size="sm"
              type="date"
              value={arrivalDate}
              onChange={handleChangedArrivalDate}
            />
          </GridItem>
          <GridItem>
            <Text fontWeight="bold">Released at:</Text>
            <Input
              size="sm"
              type="date"
              value={releasedDate}
              onChange={handleChangedReleasedDate}
            />
          </GridItem>
        </Grid>

        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading size="md">Cost Items</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box borderWidth="1px" rounded="lg" p={4}>
                <DataTable data={costItems} columns={columns} />
                <VStack align="stretch" mt={4} spacing={2}>
                  <Flex>
                    <Input
                      id="new-item-description"
                      placeholder="Description..."
                      value={newItemDescription}
                      onChange={(e) => setNewItemDescription(e.target.value)}
                      onKeyPress={handleKeyPress}
                      flex={2}
                      mr={2}
                    />
                    <InputGroup flex={1} mr={2}>
                      <InputLeftElement>$</InputLeftElement>
                      <Input
                        id="new-item-cost"
                        type="text"
                        inputMode="decimal"
                        value={costInputValue}
                        onChange={handleCostInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder="0"
                      />
                    </InputGroup>
                    <Checkbox
                      id="new-item-is-paid"
                      isChecked={newItemIsPaid}
                      onChange={(e) => setNewItemIsPaid(e.target.checked)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleAddNewItem();
                        }
                      }}
                      alignSelf="center"
                    >
                      Is Paid
                    </Checkbox>
                  </Flex>
                  {newItemError && (
                    <Text color="red.500" fontSize="sm">
                      {newItemError}
                    </Text>
                  )}
                  <Button onClick={handleAddNewItem} colorScheme="teal">
                    Add Cost Item
                  </Button>
                </VStack>
                <Text fontWeight="bold" mt={4}>
                  Total cost: ${Number(totalCost).toFixed(2)}
                </Text>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading size="md">Invoices</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <ContainerInvoiceFormField
                setInvoiceIds={setInvoiceIds}
                invoiceIds={invoiceIds}
              />
              <DataTable data={invoiceItemCosts} columns={invoiceColumns} />
              <HStack>
                <StackEntry>
                  <b style={{ marginTop: "8px" }}>Invoice total: </b>
                  {`$ ${Number(invoiceTotal).toFixed(2)}`}
                </StackEntry>
                <Spacer />
                <StackEntry>
                  <b style={{ marginTop: "8px" }}>Received total: </b>
                  {`$ ${receivedTotal().toFixed(2)}`}
                </StackEntry>
                <Spacer />
                <StackEntry>
                  <b style={{ marginTop: "8px" }}>Credit total: </b>
                  {`$ ${Number(totalCredit).toFixed(2)}`}
                </StackEntry>
                <Spacer />
                <StackEntry>
                  <b style={{ marginTop: "8px" }}>Total cost: </b>
                  {`$ ${(Number(invoiceTotal) + Number(totalCost)).toFixed(2)}`}
                </StackEntry>
              </HStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    );
  },
);

export default ContainerCostSummary;
