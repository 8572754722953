import { OrderItem, Price, ProductUnit } from "../../types";
import { useEffect, useState } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import * as React from "react";
import DataTable from "../../components/DataTable/DataTable";
import EditableColumn from "../../components/DataTable/EditableColumn";
import { getOrderTotal } from "../../utils/invoiceUtils";
import AddUnitSelectField from "../../components/AddUnitSelectField/AddUnitSelectField";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch";
import {
  useGetInventoryItemsQuery,
  useGetProductsQuery,
} from "../../redux/apiSlice";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";

interface OrderSummaryProps {
  isSalesOrder?: boolean;
  orderItems: OrderItem[];
  productUnitPriceMap: Map<number, Price[]>;
  setOrderItems: (value: OrderItem[]) => void;
  setOrderTotal: (value: number) => void;
  preventItemEdits?: boolean;
  preventAddRow?: boolean;
  onChange: () => void;
}

export default function OrderSummary({
  isSalesOrder = false,
  orderItems,
  productUnitPriceMap,
  setOrderItems,
  setOrderTotal,
  preventItemEdits = true,
  preventAddRow = true,
  onChange,
}: OrderSummaryProps): React.ReactElement {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const { data: products } = useGetProductsQuery("");

  const { data: inventoryItems } = useGetInventoryItemsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [newProductId, setNewProductId] = useState<number | null | undefined>(
    null,
  );
  const [newItemProductName, setNewItemProductName] = useState<string>("");
  const [newProductUnitId, setNewProductUnitId] = useState<number | null>(null);
  const [newProductUnits, setNewProductUnits] = useState<ProductUnit[]>([]);
  const [newItemQuantity, setNewItemQuantity] = useState<number>(1);
  const [newItemCost, setNewItemCost] = useState<number | string>("");
  const [newItemTotal, setNewItemTotal] = useState<number | null>(null);

  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      const newOrderTotal = orderItems.reduce((total, item) => {
        const unitPrice: number =
          item.price ||
          productUnitPriceMap.get(item.product_unit_id)?.[0].price ||
          0;
        return total + unitPrice * item.quantity;
      }, 0);
      setOrderTotal(newOrderTotal);
    } else {
      setOrderTotal(0);
    }
  }, [orderItems]);

  useEffect(() => {
    let inventoryUnitIds = new Set();
    if (
      inventoryItems &&
      inventoryItems.length > 0 &&
      products &&
      products.length > 0
    ) {
      inventoryUnitIds = new Set(
        inventoryItems.map((item) => item.product_unit_id),
      );
    } else {
      return;
    }
    if (products && products.length > 0 && newProductId) {
      const currentProduct = products.find(
        (product) => product.id === newProductId,
      );
      if (currentProduct) {
        const filteredUnits = currentProduct.product_units.filter((unit) =>
          inventoryUnitIds.has(unit.id),
        );
        setNewProductUnits(filteredUnits);
        setNewProductUnitId(filteredUnits?.[0]?.id);
        if (!filteredUnits?.[0]) {
          toast({
            title: "No units available!",
            description: "Please add units to proceed with order.",
            status: "warning",
            isClosable: true,
          });
        }
      } else {
        setNewProductUnits([]);
      }
    } else {
      setNewProductUnits([]);
    }
  }, [products, inventoryItems, newProductId]);

  const handleEdit = (index: number, quantity: number) => {
    onChange();
    const newOrderItems = orderItems.map((orderItem, i) => {
      if (i === index) {
        const updatedOrderItem = {
          ...orderItem,
          quantity: quantity,
        };
        updatedOrderItem.total = getOrderTotal(updatedOrderItem);
        return updatedOrderItem;
      }
      return orderItem;
    });
    setOrderItems(newOrderItems);
  };

  const handleEditPrice = (index: number, price: number) => {
    onChange();
    const newOrderItems = orderItems.map((orderItem, i) => {
      if (i === index) {
        const updatedOrderItem = {
          ...orderItem,
          price: price,
        };
        updatedOrderItem.total = getOrderTotal(updatedOrderItem);
        return updatedOrderItem;
      }
      return orderItem;
    });
    setOrderItems(newOrderItems);
  };

  // const handleRemove = (index: number) => {
  //   setOrderItems(orderItems.filter((_, i) => i !== index));
  // };

  const handleAddNewItem = () => {
    if (
      products &&
      newProductId &&
      newProductUnitId &&
      newItemQuantity &&
      newItemCost
    ) {
      const product = products.find((product) => product.id === newProductId);
      const productUnit = product?.product_units.find(
        (unit) => unit.id === newProductUnitId,
      );
      if (!product || !productUnit) {
        return;
      }
      const newItem: OrderItem = {
        product_id: newProductId,
        product: product,
        product_unit_id: newProductUnitId,
        product_unit: productUnit,
        quantity: newItemQuantity,
        price: Number(newItemCost),
        total: Number(newItemCost) * newItemQuantity,
      };
      setOrderItems([...orderItems, newItem]);
      onChange();
      setNewItemQuantity(1);
      setNewItemCost("");
      setNewItemTotal(null);
      setNewProductId(null);
      setNewItemProductName("");
      setNewProductUnitId(null);
      setNewProductUnits([]);
    }
  };

  const columnHelper = createColumnHelper<OrderItem>();

  const columns = [
    columnHelper.accessor("product", {
      cell: (info) => info.getValue()?.name,
      header: "Product",
    }),
    columnHelper.accessor("product_unit", {
      cell: (info) => getProductUnitLabel(info.getValue()),
      header: "Unit",
    }),
    columnHelper.accessor("quantity", {
      cell: (info) => (
        <EditableColumn
          value={info.getValue()}
          setValue={(value: string) =>
            handleEdit(info.row.index, Number(value))
          }
          isEditable={!preventItemEdits}
        />
      ),
      header: "Requested Quantity",
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor("price", {
      cell: (info) => (
        <EditableColumn
          value={Number(info.getValue()).toFixed(2)}
          setValue={(value: string) => {
            handleEditPrice(info.row.index, Number(value));
          }}
          label={"$"}
          isEditable={!preventItemEdits}
        />
      ),
      sortingFn: (a, b, columnId) =>
        Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
      header: "Price",
      meta: {
        isNumeric: true,
        isCurrency: true,
      },
    }),
    columnHelper.accessor("total", {
      cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
      sortingFn: (a, b, columnId) =>
        Number(a.getValue(columnId)) - Number(b.getValue(columnId)),
      header: "Total",
      meta: {
        isNumeric: true,
        isCurrency: true,
      },
    }),
  ];

  return (
    <div
      style={{
        minHeight: "200px",
        maxHeight: "300px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <DataTable
        data={orderItems}
        columns={columns}
        showAddRow={!preventAddRow}
        addRowLabel={"item"}
        addRowNextFocus={"new-item-description"}
        addRowChildren={[
          <InventoryProductSearch
            setProductFilterId={setNewProductId}
            setProductName={setNewItemProductName}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                document.getElementById("new-item-unit")?.focus();
              }
            }}
          />,
          <AddUnitSelectField
            focusId="new-item-unit"
            selectedProductId={newProductId}
            selectedProductName={newItemProductName}
            selectedUnitId={newProductUnitId}
            setSelectedUnitId={setNewProductUnitId}
            units={newProductUnits}
            setUnits={setNewProductUnits}
            addUnitsEnabled={isSalesOrder}
          />,
          <Input
            id={"new-item-quantity"}
            type="number"
            value={newItemQuantity}
            onChange={(e) => setNewItemQuantity(Number(e.target.value))}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                // handleAddNewItem();
                document.getElementById("new-item-cost")?.focus();
              }
            }}
          />,
          <InputGroup>
            <InputLeftElement>$</InputLeftElement>
            <Input
              id={"new-item-cost"}
              type="number"
              value={newItemCost}
              onChange={(e) => setNewItemCost(Number(e.target.value))}
              isDisabled={!isSalesOrder}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  handleAddNewItem();
                  document.getElementById("new-item-description")?.focus();
                }
              }}
            />
          </InputGroup>,
          <Text>${Number(newItemTotal).toFixed(2)}</Text>,
        ]}
      />
    </div>
  );
}
