import { Td, useDisclosure } from "@chakra-ui/react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import React from "react";
import { Container, ContainerInvoiceJunction } from "../../types";
import ContainerModal from "./ContainerModal";

interface ContainersViewEntryProps {
  index: number;
  container: Container;
}
const ContainersViewEntry = ({
  index,
  container,
}: ContainersViewEntryProps): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <InteractiveRow key={index} itemIndex={index} onClick={onOpen}>
      <ContainerModal container={container} isOpen={isOpen} onClose={onClose} />
      <Td fontSize="sm">{container.number}</Td>
      <Td fontSize="sm">
        {container.container_invoices
          ? container.container_invoices
              .map((invoice: ContainerInvoiceJunction) => invoice.invoice_id)
              .join(", ")
          : ""}
      </Td>
      <Td fontSize="sm">{container.expected_arrival.slice(0, 10)}</Td>
      <Td fontSize="sm">
        {container.arrived_at ? container.arrived_at.slice(0, 10) : "--"}
      </Td>
      <Td fontSize="sm">
        {container.released_at ? container.released_at.slice(0, 10) : "--"}
      </Td>
    </InteractiveRow>
  );
};

export default ContainersViewEntry;
