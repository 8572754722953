import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Text,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Select,
  Badge,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { Product, ProductUnit } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import { badgeColors } from "../../routes/ProductsPage";
import DataTable from "../../components/DataTable/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import { usePostProductUnitMutation } from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { capitalizeFirstLetter } from "../../utils/stringUtils";

interface ProductViewEntryProps {
  itemIndex: number;
  product: Product;
  inventoryUnitIds: Set<number>;
  children?: React.ReactNode[];
  onClick?: () => void;
}

export default function ProductViewEntry({
  itemIndex,
  product,
  inventoryUnitIds,
  children,
}: ProductViewEntryProps) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [unitLabel, setUnitLabel] = useState<string>("");
  const [unitQuantity, setUnitQuantity] = useState<number>(1);
  const [unitMeasure, setUnitMeasure] = useState<string | undefined>("lb");
  const [unitSystem, setUnitSystem] = useState<string | undefined>(
    "IMPERIAL_US",
  );

  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const [postProductUnit, { isLoading: isPostingProductUnit }] =
    usePostProductUnitMutation();

  const handleAddProductUnit = () => {
    if (Number(organisationId) === 4) {
      toast({
        title: "Function disabled in demo.",
        description: "You do not have permission to create units.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const productUnitPayload = {
      product_id: product.id,
      unit_label: unitLabel,
      unit_measure: unitMeasure,
      unit_quantity: unitQuantity,
      unit_system: unitSystem,
    } as ProductUnit;

    postProductUnit(productUnitPayload)
      .unwrap()
      .then(() => {
        toast({
          title: "Unit created.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Unit failed to create.",
          description:
            "Please check your inputs and try again. If the issue persists please contact support.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleMeasureChange = (value: string) => {
    setUnitMeasure(value);
    if (["oz", "lb"].includes(value)) {
      setUnitSystem("IMPERIAL_US");
    } else if (value === "unit") {
      setUnitSystem("QUANTITY");
    } else {
      setUnitSystem("METRIC");
    }
  };

  const getUnitSystemBadgeColor = (unitSystem: string) => {
    switch (unitSystem) {
      case "METRIC":
        return "green";
      case "IMPERIAL_US":
        return "blue";
      case "IMPERIAL_UK":
        return "blue";
      default:
        return "purple";
    }
  };

  const getUnitSystemBadgeLabel = (unitSystem: string) => {
    const label = unitSystem.split("_")[0];
    return capitalizeFirstLetter(label);
  };

  const columnHelper = createColumnHelper<ProductUnit>();

  const columns = [
    columnHelper.accessor("unit_label", {
      cell: (info) => info.getValue(),
      header: "Label",
    }),
    columnHelper.accessor("unit_quantity", {
      cell: (info) => info.getValue(),
      header: "Quantity",
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor("unit_measure", {
      cell: (info) => info.getValue(),
      header: "Measure",
    }),
    columnHelper.accessor("unit_system", {
      cell: (info) => (
        <Badge colorScheme={getUnitSystemBadgeColor(info.getValue())}>
          {getUnitSystemBadgeLabel(info.getValue())}
        </Badge>
      ),
      header: "Unit System",
    }),
  ];

  useEffect(() => {
    setUnitSystem(undefined);
    setUnitQuantity(1);
    setUnitLabel("");
    setUnitMeasure("lb");
    setUnitSystem("IMPERIAL_US");
  }, [onOpen, onClose]);

  return (
    <InteractiveRow itemIndex={itemIndex} onClick={onOpen}>
      <Modal
        size={"6xl"}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>{product.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack>
              <FormControl>
                <FormLabel>Product</FormLabel>
                <Text>{product.name}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>Units</FormLabel>
                {product.product_units.map((unit, unitIndex) => {
                  if (inventoryUnitIds.has(unit.id)) {
                    return (
                      <Badge
                        colorScheme={badgeColors[unitIndex % 8]}
                        fontSize="0.8em"
                        key={unit.id}
                        marginRight="0.5rem"
                      >
                        {getProductUnitLabel(unit)}
                      </Badge>
                    );
                  }
                })}
              </FormControl>
              {/*<FormControl>*/}
              {/*  <FormLabel>Default Reporting Unit</FormLabel>*/}
              {/*  <Select>*/}
              {/*    {product.product_units.map((unit) => (*/}
              {/*      <option key={unit.id} value={unit.id}>*/}
              {/*        {getProductUnitLabel(unit)}*/}
              {/*      </option>*/}
              {/*    ))}*/}
              {/*  </Select>*/}
              {/*</FormControl>*/}
            </HStack>
            <Accordion defaultIndex={[0]} allowMultiple marginTop={"1rem"}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <b>Units</b>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <DataTable
                    data={product.product_units.filter((unit) =>
                      inventoryUnitIds.has(unit.id),
                    )}
                    columns={columns}
                    showAddRow
                    addRowLabel={"unit"}
                    addRowChildren={[
                      <Input
                        placeholder={"Unit label..."}
                        onChange={(e) => setUnitLabel(e.target.value)}
                      />,
                      <NumberInput
                        defaultValue={1}
                        min={0.00001}
                        onChange={(e) => setUnitQuantity(Number(e))}
                      >
                        <NumberInputField placeholder="Quantity" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>,
                      <Select
                        onChange={(e) => handleMeasureChange(e.target.value)}
                      >
                        <option key={0} value={"lb"}>
                          lb
                        </option>
                        <option key={1} value={"oz"}>
                          oz
                        </option>
                        <option key={2} value={"g"}>
                          g
                        </option>
                        <option key={3} value={"kg"}>
                          kg
                        </option>
                        <option key={4} value={"unit"}>
                          each
                        </option>
                      </Select>,
                      <>
                        {unitSystem && (
                          <Badge
                            colorScheme={getUnitSystemBadgeColor(unitSystem)}
                          >
                            {getUnitSystemBadgeLabel(unitSystem)}
                          </Badge>
                        )}
                      </>,
                    ]}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              isDisabled={
                !unitMeasure ||
                !unitQuantity ||
                !unitLabel ||
                unitLabel.length === 0
              }
              onClick={() => handleAddProductUnit()}
              isLoading={isPostingProductUnit}
            >
              Save
            </Button>
            <Spacer />
            <Button
              colorScheme="red"
              variant={"ghost"}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </InteractiveRow>
  );
}
