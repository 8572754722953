"use client";

import React, { useState } from "react";
import {
  RadioGroup,
  RadioGroupItem,
  Label,
  Checkbox,
} from "@relume_io/relume-ui";
import { BiEnvelope } from "react-icons/bi";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Input, Button, Select, Textarea } from "@chakra-ui/react";
import { usePostDemoRequestMutation } from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";

type Props = {
  tagline: string;
  heading: string;
  description: string;
  email: string;
  phone: string;
  address: string;
  button: object;
};

export type Contact6Props = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const DemoRequest = (props: Contact6Props) => {
  const { tagline, heading, description, email, button } = {
    ...Contact6Defaults,
    ...props,
  } as Props;

  const toast = useToast();

  const [firstNameInput, setFirstNameInput] = useState("");
  const [lastNameInput, setLastNameInput] = useState("");

  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("");
  const [otherItem, setOtherItem] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [messageInput, setMessageInput] = useState("");
  const [acceptTerms, setAcceptTerms] = useState<boolean | "indeterminate">(
    false,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [postDemoRequest, { isLoading: isPostingRequest }] =
    usePostDemoRequestMutation();

  const handleSubmit = () => {
    if (selectedItem === "Other" && (!otherItem || otherItem.length === 0)) {
      toast({
        title: "Error",
        description: "Please provide details for 'Other' business type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!acceptTerms) {
      toast({
        title: "Error",
        description: "Please accept the terms to proceed.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!firstNameInput || firstNameInput.length === 0) {
      toast({
        title: "Error",
        description: "Please provide your first name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!lastNameInput || lastNameInput.length === 0) {
      toast({
        title: "Error",
        description: "Please provide your last name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!emailInput || emailInput.length === 0) {
      toast({
        title: "Error",
        description: "Please provide your email.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!phoneInput || phoneInput.length === 0) {
      toast({
        title: "Error",
        description: "Please provide your phone number.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!companyName || companyName.length === 0) {
      toast({
        title: "Error",
        description: "Please provide your company name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!selectedRadio || selectedRadio.length === 0) {
      toast({
        title: "Error",
        description: "Please select your role.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    postDemoRequest({
      firstName: firstNameInput,
      lastName: lastNameInput,
      email: emailInput,
      phone: phoneInput,
      businessType: selectedItem === "Other" ? otherItem : selectedItem,
      company: companyName,
      role: selectedRadio,
      message: messageInput,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Your request has been submitted successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFirstNameInput("");
        setLastNameInput("");
        setEmailInput("");
        setPhoneInput("");
        setSelectedItem("");
        setOtherItem("");
        setCompanyName("");
        setSelectedRadio("");
        setMessageInput("");
        setAcceptTerms(false);
      })
      .catch((err) => {
        try {
          Sentry.captureException(JSON.stringify(err));
        } catch {
          Sentry.captureException(err);
        }
        toast({
          title: "Error",
          description: "An error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const selectItems = [
    { value: "farm", label: "Farm" },
    { value: "importer-distributor", label: "Importer/Distributor" },
    { value: "brewery-cidery", label: "Brewery/Cidery" },
    { value: "winery-vineyard", label: "Winery/Vineyard" },
    { value: "retail", label: "Retail" },
    { value: "hospitality", label: "Hospitality" },
    { value: "cpg", label: "CPG" },
    { value: "other", label: "Other" },
  ];

  const radioItems = [
    { value: "owner", label: "Owner" },
    { value: "c-level", label: "C-level Exec" },
    { value: "operations", label: "Operations" },
    { value: "procurement", label: "Procurement" },
    { value: "account-manager", label: "Account Manager" },
    { value: "it-manager", label: "IT Manager" },
    { value: "other", label: "Other" },
  ];

  return (
    <section className="demo-request-section px-[5%] py-16 md:py-24 lg:py-28">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Demo Terms</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              By requesting a demo, you agree to allow Stocky AI to retain your
              email and other provided data for the purpose of following up on
              your request and for other business-related purposes. This may
              include, but is not limited to, sending you updates, promotional
              materials, and other communications related to our services. We
              are committed to protecting your privacy and will handle your data
              in accordance with our privacy policy. If you wish to have your
              data removed from our records, please contact us at{" "}
              <a href="mailto:support@stocky-ai.com">support@stocky-ai.ca</a>.
            </p>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className="container grid grid-cols-1 items-start gap-y-12 md:grid-flow-row md:grid-cols-2 md:gap-x-12 lg:grid-flow-col lg:gap-x-20 lg:gap-y-16">
        <div>
          <p className="mb-3 font-semibold md:mb-4">{tagline}</p>
          <div className="mb-6 md:mb-8">
            <h2 className="mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl">
              {heading}
            </h2>
            <p className="md:text-md">{description}</p>
          </div>

          <div className="grid grid-cols-1 gap-4 py-2">
            <div className="flex items-center gap-4">
              <BiEnvelope className="size-6 flex-none" />
              <p>{email}</p>
            </div>
            {/*<div className="flex items-center gap-4">*/}
            {/*  <BiPhone className="size-6 flex-none" />*/}
            {/*  <p>{phone}</p>*/}
            {/*</div>*/}
            {/*<div className="flex items-center gap-4">*/}
            {/*  <BiMap className="size-6 flex-none" />*/}
            {/*  <p>{address}</p>*/}
            {/*</div>*/}
          </div>
        </div>

        <form
          className="grid grid-cols-1 grid-rows-[auto_auto] gap-6"
          onSubmit={handleSubmit}
        >
          <div className="grid grid-cols-2 gap-6">
            <div className="grid w-full items-center">
              <Label htmlFor="firstName" className="mb-2">
                First name
              </Label>
              <Input
                type="text"
                id="firstName"
                value={firstNameInput}
                onChange={(e) => setFirstNameInput(e.target.value)}
              />
            </div>

            <div className="grid w-full items-center">
              <Label htmlFor="lastName" className="mb-2">
                Last name
              </Label>
              <Input
                type="text"
                id="lastName"
                value={lastNameInput}
                onChange={(e) => setLastNameInput(e.target.value)}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div className="grid w-full items-center">
              <Label htmlFor="email" className="mb-2">
                Email
              </Label>
              <Input
                type="email"
                id="email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
              />
            </div>

            <div className="grid w-full items-center">
              <Label htmlFor="phone" className="mb-2">
                Phone number
              </Label>
              <Input
                type="text"
                id="phone"
                value={phoneInput}
                onChange={(e) => setPhoneInput(e.target.value)}
              />
            </div>
          </div>
          <div className="grid w-full items-center">
            <Label className="mb-2">
              What type of business do you own/operate?
            </Label>
            <Select
              placeholder="Select one..."
              onChange={(e) => setSelectedItem(e.target.value)}
            >
              {selectItems.map((item, index) => (
                <option
                  key={index}
                  value={item.label}
                  style={{ color: "black" }}
                >
                  {item.label}
                </option>
              ))}
            </Select>
          </div>
          {selectedItem === "Other" && (
            <div className="grid w-full items-center">
              <Label htmlFor="other" className="mb-2">
                Other - please provide details
              </Label>
              <Input
                type="text"
                id="other"
                value={otherItem}
                onChange={(e) => setOtherItem(e.target.value)}
              />
            </div>
          )}
          <div className="grid w-full items-center">
            <Label htmlFor="company" className="mb-2">
              Company name
            </Label>
            <Input
              type="text"
              id="company"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="grid w-full items-center py-3 md:py-4">
            <Label className="mb-3 md:mb-4">
              Which option best describes your role with this company?
            </Label>
            <RadioGroup
              className="grid grid-cols-2 gap-x-6 gap-y-3.5"
              onValueChange={setSelectedRadio}
            >
              {radioItems.map((item, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <RadioGroupItem value={item.value} id={item.value} />
                  <Label htmlFor={item.value}>{item.label}</Label>
                </div>
              ))}
            </RadioGroup>
          </div>

          <div className="grid w-full items-center">
            <Label htmlFor="message" className="mb-2">
              Message
            </Label>
            <Textarea
              id="message"
              placeholder="Type your message..."
              className="min-h-[11.25rem] overflow-auto"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
            />
          </div>

          <div className="mb-3 flex items-center space-x-2 text-sm md:mb-4">
            <Checkbox
              id="terms"
              checked={acceptTerms}
              onCheckedChange={setAcceptTerms}
            />
            <Label htmlFor="terms" className="cursor-pointer" onClick={onOpen}>
              I accept the{" "}
              <a className="text-link-primary underline" target="_blank">
                Terms
              </a>
            </Label>
          </div>

          <div>
            <Button
              {...button}
              isLoading={isPostingRequest}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export const Contact6Defaults: Contact6Props = {
  tagline: "Experience the future of operations.",
  heading: "Request a Demo",
  description: "We will get back to you promptly.",
  email: "support@stocky-ai.com",
};
