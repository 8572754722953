import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import {
  useGetBuyerLocationsQuery,
  useGetOrganisationLocationsQuery,
} from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import OrganisationSearchOrCreate from "../../components/OrganisationSearchOrCreate/OrganisationSearchOrCreate";
import { skipToken } from "@reduxjs/toolkit/query";

interface AddCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddCustomerModal = ({ isOpen, onClose }: AddCustomerModalProps) => {
  const [radioValue, setRadioValue] = React.useState("org");
  const [isIndividual, setIsIndividual] = useState<boolean>(false);

  const handleSetIsIndividual = (value: string) => {
    setRadioValue(value);
    setIsIndividual(value === "individual");
  };

  const [selectedOrganisationId, setSelectedOrganisationId] = useState<
    number | null | undefined
  >(null);

  const [existingBuyerLocationIds, setExistingBuyerLocationIds] = useState<
    Set<number>
  >(new Set());

  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: buyerLocations } = useGetBuyerLocationsQuery({
    organisationId,
    locationId,
  });

  const { data: selectedOrganisationLocations } =
    useGetOrganisationLocationsQuery({
      // @ts-expect-error - skipToken is valid
      organisationId: selectedOrganisationId
        ? selectedOrganisationId.toString()
        : skipToken,
    });

  useEffect(() => {
    if (buyerLocations) {
      const newBuyerLocationIds = new Set<number>();
      buyerLocations.forEach((buyer) => {
        newBuyerLocationIds.add(buyer.id);
      });
      setExistingBuyerLocationIds(newBuyerLocationIds);
    } else {
      setExistingBuyerLocationIds(new Set<number>());
    }
  }, [buyerLocations]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Account</ModalHeader>
        <ModalBody>
          <FormControl marginBottom={4}>
            <FormLabel>Account Type</FormLabel>
            <RadioGroup onChange={handleSetIsIndividual} value={radioValue}>
              <Stack spacing={5} direction="row">
                <Radio colorScheme="teal" value="org">
                  Business/Organization
                </Radio>
                <Radio colorScheme="teal" value="individual">
                  Individual/Consumer
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>
              {isIndividual ? "Customer" : "Organization"} Name
            </FormLabel>
            <OrganisationSearchOrCreate
              setOrganisationId={setSelectedOrganisationId}
            />
          </FormControl>
          {selectedOrganisationId && selectedOrganisationLocations && (
            <FormControl>
              <FormLabel>Business Locations</FormLabel>
              <Select
                onChange={(e) => console.log(e.target.value)}
                placeholder="Add existing location..."
              >
                {selectedOrganisationLocations?.map((buyer) => (
                  <option
                    key={buyer.id}
                    value={buyer.id}
                    disabled={existingBuyerLocationIds.has(buyer.id)}
                  >
                    {existingBuyerLocationIds.has(buyer.id) ? "✅" : ""}
                    {buyer.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {/*<FormControl>*/}
          {/*  <FormLabel>Customer</FormLabel>*/}
          {/*  <Select*/}
          {/*    onChange={(e) => setSelectedBuyer(e.target.value)}*/}
          {/*  >*/}
          {/*    {buyers?.map((buyer) => (*/}
          {/*      <option key={buyer.id} value={buyer.id}>*/}
          {/*        {buyer.name}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          {/*<FormControl>*/}
          {/*  <FormLabel>Customer</FormLabel>*/}
          {/*  <Select*/}
          {/*    onChange={(e) => setSelectedBuyer(e.target.value)}*/}
          {/*  >*/}
          {/*    {buyers?.map((buyer) => (*/}
          {/*      <option key={buyer.id} value={buyer.id}>*/}
          {/*        {buyer.name}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          {/*<FormControl>*/}
          {/*  <FormLabel>Customer</FormLabel>*/}
          {/*  <Select*/}
          {/*    onChange={(e) => setSelectedBuyer(e.target.value)}*/}
          {/*  >*/}
          {/*    {buyers?.map((buyer) => (*/}
          {/*      <option key={buyer.id} value={buyer.id}>*/}
          {/*        {buyer.name}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          {/*<FormControl>*/}
          {/*  <FormLabel>Customer</FormLabel>*/}
          {/*  <Select*/}
          {/*    onChange={(e) => setSelectedBuyer(e.target.value)}*/}
          {/*  >*/}
          {/*    {buyers?.map((buyer) => (*/}
          {/*      <option key={buyer.id} value={buyer.id}>*/}
          {/*        {buyer.name}*/}
          {/*      </option>*/}
          {/*)}*/}
          {/*<FormControl mt={4}>*/}
          {/*  <FormLabel>Payment Type</FormLabel>*/}
          {/*  <Select*/}
          {/*    onChange={(e) => setPaymentType(e.target.value as PaymentType)}*/}
          {/*  >*/}
          {/*    {Object.values(PaymentType).map((type) => (*/}
          {/*      <option key={type} value={type}>*/}
          {/*        {paymentTypeLabels[type]}*/}
          {/*      </option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          {/*{paymentType === PaymentType.CHEQUE && (*/}
          {/*  <FormControl mt={4}>*/}
          {/*    <FormLabel>Reference Number</FormLabel>*/}
          {/*    <Input*/}
          {/*      placeholder="Reference Number"*/}
          {/*      onChange={(e) => setReferenceNumber(e.target.value)}*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*)}*/}
          {/*<FormControl mt={4}>*/}
          {/*  <FormLabel>Payment Date</FormLabel>*/}
          {/*  <Input*/}
          {/*    type="date"*/}
          {/*    onChange={(e) => setPaymentDate(e.target.value)}*/}
          {/*  />*/}
          {/*</FormControl>*/}
          {/*<FormControl mt={4}>*/}
          {/*  <FormLabel>Amount</FormLabel>*/}
          {/*  <InputGroup>*/}
          {/*    <InputLeftElement>$</InputLeftElement>*/}
          {/*    <NumberInput min={0.01}>*/}
          {/*      <NumberInputField*/}
          {/*        marginLeft={"8px"}*/}
          {/*        onChange={(e) => setAmount(e.target.value)}*/}
          {/*      />*/}
          {/*    </NumberInput>*/}
          {/*  </InputGroup>*/}
          {/*</FormControl>*/}
          {/*{paymentType === PaymentType.CHEQUE && (*/}
          {/*  <>*/}
          {/*    <FormControl mt={4}>*/}
          {/*      <FormLabel>Lodged Date</FormLabel>*/}
          {/*      <Input*/}
          {/*        type="date"*/}
          {/*        onChange={(e) => setLodgedDate(e.target.value)}*/}
          {/*      />*/}
          {/*    </FormControl>*/}
          {/*    <FormControl mt={4}>*/}
          {/*      <Checkbox*/}
          {/*        isChecked={hasCheckBounced}*/}
          {/*        onChange={(e) => setHasCheckBounced(e.target.checked)}*/}
          {/*      >*/}
          {/*        Has check bounced?*/}
          {/*      </Checkbox>*/}
          {/*    </FormControl>*/}
          {/*    {hasCheckBounced && (*/}
          {/*      <FormControl mt={4}>*/}
          {/*        <FormLabel>Bounced Date</FormLabel>*/}
          {/*        <Input*/}
          {/*          type="date"*/}
          {/*          onChange={(e) => setBouncedDate(e.target.value)}*/}
          {/*        />*/}
          {/*      </FormControl>*/}
          {/*    )}*/}
          {/*  </>*/}
          {/*)}*/}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCustomerModal;
