import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const CalendarMonth: ComponentMultiStyleConfig = {
  parts: ["month", "name", "week", "weekday", "days"],

  baseStyle: {
    name: {
      h: 8,
      fontSize: "lg",
      fontWeight: "bold",
      lineHeight: 6,
      textAlign: "center",
      textTransform: "capitalize",
      color: "gray.800",
    },

    week: {
      gridTemplateColumns: "repeat(7, 1fr)",
    },

    weekday: {
      color: "gray.500",
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: "xs",
      fontWeight: "bold",
      padding: "4px 0",
      borderTop: "1px solid",
      borderColor: "gray.100",
    },

    days: {
      gridTemplateColumns: "repeat(7, 1fr)",
      gap: 1,
    },
  },

  defaultProps: {
    name: {
      as: "h2",
    },
  },
};
