import React, { useState } from "react";
import {
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  VStack,
  useToast,
  StackProps,
  Button,
} from "@chakra-ui/react";

interface EmailAddressFormProps extends StackProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
}

const EmailAddressForm = ({
  emails,
  setEmails,
  ...props
}: EmailAddressFormProps): React.ReactElement => {
  const [inputValue, setInputValue] = useState("");
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const toast = useToast();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleAddEmail = () => {
    if (
      inputValue &&
      emailRegex.test(inputValue) &&
      !emails.includes(inputValue)
    ) {
      setEmails([...emails, inputValue]);
      setInputValue("");
    } else {
      toast({
        title: "Invalid or duplicate email.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleDeleteEmail = (index: number) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleEditEmail = (index: number) => {
    setInputValue(emails[index]);
    setEditingIndex(index);
  };

  const handleSaveEdit = () => {
    if (editingIndex !== null && inputValue && emailRegex.test(inputValue)) {
      const updatedEmails = [...emails];
      updatedEmails[editingIndex] = inputValue;
      setEmails(updatedEmails);
      setInputValue("");
      setEditingIndex(null);
    } else {
      toast({
        title: "Invalid email format.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch" {...props}>
      <HStack>
        <Input
          placeholder="Enter email address"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const isEditing = editingIndex !== null;
              if (isEditing) {
                handleSaveEdit();
              } else {
                handleAddEmail();
              }
            }
          }}
        />
        <Button
          variant={editingIndex !== null || !inputValue ? "outline" : "solid"}
          colorScheme="teal"
          onClick={editingIndex !== null ? handleSaveEdit : handleAddEmail}
        >
          {editingIndex !== null ? "Save" : "Add"}
        </Button>
      </HStack>
      <HStack wrap="wrap">
        {emails.map((email, index) => (
          <Tag
            key={index}
            size="lg"
            borderRadius="full"
            variant="solid"
            colorScheme="teal"
            onDoubleClick={() => handleEditEmail(index)}
          >
            <TagLabel>{email}</TagLabel>
            <TagCloseButton onClick={() => handleDeleteEmail(index)} />
          </Tag>
        ))}
      </HStack>
    </VStack>
  );
};

export default EmailAddressForm;
