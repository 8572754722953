import { formatCurrency } from "../../utils/invoiceUtils";
import GridPageOverview from "../../components/PageOverview/GridPageOverview.tsx";

interface SalesOverviewProps {
  totalSales: number;
  totalForecasted: number;
  startDate: string;
  endDate: string;
}

function SalesOverview({
  totalSales,
  totalForecasted,
  startDate,
  endDate,
}: SalesOverviewProps) {
  return (
    <GridPageOverview
      heading={"Sales Performance"}
      subheading={startDate && endDate && `${startDate} - ${endDate}`}
      showDateRangePicker={true}
      mainKPI={{
        title: "",
        stat: "-0.07%",
        helpText:
          "Your location is relatively on target so far this week, with a slight decrease compared to forecasts.",
      }}
      secondaryKPI={{
        title: "",
        stat: "4.8%",
        helpText:
          "There has been an uptick in business over the past day, with a 4.8% increase in sales vs. forecasts.",
      }}
      stats={[
        {
          title: "Total Sales (WTD)",
          stat: "$" + formatCurrency(totalSales),
          helpText: "1.4% up from last week",
          arrowType: "increase",
        },
        {
          title: "Total Forecasted",
          stat: "$" + formatCurrency(totalForecasted),
          helpText: "2.1% up from last week",
          arrowType: "increase",
        },
      ]}
    />
  );
}

export default SalesOverview;
