import React from "react";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import EditableColumn from "../../components/DataTable/EditableColumn";
import { createColumnHelper } from "@tanstack/react-table";
import { EventSalesReconciliationItem } from "../../types";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Flex, Box, Text } from "@chakra-ui/react";

interface StockReconciliationInputProps {
  reconciliationItems: EventSalesReconciliationItem[];
  setReconciliationItems: (items: EventSalesReconciliationItem[]) => void;
  isOpening?: boolean;
  enableAllEditing?: boolean;
  height?: number;
  productsInStock?: Set<number>;
}

const StockReconciliationListInput = ({
  reconciliationItems = [],
  setReconciliationItems,
  isOpening = true,
  enableAllEditing = false,
  height = 400,
  productsInStock,
}: StockReconciliationInputProps): React.ReactElement => {
  const handleQuantityChange = (
    index: number,
    key: keyof EventSalesReconciliationItem = "opening_quantity",
    value: number | null,
  ) => {
    const newItems = [...reconciliationItems];
    const item: EventSalesReconciliationItem = { ...newItems[index] };
    // @ts-expect-error - types are valid
    item[key as keyof EventSalesReconciliationItem] = value;
    // if opening and closing quantity are defined, calculate variance. if sales and wasted quantity are defined, update variance
    const openingQuantity = Number(item.opening_quantity);
    const closingQuantity = Number(item.closing_quantity);
    const soldQuantity = Number(item.sold_quantity);
    const wastedQuantity = Number(item.wasted_quantity);
    let varianceQuantity = 0;

    if (!isNaN(openingQuantity) && !isNaN(closingQuantity)) {
      varianceQuantity = closingQuantity - openingQuantity;
      if (!isNaN(soldQuantity)) {
        varianceQuantity += soldQuantity;
      }
      if (!isNaN(wastedQuantity)) {
        varianceQuantity += wastedQuantity;
      }
    }
    item.variance_quantity = varianceQuantity;
    newItems[index] = item;
    setReconciliationItems(newItems);
  };

  const columnHelper = createColumnHelper<EventSalesReconciliationItem>();

  const columns = [
    {
      accessorKey: "product",
      header: "Product",
      cell: (info) => (
        <Text noOfLines={1} title={info.getValue()?.name}>
          {info.getValue()?.name}
        </Text>
      ),
      width: "20%",
      minWidth: "150px",
    },
    {
      accessorKey: "product_unit",
      header: "Unit",
      cell: (info) => (
        <Text noOfLines={1} title={getProductUnitLabel(info.getValue())}>
          {getProductUnitLabel(info.getValue())}
        </Text>
      ),
      width: "15%",
      minWidth: "100px",
    },
    {
      accessorKey: "opening_quantity",
      header: "Opening quantity",
      cell: (info) => (
        <EditableColumn
          inputId={`opening-quantity-${info.row.index}`}
          value={info.getValue()}
          inputType={"number"}
          setValue={(value: string) => {
            if (!isNaN(Number(value))) {
              handleQuantityChange(
                info.row.index,
                "opening_quantity",
                Number(value),
              );
            } else {
              handleQuantityChange(info.row.index, "opening_quantity", null);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Tab") {
              // select next cell if not last cell
              const nextKey = isOpening
                ? "opening-quantity"
                : "closing-quantity";
              const nextIndex = isOpening ? info.row.index + 1 : info.row.index;
              if (nextIndex < reconciliationItems.length) {
                const nextCell = document.getElementById(
                  `${nextKey}-${nextIndex}`,
                );
                nextCell?.focus();
              }
            }
          }}
          isEditable={enableAllEditing || isOpening}
        />
      ),
      width: "13%",
      minWidth: "120px",
    },
    columnHelper.accessor("closing_quantity", {
      cell: (info) => (
        <EditableColumn
          inputId={`closing-quantity-${info.row.index}`}
          value={info.getValue()}
          setValue={(value: string) =>
            handleQuantityChange(
              info.row.index,
              "closing_quantity",
              Number(value),
            )
          }
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Tab") {
              // select next cell if not last cell
              const nextCell = document.getElementById(
                `sold-quantity-${info.row.index}`,
              );
              nextCell?.focus();
            }
          }}
          isEditable={enableAllEditing || !isOpening}
        />
      ),
      header: "Closing quantity",
      width: "13%",
      minWidth: "120px",
    }),
    columnHelper.accessor("sold_quantity", {
      cell: (info) => (
        <EditableColumn
          inputId={`sold-quantity-${info.row.index}`}
          value={info.getValue()}
          setValue={(value: string) =>
            handleQuantityChange(info.row.index, "sold_quantity", Number(value))
          }
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Tab") {
              // select next cell if not last cell
              const nextCell = document.getElementById(
                `wasted-quantity-${info.row.index}`,
              );
              nextCell?.focus();
            }
          }}
          isEditable={enableAllEditing || !isOpening}
        />
      ),
      header: "Sold quantity",
      width: "13%",
      minWidth: "120px",
    }),
    columnHelper.accessor("wasted_quantity", {
      cell: (info) => (
        <EditableColumn
          inputId={`wasted-quantity-${info.row.index}`}
          value={info.getValue()}
          setValue={(value: string) =>
            handleQuantityChange(
              info.row.index,
              "wasted_quantity",
              Number(value),
            )
          }
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Tab") {
              // select next cell if not last cell
              if (info.row.index < reconciliationItems.length - 1) {
                const nextCell = document.getElementById(
                  `closing-quantity-${info.row.index + 1}`,
                );
                nextCell?.focus();
              }
            }
          }}
          isEditable={enableAllEditing || !isOpening}
        />
      ),
      header: "Wasted quantity",
      width: "13%",
      minWidth: "120px",
    }),
    columnHelper.accessor("variance_quantity", {
      cell: (info) => (
        <EditableColumn
          value={info.getValue()}
          setValue={(value: string) =>
            handleQuantityChange(
              info.row.index,
              "variance_quantity",
              Number(value),
            )
          }
          isEditable={false}
        />
      ),
      header: "Variance",
      width: "13%",
      minWidth: "120px",
    }),
  ];

  const HeaderRow = ({ columns }: { columns: any[] }) => (
    <Flex
      borderBottom="1px solid"
      borderColor="gray.200"
      bg="gray.50"
      p={2}
      fontWeight="bold"
      width="100%"
    >
      {columns.map((column, index) => (
        <Box
          key={index}
          width={column.width}
          minWidth={column.minWidth}
          flexShrink={0}
          textAlign="left"
        >
          <Text title={column.header}>{column.header}</Text>
        </Box>
      ))}
    </Flex>
  );

  const VirtualizedRow = ({
    index,
    style,
    columns,
  }: {
    index: number;
    style: React.CSSProperties;
    columns: any[];
  }) => {
    const item = reconciliationItems[index];
    return (
      <Flex
        style={style}
        borderBottom="1px solid"
        borderColor="gray.100"
        alignItems="center"
        _hover={{ bg: "gray.50" }}
      >
        {columns.map((column, colIndex) => (
          <Box
            key={colIndex}
            width={column.width}
            minWidth={column.minWidth}
            flexShrink={0}
            p={2}
            color={productsInStock?.has(item.product_id) ? "black" : "red.500"}
          >
            {column.cell({
              getValue: () => item[column.accessorKey],
              row: { index },
            })}
          </Box>
        ))}
      </Flex>
    );
  };

  return (
    <Box
      height={`${height}px`}
      width="100%"
      borderWidth={1}
      borderColor="gray.200"
      borderRadius="md"
      overflow="auto"
    >
      <Box minWidth="100%">
        <HeaderRow columns={columns} />
        <Box height={`${height - 40}px`} width="100%">
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={height - 40}
                itemCount={reconciliationItems.length}
                itemSize={50}
                width={width}
                itemData={columns}
              >
                {({ index, style }) => (
                  <VirtualizedRow
                    index={index}
                    style={style}
                    columns={columns}
                  />
                )}
              </List>
            )}
          </AutoSizer>
        </Box>
      </Box>
    </Box>
  );
};

export default StockReconciliationListInput;
