import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { formatISODate, getLocalDate } from "../utils/dateUtils";

export enum DateRangeOptions {
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export interface DateState {
  start_date: string;
  end_date: string;
  option: DateRangeOptions;
  startOfWeekIndex: number;
}

interface DateShiftPayload {
  direction: "forward" | "backward";
}

const getDefaultStartAndEndDates = (
  dateRangeOption: DateRangeOptions,
  startOfWeekIndex: number = 1,
): [string, string] => {
  const now = new Date();
  let start_date: Date;
  let end_date: Date;

  switch (dateRangeOption) {
    case DateRangeOptions.DAILY:
      start_date = new Date(now);
      end_date = new Date(now);
      break;
    case DateRangeOptions.WEEKLY:
      const dayOfWeek = now.getDay();
      start_date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - dayOfWeek + startOfWeekIndex,
      );
      end_date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + (6 - dayOfWeek + startOfWeekIndex),
      );
      break;
    case DateRangeOptions.MONTHLY:
      start_date = new Date(now.getFullYear(), now.getMonth(), 1);
      end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      break;
    case DateRangeOptions.YEARLY:
      start_date = new Date(now.getFullYear(), 0, 1);
      end_date = new Date(now.getFullYear(), 11, 31);
      break;
  }

  return [formatISODate(start_date), formatISODate(end_date)];
};

const [start_date, end_date] = getDefaultStartAndEndDates(
  DateRangeOptions.MONTHLY,
);

const initialState = {
  start_date: start_date,
  end_date: end_date,
  option: DateRangeOptions.MONTHLY,
  startOfWeekIndex: 1,
} satisfies DateState as DateState;

const addDays = (date: string | Date, days: number): Date => {
  const result = typeof date === "string" ? new Date(date) : date;
  result.setDate(result.getDate() + days);
  return result;
};

const addWeeks = (date: string, weeks: number): Date => {
  const dayMultiplier = 7 + (weeks > 0 ? 1 : -1);
  return addDays(date, weeks * dayMultiplier);
};

const addMonths = (
  date: string,
  months: number,
  isEndDate: boolean = false,
): Date => {
  const result = getLocalDate(date);
  // set day to 1 to avoid issues with months that have different number of days
  result.setDate(1);
  result.setMonth(result.getMonth() + months);

  if (isEndDate) {
    result.setMonth(result.getMonth() + 1);
    result.setDate(0); // set to last day of next month
  }

  return result;
};

const addYears = (date: string, years: number): Date => {
  const result = new Date(date);
  result.setFullYear(result.getFullYear() + years);
  return result;
};

export const filterDatesSlice = createSlice({
  name: "filterDates",
  initialState,
  reducers: {
    update(state, action: PayloadAction<DateState>) {
      state.start_date = action.payload.start_date;
      state.end_date = action.payload.end_date;
    },
    setOption(state, action: PayloadAction<DateRangeOptions>) {
      state.option = action.payload;
      [state.start_date, state.end_date] = getDefaultStartAndEndDates(
        action.payload,
        state.startOfWeekIndex,
      );
    },
    shift(state, action: PayloadAction<DateShiftPayload>) {
      const { direction } = action.payload;
      const option = state.option;
      const multiplier = direction === "forward" ? 1 : -1;

      switch (option) {
        case DateRangeOptions.DAILY:
          state.start_date = formatISODate(
            addDays(state.start_date, 1 * multiplier),
          );
          state.end_date = formatISODate(
            addDays(state.end_date, 1 * multiplier),
          );
          break;
        case DateRangeOptions.WEEKLY:
          let newStart = addWeeks(state.start_date, 1 * multiplier);
          if (newStart.getDay() !== state.startOfWeekIndex) {
            newStart = addDays(
              newStart,
              state.startOfWeekIndex - newStart.getDay(),
            );
          }
          let newEnd = addWeeks(state.end_date, 1 * multiplier);
          const endOfWeekIndex = (state.startOfWeekIndex + 6) % 7;
          if (newEnd.getDay() !== endOfWeekIndex) {
            newEnd = addDays(newEnd, endOfWeekIndex - newEnd.getDay());
          }
          state.start_date = formatISODate(newStart);
          state.end_date = formatISODate(newEnd);
          break;
        case DateRangeOptions.MONTHLY:
          state.start_date = formatISODate(
            addMonths(state.start_date, 1 * multiplier),
          );
          state.end_date = formatISODate(
            addMonths(state.end_date, 1 * multiplier, true),
          );
          break;
        case DateRangeOptions.YEARLY:
          state.start_date = formatISODate(
            addYears(state.start_date, 1 * multiplier),
          );
          state.end_date = formatISODate(
            addYears(state.end_date, 1 * multiplier),
          );
          break;
      }
    },
  },
  selectors: {
    getStartDate: (state): string => state.start_date,
    getEndDate: (state): string => state.end_date,
    getOption: (state): DateRangeOptions => state.option,
  },
});

export const { update, setOption, shift } = filterDatesSlice.actions;
