import {
  FormControl,
  FormLabel,
  HStack,
  Select,
  Skeleton,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { useGetInvoicesQuery } from "../../redux/apiSlice";

interface ContainerInvoiceFormFieldProps {
  invoiceIds: number[];
  setInvoiceIds: (invoiceIds: number[]) => void;
}

const ContainerInvoiceFormField = ({
  invoiceIds,
  setInvoiceIds,
}: ContainerInvoiceFormFieldProps): React.ReactElement => {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: invoices, isLoading: invoicesLoading } = useGetInvoicesQuery({
    organisationId,
    locationId,
  });

  const [unselectedInvoiceIds, setUnselectedInvoiceIds] = useState<number[]>(
    [],
  );
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<number[]>([]);
  const [isAddingInvoice, setIsAddingInvoice] = useState<boolean>(false);

  useEffect(() => {
    if (invoices) {
      setUnselectedInvoiceIds(
        invoices
          .map((invoice) => {
            if (invoice.id && !selectedInvoiceIds.includes(invoice.id)) {
              return invoice.id;
            }
            return undefined;
          })
          .filter((id) => id !== undefined) as number[],
      );
    }
  }, [invoices]);

  useEffect(() => {
    if (invoiceIds) {
      setSelectedInvoiceIds(invoiceIds);
    } else {
      setSelectedInvoiceIds([]);
    }
  }, [invoiceIds]);

  const hideInvoiceSelect = () => {
    setIsAddingInvoice(true);
    setTimeout(() => {
      setIsAddingInvoice(false);
    }, 400);
  };

  const handleAddInvoiceId = (invoiceId: number) => {
    if (!invoiceId) {
      return;
    }
    hideInvoiceSelect();
    setSelectedInvoiceIds([...selectedInvoiceIds, invoiceId]);
    setUnselectedInvoiceIds(
      unselectedInvoiceIds.filter((id) => id !== invoiceId),
    );
    setInvoiceIds([...selectedInvoiceIds, invoiceId]);
  };

  const handleRemoveInvoiceId = (invoiceId: number) => {
    if (!invoiceId) {
      return;
    }
    hideInvoiceSelect();
    const updatedInvoiceIds = selectedInvoiceIds.filter(
      (id) => id !== invoiceId,
    );
    setSelectedInvoiceIds(updatedInvoiceIds);
    setUnselectedInvoiceIds([...unselectedInvoiceIds, invoiceId]);
    setInvoiceIds(updatedInvoiceIds);
  };

  return (
    <FormControl m={"12px"}>
      <FormLabel>Invoice(s) in Container</FormLabel>
      {isAddingInvoice || invoicesLoading ? (
        <Skeleton height="20px" width="150px" />
      ) : (
        <Select
          marginLeft="4px"
          w="250px"
          onChange={(e) => handleAddInvoiceId(Number(e.target.value))}
          placeholder="Add invoices..."
        >
          {unselectedInvoiceIds.map((invoiceId, index) => (
            <option key={index} value={invoiceId}>
              {invoiceId}
            </option>
          ))}
        </Select>
      )}
      <HStack spacing={4} marginTop={"8px"}>
        {selectedInvoiceIds.map((invoiceId, index) => (
          <Tag size={"sm"} key={index} variant="subtle" colorScheme="cyan">
            <TagLabel>{invoiceId}</TagLabel>
            <TagCloseButton onClick={() => handleRemoveInvoiceId(invoiceId)} />
          </Tag>
        ))}
      </HStack>
    </FormControl>
  );
};

export default ContainerInvoiceFormField;
