import { Address, Location, OrderItem, Price } from "../../types";
import { getLocationAndOrgName } from "../../utils/invoiceUtils";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import OrderSummary from "./OrderSummary";
import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import AddressSearchOrCreate from "../../components/AddressSearchOrCreate/AddressSearchOrCreate";

const OrderReview = ({
  buyer,
  supplier,
  orderItems,
  productUnitPriceMap,
  setOrderItems,
  deliveryDate,
  setDeliveryDate,
  deliveryMethod,
  setDeliveryMethod,
  isSalesOrder = false,
  showErrors,
  buyerPONumber,
  setBuyerPONumber,
  setDeliveryAddressId,
}: {
  buyer: Location;
  supplier: Location;
  orderItems: OrderItem[];
  productUnitPriceMap: Map<number, Price[]>;
  setOrderItems: (value: OrderItem[]) => void;
  deliveryDate: string;
  setDeliveryDate: (value: string) => void;
  deliveryMethod: string;
  setDeliveryMethod: (value: string) => void;
  isSalesOrder?: boolean;
  showErrors: boolean;
  buyerPONumber: string | undefined;
  setBuyerPONumber: (value: string) => void;
  setDeliveryAddressId: (value: number | undefined) => void;
}): React.ReactElement => {
  const [orderTotal, setOrderTotal] = useState<number>(0);
  const [address, setAddress] = useState<Address | null>(null);

  useEffect(() => {
    if (address?.id) {
      setDeliveryAddressId(address.id);
    } else {
      setDeliveryAddressId(undefined);
    }
  }, [address?.id, setDeliveryAddressId]);

  const getHeading = () =>
    isSalesOrder
      ? `Order for ${getLocationAndOrgName(buyer)}`
      : getLocationAndOrgName(supplier);

  const getDeliveryName = () =>
    deliveryMethod === "delivery" ? buyer.name : supplier.name;

  return (
    <>
      <Heading
        as={"h2"}
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        fontSize={"24"}
        mb="2%"
      >
        Review Order
      </Heading>
      <FormControl as={GridItem} colSpan={[3, 2]}>
        <Heading
          as={"h3"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          fontSize={"18"}
          mb="2%"
        >
          {getHeading()}
        </Heading>
        <HStack>
          <div>
            <Text as="b">
              {deliveryMethod === "delivery" ? "Deliver to" : "Pickup at"}:
            </Text>
            <Text>{getDeliveryName()}</Text>
            <AddressSearchOrCreate
              organisationId={
                deliveryMethod === "delivery"
                  ? buyer.organisation_id
                  : supplier.organisation_id
              }
              locationId={
                deliveryMethod === "delivery" ? buyer.id : supplier.id
              }
              includeCountry={Boolean(
                buyer.address?.country &&
                  supplier.address?.country &&
                  buyer.address?.country !== supplier.address?.country,
              )}
              setAddress={setAddress}
            />
          </div>
          <Spacer />
          <Box>
            <FormLabel
              as="b"
              htmlFor="ext po number"
              fontSize="sm"
              fontWeight="md"
              color="gray.700"
              _dark={{
                color: "gray.50",
              }}
              mb="6"
              whiteSpace="nowrap"
            >
              Ext. PO Number
            </FormLabel>
            <Input
              value={buyerPONumber || ""}
              type="text"
              onChange={(e) => setBuyerPONumber(e.target.value)}
              focusBorderColor="brand.400"
              rounded="md"
            />
          </Box>
        </HStack>
        <Flex paddingTop={"1rem"} flexDirection={["column", "row"]} gap="2">
          <Stack>
            <FormLabel
              as="b"
              htmlFor="order date"
              fontSize="sm"
              fontWeight="md"
              color="gray.700"
              _dark={{
                color: "gray.50",
              }}
            >
              Order Date
            </FormLabel>
            <Input
              value={new Date().toLocaleDateString()}
              isReadOnly
              focusBorderColor="brand.400"
              rounded="md"
            />
          </Stack>
          <Spacer />
          <Stack>
            <FormLabel
              as="b"
              htmlFor="delivery method"
              fontSize="sm"
              fontWeight="md"
              color="gray.700"
              _dark={{
                color: "gray.50",
              }}
            >
              Delivery Method
            </FormLabel>
            <Select
              value={deliveryMethod}
              onChange={(e) => setDeliveryMethod(e.target.value)}
            >
              <option value="pickup">Pickup</option>
              <option value="delivery">Delivery</option>
            </Select>
          </Stack>
          <Spacer />
          <Stack>
            <FormLabel
              as="b"
              htmlFor="delivery date"
              fontSize="sm"
              fontWeight="md"
              color="gray.700"
              _dark={{
                color: "gray.50",
              }}
            >
              {capitalizeFirstLetter(deliveryMethod)} Date
            </FormLabel>
            <Input
              placeholder="Select Date and Time"
              size="md"
              type="date"
              value={deliveryDate}
              onChange={(e) => setDeliveryDate(e.target.value.slice(0, 10))}
              isInvalid={
                showErrors && (!deliveryDate || deliveryDate.length < 1)
              }
            />
          </Stack>
        </Flex>
      </FormControl>
      <SimpleGrid columns={1} spacing={6} marginTop={"8px"}>
        <OrderSummary
          isSalesOrder={isSalesOrder}
          orderItems={orderItems}
          productUnitPriceMap={productUnitPriceMap}
          setOrderItems={setOrderItems}
          setOrderTotal={setOrderTotal}
          onChange={() => {}}
        />
        <Stack direction="row" style={{ marginTop: "auto" }}>
          <span>
            <strong>Order Total:</strong>
          </span>
          <Spacer />
          <span>
            <strong>${orderTotal.toFixed(2)}</strong>
          </span>
        </Stack>
      </SimpleGrid>
    </>
  );
};

export default OrderReview;
