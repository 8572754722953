import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { usePostContainerMutation } from "../../redux/apiSlice";
import { Container } from "../../types";
import * as Sentry from "@sentry/react";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import ContainerInvoiceFormField from "./ContainerInvoiceFormField";

interface AddContainerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddContainerModal = ({ isOpen, onClose }: AddContainerModalProps) => {
  const toast = useToast();

  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const [postContainer, { isLoading }] = usePostContainerMutation();

  const [containerNumber, setContainerNumber] = useState<string>("");
  const [invoiceIds, setInvoiceIds] = useState<number[]>([]);
  const [expectedArrival, setExpectedArrival] = useState<string>("");
  const [originCountry, setOriginCountry] = useState<string>("");

  useEffect(() => {
    if (!isOpen) {
      setContainerNumber("");
      setInvoiceIds([]);
      setExpectedArrival("");
    }
  }, [isOpen]);

  const handleChangedExpectedArrivalDate = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setExpectedArrival(e.target.value.slice(0, 10));
  };

  const handleAddContainer = () => {
    if (!isLoading) {
      if (
        !containerNumber ||
        containerNumber.length === 0 ||
        !expectedArrival ||
        expectedArrival.length === 0
      ) {
        toast({
          title: "Container number and expected arrival are required.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        postContainer({
          organisation_id: Number(organisationId),
          location_id: Number(locationId),
          number: containerNumber,
          expected_arrival: expectedArrival,
          status: "NEW",
          container_invoices: invoiceIds.map((invoiceId) => ({
            invoice_id: invoiceId,
          })),
          origin_country: originCountry,
        } as Container)
          .unwrap()
          .then(() => {
            toast({
              title: "Container successfully added for tracking.",
              description: "You can view this in the Containers list.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          })
          .catch((error) => {
            try {
              Sentry.captureException(JSON.stringify(error));
            } catch {
              Sentry.captureException(error);
            }
            toast({
              title: "Container failed to create.",
              description:
                "Please try again later & contact support if issue persists.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add container</ModalHeader>
        <ModalBody>
          <Stack>
            <Flex flexDirection={["column", "row"]}>
              <FormControl m={"12px"}>
                <FormLabel>Container Number</FormLabel>
                <Input
                  placeholder={"Container number..."}
                  onChange={(e) => setContainerNumber(e.target.value)}
                  w="250px"
                />
              </FormControl>
              <FormControl m={"12px"}>
                <FormLabel>Origin Country</FormLabel>
                <Input
                  placeholder={"Origin country..."}
                  onChange={(e) => setOriginCountry(e.target.value)}
                  w="250px"
                />
              </FormControl>
            </Flex>
            <ContainerInvoiceFormField
              invoiceIds={invoiceIds}
              setInvoiceIds={setInvoiceIds}
            />
            <FormControl m={"12px"}>
              <FormLabel>Expected Arrival</FormLabel>
              <Input
                marginLeft="4px"
                w="250px"
                type="date"
                value={expectedArrival}
                onChange={handleChangedExpectedArrivalDate}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={() => handleAddContainer()}
            isLoading={isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddContainerModal;
