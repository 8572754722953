import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Card,
  CardHeader,
  CardBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Select,
  NumberInputField,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
} from "@chakra-ui/react";
import InteractiveRow from "../../components/InteractiveRow/InteractiveRow";
import { Location, Price, ProductUnitAndPrices } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useLocation } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import { getLocationAndOrgName } from "../../utils/invoiceUtils";

interface PriceViewEntryProps {
  itemIndex: number;
  item: ProductUnitAndPrices;
  prices: Price[];
  buyers: Location[];
  children?: React.ReactNode[];
  onClick?: () => void;
}

export default function PriceViewEntry({
  itemIndex,
  item,
  prices,
  buyers,
  children,
}: PriceViewEntryProps) {
  const navLocation = useLocation();
  const [version, setVersion] = useState<number>(0);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [defaultPrice, setDefaultPrice] = useState<Price | undefined>(
  //   prices.find(
  //     (price) =>
  //       price.product_unit_id === item.product_unit.id &&
  //       !price.buyer_organisation_id,
  //   ),
  // );
  const [newPrice, setNewPrice] = useState<string>("0.00");
  // defaultPrice ? Number(defaultPrice.price).toFixed(2) : "0.00");
  const [priceEdit, setPriceEdit] = useState<string>(
    Number(item.current_price).toFixed(2),
  );

  const [customerPrices, setCustomerPrices] = useState<Price[]>(
    prices ? prices.filter((price) => price.buyer_organisation_id) : [],
  );
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceEdit(Number(e.target.value).toFixed(2));
  };

  const calculateProfitMargin = (salePrice: number, supplyPrice: number) => {
    return ((salePrice - supplyPrice) / salePrice) * 100;
  };

  useEffect(() => {
    setNewPrice(Number(item.current_price).toFixed(2));
    setPriceEdit(Number(item.current_price).toFixed(2));
  }, [item]);

  useEffect(() => {
    if (navLocation.search) {
      const query = new URLSearchParams(navLocation.search);
      const version = query.get("v");
      if (version) {
        setVersion(Number(version));
      }
    }
  }, [navLocation]);

  useEffect(() => {
    setCustomerPrices(
      prices ? prices.filter((price) => price.buyer_organisation_id) : [],
    );
  }, [prices]);

  const getBuyerName = (buyerOrgId: number) => {
    const buyer = buyers.find((buyer) => buyer.id === buyerOrgId);
    return buyer ? buyer.name : `Organisation ${buyerOrgId}`;
  };

  const supplierPriceData = [
    {
      date: "2023-05-06",
      supplier_price: (Number(item.supplier_price) * 0.8).toFixed(2),
      forecasted_price: null,
    },
    {
      date: "2023-07-15",
      supplier_price: (Number(item.supplier_price) * 0.9).toFixed(2),
      forecasted_price: null,
    },
    {
      date: "2023-09-23",
      supplier_price: (Number(item.supplier_price) * 0.95).toFixed(2),
      forecasted_price: null,
    },
    {
      date: "2023-11-30",
      supplier_price: (Number(item.supplier_price) * 1.15).toFixed(2),
      forecasted_price: null,
    },
    {
      date: "2024-01-01",
      supplier_price: (Number(item.supplier_price) * 1.2).toFixed(2),
      forecasted_price: null,
    },
    {
      date: "2024-03-15",
      supplier_price: Number(item.supplier_price).toFixed(2),
      forecasted_price: null,
    },
    {
      date: "2024-05-06",
      supplier_price: (Number(item.supplier_price) * 0.95).toFixed(2),
      forecasted_price: (Number(item.supplier_price) * 0.95).toFixed(2),
    },
    {
      date: "2024-07-15",
      supplier_price: null,
      forecasted_price: (Number(item.supplier_price) * 0.9).toFixed(2),
    },
    {
      date: "2024-09-23",
      supplier_price: null,
      forecasted_price: (Number(item.supplier_price) * 0.95).toFixed(2),
    },
    {
      date: "2024-11-30",
      supplier_price: null,
      forecasted_price: (Number(item.supplier_price) * 1.05).toFixed(2),
    },
    {
      date: "2025-01-01",
      supplier_price: null,
      forecasted_price: (Number(item.supplier_price) * 1.1).toFixed(2),
    },
    {
      date: "2025-03-15",
      supplier_price: null,
      forecasted_price: Number(item.supplier_price).toFixed(2),
    },
  ];

  const tickY = {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Oxygen-Sans, Helvetica Neue, sans-serif",
    fontSize: 14,
    fontWeight: 400,
  };

  const columnHelper = createColumnHelper<{
    description: string;
    value: number;
    costPerCase: number;
    costPerUnit: number;
  }>();

  const getColumns = () => {
    const columns = [
      columnHelper.accessor("description", {
        cell: (info) => info.getValue(),
        header: "Description",
      }),
      columnHelper.accessor("value", {
        cell: (info) => info.getValue(),
        header: "Value",
        meta: {
          isNumeric: true,
        },
      }),
    ];
    if (item && item.product_unit.sub_unit) {
      columns.push(
        // @ts-ignore
        columnHelper.accessor("costPerCase", {
          cell: (info) => info.getValue(),
          header: "Cost Per Case",
          meta: {
            isNumeric: true,
          },
        }),
      );
    }
    columns.push(
      // @ts-ignore
      columnHelper.accessor("costPerUnit", {
        cell: (info) => info.getValue(),
        header: "Cost Per Unit",
        meta: {
          isNumeric: true,
        },
      }),
    );
    return columns;
  };

  const getInputFields = () => {
    const inputs = [
      <Input
        placeholder={"Description..."}
        // onChange={(e) => setUnitLabel(e.target.value)}
      />,
      <InputGroup>
        <InputLeftElement>$</InputLeftElement>
        <Input
          type="number"
          // defaultValue={newPrice}
          // onChange={handlePriceChange}
          // onBlur={() => setNewPrice(priceEdit)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // setNewPrice(priceEdit);
            }
          }}
        />
      </InputGroup>,
      <InputGroup>
        <InputLeftElement>$</InputLeftElement>
        <Input
          type="number"
          // defaultValue={newPrice}
          // onChange={handlePriceChange}
          // onBlur={() => setNewPrice(priceEdit)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // setNewPrice(priceEdit);
            }
          }}
        />
      </InputGroup>,
    ];
    if (item && item.product_unit.sub_unit) {
      inputs.push(
        <InputGroup>
          <InputLeftElement>$</InputLeftElement>
          <Input
            type="number"
            // defaultValue={newPrice}
            // onChange={handlePriceChange}
            // onBlur={() => setNewPrice(priceEdit)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // setNewPrice(priceEdit);
              }
            }}
          />
        </InputGroup>,
      );
    }
    return inputs;
  };

  return (
    <InteractiveRow itemIndex={itemIndex} onClick={onOpen}>
      <Modal
        size={{ base: "full", md: "6xl" }}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent p={{ base: 2, md: 4 }}>
          <ModalHeader>{item.product_unit.product_name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
              <FormControl>
                <FormLabel>Product</FormLabel>
                <Text>{item.product_unit.product_name}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>Unit</FormLabel>
                <Text>{getProductUnitLabel(item.product_unit)}</Text>
              </FormControl>
            </Stack>
            <Accordion defaultIndex={[0]} allowMultiple marginTop={"1rem"}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <b>Default Price</b>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Card p={{ base: 2, md: 4 }}>
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      spacing={4}
                    >
                      <FormControl>
                        <FormLabel>Cost per Unit</FormLabel>
                        <Text>${Number(item.supplier_price).toFixed(2)}</Text>
                      </FormControl>
                      {version > 0 && (
                        <Box
                          width="100%"
                          height={{ base: "200px", md: "100%" }}
                        >
                          <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                              width={150}
                              height={150}
                              data={supplierPriceData}
                            >
                              <ReferenceLine
                                y={item.supplier_price}
                                label={
                                  <Label
                                    value={"Current supplier price"}
                                    position={"insideLeft"}
                                  />
                                }
                                stroke="red"
                              />
                              <CartesianGrid
                                strokeWidth="1"
                                stroke="#8884d8"
                                vertical={false}
                              />
                              <XAxis
                                dataKey="date"
                                tick={false}
                                tickLine={false}
                                axisLine={{
                                  stroke: "rgba(0,0,3,0.32)",
                                  strokeWidth: 0.5,
                                }}
                              />
                              <YAxis
                                tick={tickY}
                                tickCount={6}
                                domain={["auto", "auto"]}
                                tickLine={false}
                                axisLine={false}
                                orientation="left"
                                tickFormatter={(value) =>
                                  `$${Number(value).toFixed(2)}`
                                }
                              />
                              <Tooltip
                                labelFormatter={(value) => `Date: ${value}`}
                                formatter={(value) => `$${value}`}
                              />
                              <Line
                                type="monotone"
                                dataKey="supplier_price"
                                stroke="#087E86FF"
                                strokeWidth={2}
                              />
                              <Line
                                type="monotone"
                                dataKey="forecasted_price"
                                stroke="#C94AF3FF"
                                strokeWidth={2}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </Box>
                      )}
                    </Stack>
                    {/*TODO: hide graph of supplier prices after Jasmine demo*/}
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      spacing={4}
                      marginTop="2rem"
                    >
                      <FormControl>
                        <FormLabel>Current Default Sales Price</FormLabel>
                        <Text>${Number(item.current_price).toFixed(2)}</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Current Default Profit Margin</FormLabel>
                        <Text>
                          {calculateProfitMargin(
                            item.current_price,
                            item.supplier_price,
                          ).toFixed(2)}
                          %
                        </Text>
                      </FormControl>
                    </Stack>
                    {/*TODO: add edit button and conditional display, save and cancel btns */}
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      spacing={4}
                      marginTop="2rem"
                    >
                      <FormControl>
                        <FormLabel>New Default Sales Price</FormLabel>
                        <InputGroup>
                          <InputLeftElement>$</InputLeftElement>
                          <Input
                            type="number"
                            defaultValue={newPrice}
                            onChange={handlePriceChange}
                            onBlur={() => setNewPrice(priceEdit)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                setNewPrice(priceEdit);
                              }
                            }}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl>
                        <FormLabel>New Default Profit Margin</FormLabel>
                        <Text>
                          {calculateProfitMargin(
                            Number(newPrice),
                            item.supplier_price,
                          ).toFixed(2)}
                          %
                        </Text>
                      </FormControl>
                    </Stack>
                  </Card>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <b>Price Calculator</b>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Card p={"1rem"}>
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      spacing={4}
                    >
                      <FormControl>
                        <FormLabel>Latest Invoice:</FormLabel>
                        <Text>INV-240612-TUN</Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Invoice cost per Case</FormLabel>
                        <Text>
                          ${(Number(item.supplier_price) * 12).toFixed(2)}
                        </Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Invoice cost per Unit</FormLabel>
                        <Text>${Number(item.supplier_price).toFixed(2)}</Text>
                      </FormControl>
                    </Stack>
                    <Box mt={4} overflowX={"auto"}>
                      <DataTable
                        data={[]}
                        columns={getColumns()}
                        showAddRow
                        addRowLabel={"line item"}
                        addRowChildren={getInputFields()}
                      />
                    </Box>
                  </Card>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <b>Customer Prices</b>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                    {customerPrices && customerPrices.length > 0 ? (
                      customerPrices.map((price, index) => (
                        <Card key={index} maxW={"30%"}>
                          <CardHeader>
                            {getBuyerName(price.buyer_organisation_id)}
                          </CardHeader>
                          <CardBody>
                            <Text>Price:</Text>
                            <InputGroup>
                              <InputLeftElement>$</InputLeftElement>
                              <Input
                                type="number"
                                defaultValue={Number(price.price).toFixed(2)}
                                onChange={() => {
                                  // TODO: Update the price in the state
                                }}
                                isReadOnly={true}
                              />
                            </InputGroup>
                            <Text marginY={"4px"}>
                              Profit margin:
                              {"  "}
                              {calculateProfitMargin(
                                Number(price.price),
                                item.supplier_price,
                              ).toFixed(2)}
                              %
                            </Text>
                            <Text marginY={"4px"}>
                              MOQ: {price.minimum_order_quantity}
                            </Text>
                            <Button
                              colorScheme="teal"
                              onClick={() => {
                                // Save the updated price
                              }}
                              isDisabled={true}
                            >
                              Save
                            </Button>
                          </CardBody>
                        </Card>
                      ))
                    ) : (
                      <div>No customer prices found, add one below</div>
                    )}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <b>Add new price</b>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      // Add a new price for a new buyer
                    }}
                  >
                    <Select
                    // Update the buyer organisation ID in the state
                    >
                      <option key={0} value={0}>
                        Default
                      </option>
                      {buyers.map((buyer) => (
                        <option key={buyer.id} value={buyer.id}>
                          {getLocationAndOrgName(buyer)}
                        </option>
                      ))}
                    </Select>
                    <InputGroup>
                      <InputLeftElement>$</InputLeftElement>
                      <Input
                        placeholder="Price"
                        type="number"
                        // Update the price in the state
                      />
                    </InputGroup>
                    <NumberInput defaultValue={10} min={10} max={20}>
                      <NumberInputField placeholder="Minimum Order Quantity" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Button type="submit">Add new price</Button>
                  </form>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            {item.product_unit.product_name === "Dates Pitted Deglet Nour" && (
              <Box marginTop="2rem">
                <FormLabel>Your customers prices:</FormLabel>
                <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                  <Card width={{ base: "100%", md: "30%" }}>
                    <CardHeader>East West Market</CardHeader>
                    <CardBody>$5.99 per unit</CardBody>
                  </Card>
                  <Card width={{ base: "100%", md: "30%" }}>
                    <CardHeader>Save-on-Foods</CardHeader>
                    <CardBody>$8.99 per unit</CardBody>
                  </Card>
                  <Card width={{ base: "100%", md: "30%" }}>
                    <CardHeader>Danial Market</CardHeader>
                    <CardBody>$6.99 per unit</CardBody>
                  </Card>
                </Stack>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={() => {
                toast({
                  title: "Product info saved",
                  description: "",
                  status: "success",
                  duration: 1000,
                  isClosable: true,
                });
                setTimeout(() => {
                  onClose();
                }, 1000);
              }}
            >
              Save
            </Button>
            <Spacer />
            <Button
              colorScheme="red"
              variant={"ghost"}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </InteractiveRow>
  );
}
