import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Address, DeliveryAddress } from "../../types";
import {
  useGetDeliveryAddressesQuery,
  usePostAddressMutation,
  usePostDeliveryAddressMutation,
} from "../../redux/apiSlice";
import AddressCreate from "../AddressCreate/AddressCreate";

interface AddressSearchOrCreateProps {
  organisationId: number;
  locationId: number;
  includeCountry?: boolean;
  setAddress: (address: Address) => void;
  selectedAddressId?: number;
}
const AddressSearchOrCreate = ({
  organisationId,
  locationId,
  includeCountry,
  setAddress,
  selectedAddressId,
}: AddressSearchOrCreateProps) => {
  const toast = useToast();

  const [renderSelect, setRenderSelect] = React.useState<boolean>(true);
  const [addressSelected, setAddressSelected] =
    React.useState<Address | null>();

  const [newAddress, setNewAddress] = React.useState<Address | null>();
  const [isDeliveryAddressDefault, setIsDeliveryAddressDefault] =
    React.useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: deliveryAddresses, isLoading: isLoadingDeliveryAddresses } =
    useGetDeliveryAddressesQuery(
      { organisationId, locationId },
      { skip: !organisationId || !locationId },
    );

  const [postAddress, { isLoading: postAddressLoading }] =
    usePostAddressMutation();

  const [postDeliveryAddress, { isLoading: postDeliveryAddressLoading }] =
    usePostDeliveryAddressMutation();

  useEffect(() => {
    if (deliveryAddresses && selectedAddressId) {
      const selectedDeliveryAddress = deliveryAddresses.find(
        (deliveryAddress) => deliveryAddress.address.id === selectedAddressId,
      );
      if (selectedDeliveryAddress) {
        setAddressSelected(selectedDeliveryAddress.address);
        setAddress(selectedDeliveryAddress.address);
      } else {
        const defaultAddress = deliveryAddresses.find(
          (deliveryAddress) => deliveryAddress.is_default,
        );
        if (defaultAddress) {
          setAddressSelected(defaultAddress.address);
          setAddress(defaultAddress.address);
        }
      }
    }
  }, [deliveryAddresses, selectedAddressId, setAddress]);

  const getAddress = (address: Address) => {
    return address
      ? `${address?.street_1}, ${
          address?.street_2 ? `${address?.street_2}, ` : ""
        }${address?.city}, ${address?.state}, ${address?.postal_code}${
          includeCountry ? `, ${address?.country}` : ""
        }`
      : "";
  };

  const handleOnSave = () => {
    if (newAddress) {
      if (
        !newAddress.country ||
        !newAddress.postal_code ||
        !newAddress.state ||
        !newAddress.city ||
        !newAddress.street_1
      ) {
        toast({
          title: "Error",
          description: "Please fill out all fields",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      postAddress(newAddress)
        .unwrap()
        .then((response) => {
          newAddress.id = response.id;
          const deliveryAddress = {
            organisation_id: organisationId,
            location_id: locationId,
            address_id: response.id,
            is_default: isDeliveryAddressDefault,
          } as DeliveryAddress;
          postDeliveryAddress(deliveryAddress)
            .unwrap()
            .then(() => {
              toast({
                title: "Success",
                description: "New delivery address added successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              setAddressSelected(newAddress);
              setAddress(newAddress);
              onClose();
            })
            .catch((error) => {
              toast({
                title: "Error - failed to create delivery address",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            });
        })
        .catch((error) => {
          toast({
            title: "Error creating address",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Box>
      {renderSelect && (
        <>
          {isLoadingDeliveryAddresses ? (
            <Skeleton h={"30px"} />
          ) : (
            <Select
              placeholder="Select Address"
              value={addressSelected?.id}
              onChange={(e) => {
                if (e.target.value === "create") {
                  setAddressSelected(null);
                  onOpen();
                  setRenderSelect(false);
                  setTimeout(() => {
                    setRenderSelect(true);
                  }, 5);
                  return;
                }
                const deliveryAddress = deliveryAddresses?.find(
                  (deliveryAddress) =>
                    deliveryAddress.address.id === Number(e.target.value),
                );
                if (deliveryAddress) {
                  setAddressSelected(deliveryAddress.address);
                  setAddress(deliveryAddress.address);
                }
              }}
            >
              {deliveryAddresses?.map((deliveryAddress) => (
                <option
                  key={deliveryAddress.address.id}
                  value={deliveryAddress.address.id}
                >
                  {getAddress(deliveryAddress.address)}
                </option>
              ))}
              <option value="create"> + Add Delivery Address</option>
            </Select>
          )}
        </>
      )}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Address</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AddressCreate updateAddress={setNewAddress} />
              <Checkbox
                mt={4}
                isChecked={isDeliveryAddressDefault}
                onChange={() =>
                  setIsDeliveryAddressDefault(!isDeliveryAddressDefault)
                }
              >
                Set as default delivery address
              </Checkbox>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme={"teal"}
                mr={3}
                onClick={handleOnSave}
                isDisabled={
                  !newAddress ||
                  !newAddress.street_1 ||
                  !newAddress.city ||
                  !newAddress.state ||
                  !newAddress.postal_code
                }
                isLoading={postAddressLoading || postDeliveryAddressLoading}
              >
                Save
              </Button>
              <Spacer />
              <Button colorScheme="red" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default AddressSearchOrCreate;
