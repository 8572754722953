import { Order } from "../../types";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Grid,
} from "@chakra-ui/react";
import OrderSummary from "./OrderSummary";
import InvoiceModal from "./InvoiceModal";
import {
  useGetInvoiceByOrderIdQuery,
  usePutOrderItemsMutation,
} from "../../redux/apiSlice";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { EditCustomerModal } from "./EditCustomerModal";

interface OrderDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  order: Order;
  supplierOrCustomerName: string;
  isSupplierView?: boolean;
}

function OrderDetailModal({
  isOpen,
  onClose,
  order,
  supplierOrCustomerName,
  isSupplierView = false,
}: OrderDetailModalProps): React.ReactElement {
  const toast = useToast();
  const {
    onOpen,
    onClose: onCloseModal,
    isOpen: isOpenModal,
  } = useDisclosure();

  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const cancelRef = useRef();

  const { data: orderInvoice, isLoading } = useGetInvoiceByOrderIdQuery(
    order ? order?.id?.toString() || "0" : "0",
    {
      skip: order.status === "PENDING",
      refetchOnMountOrArgChange: true,
    },
  );

  const [putOrderItems, { isLoading: isOrderUpdating }] =
    usePutOrderItemsMutation();

  const [orderItems, setOrderItems] = useState(order.order_items);
  const [orderTotal, setOrderTotal] = useState<number>(0);
  const [orderInfoChanged, setOrderInfoChanged] = useState<boolean>(false);

  useEffect(() => {
    setOrderItems(order.order_items);
  }, [order, isOpen, isOpenModal]);

  const getButtonTitle = () => {
    if (order.status === "PENDING") {
      return isSupplierView ? "Process Order" : "Accept Delivery";
    }
    return "Show Invoice";
  };

  const handlePutOrderItems = () => {
    if (!order?.id) {
      toast({
        title: "Order ID not found!",
        description: "Please try again or contact support if issue persists",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    putOrderItems({
      orderId: order.id,
      orderItems: orderItems,
    })
      .unwrap()
      .then(() => {
        toast({
          title: "Order updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Error updating order.",
          description: "Please try again or contact support if issue persists",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    setOrderInfoChanged(false);
  };

  const handleUpdateOrderItems = () => {
    // if no order items or order item quantities are 0, alert user that order will be deleted
    if (
      !orderItems ||
      orderItems.length === 0 ||
      orderItems.every((item) => item.quantity === 0)
    ) {
      onOpenAlert();
      return;
    }
    handlePutOrderItems();
  };

  return (
    <Modal
      size={"4xl"}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      {!isLoading && isOpen && isOpenModal && (
        <InvoiceModal
          invoice={orderInvoice || undefined}
          order={orderInvoice ? undefined : order}
          isOpen={isOpenModal}
          onClose={() => {
            onCloseModal();
            onClose();
          }}
          supplierOrCustomerName={supplierOrCustomerName}
          isSupplierView={isSupplierView}
        />
      )}

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-expect-error - valid ref
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isOpen={isAlertOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Delete Order?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            All items have been deleted or their quantities have been set to
            zero. This will delete the entire order. Are you sure you want to
            proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
            {/*@ts-expect-error - valid ref*/}
            <Button ref={cancelRef} onClick={onCloseAlert}>
              No
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                handlePutOrderItems();
                onCloseAlert();
              }}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader>
          <Flex alignItems="center">
            <Heading size={"md"}>
              {isSupplierView ? "Sales " : "Purchase "}Order details
            </Heading>
            <Spacer />
            <Button
              variant="solid"
              colorScheme="teal"
              aria-label="Add"
              fontSize="20px"
              marginRight={"1rem"}
              onClick={onOpen}
            >
              {getButtonTitle()}
            </Button>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
            <Box>
              <Text fontWeight="bold">Order ID:</Text>
              <Text>{order.id}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">
                {isSupplierView ? "Customer" : "Supplier"}:
              </Text>
              <Flex alignItems="center">
                <Text>{supplierOrCustomerName}</Text>
                {isSupplierView && order.status === "PENDING" && (
                  <EditCustomerModal orderId={order.id} />
                )}
              </Flex>
            </Box>
            <Box>
              <Text fontWeight="bold">Order Date:</Text>
              <Text>{order.order_date.slice(0, 10)}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Delivery Date:</Text>
              <Text>{order.delivery_date.slice(0, 10)}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Ext PO Number:</Text>
              <Text>{order.buyer_po_number || "N/A"}</Text>
            </Box>
          </Grid>
          <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            maxWidth={800}
            p={6}
            m="10px auto"
          >
            <OrderSummary
              isSalesOrder={isSupplierView}
              orderItems={orderItems}
              productUnitPriceMap={new Map()}
              preventItemEdits={["IN_TRANSIT", "DELIVERED"].includes(
                order.status || "",
              )}
              preventAddRow={
                !isSupplierView ||
                ["IN_TRANSIT", "DELIVERED"].includes(order.status || "")
              }
              setOrderItems={setOrderItems}
              setOrderTotal={setOrderTotal}
              onChange={() => setOrderInfoChanged(true)}
            />
          </Box>
          <Stack direction="row" style={{ marginTop: "auto" }}>
            <span>
              <strong>Order Total:</strong>
            </span>
            <Spacer />
            <span>
              <strong>${orderTotal.toFixed(2)}</strong>
            </span>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="teal"
            isDisabled={
              ["IN_TRANSIT", "DELIVERED"].includes(order.status || "") ||
              !orderInfoChanged
            }
            onClick={() => handleUpdateOrderItems()}
            isLoading={isOrderUpdating}
          >
            Update Order
          </Button>
          <Spacer />
          <Button colorScheme="red" variant={"ghost"} mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default OrderDetailModal;
