import { Address, Invoice } from "../../types";
import { Badge, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import AvatarEntry from "../../components/AvatarEntry/AvatarEntry";
import * as React from "react";
import InvoiceModal from "./InvoiceModal";
import {
  getInvoiceStatusColor,
  getInvoiceStatusLabel,
} from "../../utils/invoiceUtils";
import { Location } from "../../types";

interface InvoiceViewEntryProps {
  itemIndex: number;
  invoice: Invoice;
  supplierOrCustomerName: string;
  isSupplierView?: boolean;
  buyer?: Location;
  supplierAddress?: Address;
}

export default function InvoiceViewEntry({
  itemIndex,
  invoice,
  supplierOrCustomerName,
  isSupplierView = false,
}: InvoiceViewEntryProps): React.ReactElement {
  const [bgColor, setBgColor] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr
      key={itemIndex}
      style={{ cursor: "pointer" }}
      onClick={() => {
        onOpen();
        setBgColor("");
      }}
      bg={bgColor}
      onMouseEnter={() => setBgColor("lightgray")}
      onMouseLeave={() => setBgColor("")}
    >
      {isOpen && (
        <InvoiceModal
          invoice={invoice}
          isOpen={isOpen}
          onClose={onClose}
          supplierOrCustomerName={supplierOrCustomerName}
          isSupplierView={isSupplierView}
        />
      )}
      <Td fontSize="sm">{invoice.id}</Td>
      <Td fontSize="sm">{invoice.order_id}</Td>
      <Td fontSize="sm">
        <AvatarEntry name={supplierOrCustomerName} />
      </Td>
      <Td fontSize="sm">{invoice.invoice_date.slice(0, 10)}</Td>
      <Td fontSize="sm">{invoice.delivery_date.slice(0, 10)}</Td>
      <Td fontSize="sm">
        <Badge
          colorScheme={getInvoiceStatusColor(invoice.status, isSupplierView)}
        >
          {getInvoiceStatusLabel(invoice.status, isSupplierView)}
        </Badge>
      </Td>
      <Td>${Number(invoice.total).toFixed(2)}</Td>
      <Td>${Number(invoice.credit).toFixed(2)}</Td>
      <Td>${Number(invoice.total_due).toFixed(2)}</Td>
      <Td>${Number(invoice.amount_paid).toFixed(2)}</Td>
      <Td>${Number(invoice.balance).toFixed(2)}</Td>
    </Tr>
  );
}
