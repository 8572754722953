import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { usePostProductMutation } from "../../redux/apiSlice";
import { Product } from "../../types";
import * as Sentry from "@sentry/react";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";

interface AddProductModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddProductModal = ({
  isOpen,
  onClose,
}: AddProductModalProps): React.ReactElement => {
  const toast = useToast();
  const [productName, setProductName] = useState<string>("");
  const [variety, setVariety] = useState<string>("");

  const [postProduct, { isLoading }] = usePostProductMutation();

  const { getCurrentOrganisationId } = userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const handleAddProduct = () => {
    if (Number(organisationId) === 4) {
      toast({
        title: "Function disabled in demo.",
        description: "You do not have permission to create products.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!isLoading) {
      if (!productName || productName.length === 0) {
        toast({
          title: "Product name is required.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        postProduct({
          name:
            variety && variety.length > 0
              ? `${productName} - ${variety}`
              : productName,
          category: "PRODUCE",
        } as Product)
          .unwrap()
          .then(() => {
            toast({
              title: "Product created with default base units.",
              description: "You can view this in the Products list.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          })
          .catch((error) => {
            try {
              Sentry.captureException(JSON.stringify(error));
            } catch {
              Sentry.captureException(error);
            }
            toast({
              title: "Product failed to create.",
              description:
                "Please try again later & contact support if issue persists.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add product</ModalHeader>
        <ModalBody>
          <Flex flexDirection={["column", "row"]} gap={4}>
            <FormControl>
              <FormLabel>Product</FormLabel>
              <Input
                placeholder={"Product name..."}
                onChange={(e) => setProductName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Variety (optional)</FormLabel>
              <Input
                placeholder={"Variety name..."}
                onChange={(e) => setVariety(e.target.value)}
              />
            </FormControl>
          </Flex>
          {/*<Divider />*/}
          {/*<FormLabel>Units</FormLabel>*/}
          {/*<HStack marginTop={"2rem"}>*/}
          {/*  <FormControl>*/}
          {/*    <FormLabel>Unit label</FormLabel>*/}
          {/*    <Input*/}
          {/*      placeholder={"Unit label..."}*/}
          {/*      onChange={(e) => setUnitLabel(e.target.value)}*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*  <FormControl>*/}
          {/*    <FormLabel>Unit quantity</FormLabel>*/}
          {/*    <NumberInput*/}
          {/*      defaultValue={1}*/}
          {/*      min={0.00001}*/}
          {/*      onChange={(e) => setUnitQuantity(Number(e))}*/}
          {/*    >*/}
          {/*      <NumberInputField placeholder="Quantity" />*/}
          {/*      <NumberInputStepper>*/}
          {/*        <NumberIncrementStepper />*/}
          {/*        <NumberDecrementStepper />*/}
          {/*      </NumberInputStepper>*/}
          {/*    </NumberInput>*/}
          {/*  </FormControl>*/}
          {/*  <FormControl>*/}
          {/*    <FormLabel>Unit measure</FormLabel>*/}
          {/*    <Select onChange={(e) => handleMeasureChange(e.target.value)}>*/}
          {/*      <option value="oz">oz</option>*/}
          {/*      <option value="lb">lb</option>*/}
          {/*      <option value="kg">kg</option>*/}
          {/*      <option value="g">g</option>*/}
          {/*    </Select>*/}
          {/*  </FormControl>*/}
          {/*</HStack>*/}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={() => handleAddProduct()}
            isLoading={isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProductModal;
