import React, { useEffect } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import { useAppSelector } from "../redux/hooks";
import HarvestsView from "../feature/HarvestsView/HarvestsView";
import { useNavigate } from "react-router-dom";
import { userSlice } from "../redux/userSlice";
import {
  useExportHarvestRecordsQuery,
  useGetLocationByIdQuery,
} from "../redux/apiSlice";
import DataExportModal from "../components/DataExportModal/DataExportModal";
import { Icon, IconButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import { RiFileDownloadLine } from "react-icons/ri";

function HarvestsPage(): React.ReactElement {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);

  const navigate = useNavigate();

  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: currentLocation } = useGetLocationByIdQuery(locationId);

  const {
    isOpen: isExportModalOpen,
    onOpen: openExportModal,
    onClose: closeExportModal,
  } = useDisclosure();

  useEffect(() => {
    if (currentLocation && !currentLocation.is_producer) {
      navigate("/products");
    }
  }, [currentLocation]);

  return (
    <>
      <PageOverview
        heading={"Harvest Records"}
        subheading={startDate && endDate && `${startDate} - ${endDate}`}
        showDateRangePicker={true}
        endElement={
          <Tooltip
            label="Export Harvest Records"
            aria-label="export harvest records"
          >
            <IconButton
              w={"30px"}
              aria-label={"export harvest records"}
              icon={<Icon w={6} h={6} as={RiFileDownloadLine} />}
              onClick={openExportModal}
            >
              Export
            </IconButton>
          </Tooltip>
        }
      />
      {organisationId && locationId && (
        <DataExportModal
          organisationId={organisationId}
          locationId={locationId}
          isOpen={isExportModalOpen}
          onClose={closeExportModal}
          label={"Harvest Records"}
          apiQueryFn={useExportHarvestRecordsQuery}
        />
      )}

      <HarvestsView />
    </>
  );
}

export default HarvestsPage;
