import * as React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Box } from "@chakra-ui/react";
import DataTable from "../../components/DataTable/DataTable";
import {
  useGetInventoryItemsQuery,
  useGetProductsQuery,
  useGetProductUnitsQuery,
} from "../../redux/apiSlice";
import { useEffect } from "react";
import _ from "lodash";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { InventoryItem, Product, ProductUnit } from "../../types.ts";

function getRandomIntInclusive(min: number, max: number) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
}

type StockQuantities = {
  opening: number;
  sold: number;
  delivered: number;
  wasted: number;
  remaining: number;
  required: number;
};

type StockInfo = StockQuantities & {
  product: string;
  unit: string;
  category: string;
  variance: number;
};

const DataMap = new Map<number, StockQuantities>([
  [
    54,
    {
      opening: 5,
      sold: 42,
      delivered: 144,
      wasted: 3,
      remaining: 20,
      required: 110,
    },
  ],
]);

const columnHelper = createColumnHelper<StockInfo>();

const columns = [
  columnHelper.accessor("product", {
    cell: (info) => info.getValue(),
    header: "Product",
  }),
  columnHelper.accessor("unit", {
    cell: (info) => info.getValue(),
    header: "Unit",
  }),
  columnHelper.accessor("category", {
    cell: (info) => info.getValue(),
    header: "Category",
  }),
  columnHelper.accessor("opening", {
    cell: (info) => info.getValue(),
    header: "Opening",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("sold", {
    cell: (info) => info.getValue(),
    header: "Sold",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("delivered", {
    cell: (info) => info.getValue(),
    header: "Delivered",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("wasted", {
    cell: (info) => info.getValue(),
    header: "Wasted",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("remaining", {
    cell: (info) => info.getValue(),
    header: "Remaining",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("variance", {
    cell: (info) => (info.getValue() === 0 ? "--" : `${info.getValue()}%`),
    header: "Variance (+/-)",
  }),
  columnHelper.accessor("required", {
    cell: (info) => info.getValue(),
    header: "Required",
    meta: {
      isNumeric: true,
    },
  }),
];

const getCategoryDisplayName = (category: string | undefined) => {
  // Converts from CANNED_GOODS to Canned Goods
  if (!category) return "";
  return category
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

function StockLevels() {
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const { data: productUnits = [] } = useGetProductUnitsQuery("");

  const { data: products = [] } = useGetProductsQuery("");

  const { data: inventoryItems = [], isLoading: isLoadingInventoryItems } =
    useGetInventoryItemsQuery(
      { organisationId, locationId },
      { skip: !organisationId || !locationId },
    );
  const [data, setData] = React.useState<StockInfo[]>([]);

  useEffect(() => {
    if (products && products.length > 0 && !isLoadingInventoryItems) {
      const productCategoryMap = new Map<number, string>(
        // @ts-expect-error - to be investigated # TODO: address
        products.map((product: Product) => [
          Number(product.id),
          product.category,
        ]),
      );
      if (productUnits && productUnits.length > 0) {
        let unitList = productUnits;
        if (inventoryItems && inventoryItems.length > 0) {
          const inventoryUnits = new Set(
            inventoryItems.map((item: InventoryItem) => item.product_unit_id),
          );
          unitList = unitList.filter(
            (e) => inventoryUnits.has(e.id) && e.unit_measure === "kg",
            // (locationId === "8" ? e.unit_label === "unit" : true),
          );
        }
        const shuffled = _.shuffle(unitList);
        setData(
          shuffled.map((productUnit: ProductUnit) => {
            const stockQuantities = DataMap.get(productUnit.id) || {
              opening: getRandomIntInclusive(0, 55),
              sold: getRandomIntInclusive(0, 55),
              delivered: getRandomIntInclusive(0, 15),
              wasted: getRandomIntInclusive(0, 5),
              remaining: 0,
              required: getRandomIntInclusive(0, 40),
            };
            stockQuantities.remaining =
              stockQuantities.opening +
              stockQuantities.delivered -
              (stockQuantities.sold + stockQuantities.wasted);
            if (stockQuantities.remaining < 0) {
              stockQuantities.opening =
                stockQuantities.opening + -stockQuantities.remaining;
              stockQuantities.remaining = 0;
            }
            return {
              product: productUnit.product_name,
              unit: `${productUnit.unit_label} (${productUnit.unit_quantity}${productUnit.unit_measure})`,
              ...stockQuantities,
              category: getCategoryDisplayName(
                productCategoryMap.get(Number(productUnit.product_id)),
              ),
              variance: getRandomIntInclusive(0, 10),
            } as StockInfo;
          }),
        );
      }
    }
  }, [productUnits, products, inventoryItems]);

  return (
    <Box
      bg={"gray.200"}
      my={10}
      mx={[0, 0, 10]}
      borderRadius={10}
      overflowX={"auto"}
    >
      <DataTable data={data} columns={columns} />
    </Box>
  );
}

export default StockLevels;
