import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    padding: 2,
    borderColor: "teal.300",
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
export const theme = extendTheme({
  components: { Checkbox: checkboxTheme },
});
