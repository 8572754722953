import { Harvest } from "../../types";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import HarvestSummary from "./HarvestSummary";
import { useDeleteHarvestRecordMutation } from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";
import React from "react";

interface HarvestDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  harvest?: Harvest;
}

function HarvestDetailModal({
  isOpen,
  onClose,
  harvest,
}: HarvestDetailModalProps): React.ReactElement {
  const {
    onOpen,
    onClose: onCloseModal,
    isOpen: isOpenModal,
  } = useDisclosure();
  const toast = useToast();

  const [deleteHarvest, { isLoading }] = useDeleteHarvestRecordMutation();

  const handleDelete = async () => {
    if (harvest) {
      deleteHarvest(harvest)
        .unwrap()
        .then(() => {
          toast({
            title: "Harvest record deleted.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setTimeout(() => {
            onCloseModal();
            onClose();
          }, 400);
        })
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          toast({
            title: "Delete failed.",
            description:
              "Please check your inputs and try again. If the issue persists please contact support.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Modal
      size={"4xl"}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader m={4}>Warning: Deleting Harvest Record</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this harvest record? Warning: this
            cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"red"}
              isDisabled={isLoading}
              onClick={handleDelete}
            >
              {isLoading ? <Spinner /> : "Delete"}
            </Button>
            <Spacer />
            <Button colorScheme={"teal"} onClick={onCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader m={4}>
          <Stack direction={"row"}>
            <Heading size={"md"}>Harvest Details</Heading>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody m={4}>
          <Stack direction={"row"} m={4}>
            <Spacer />
            <Text size={"sm"}>
              <b>Harvest ID:</b> {harvest?.id}
            </Text>
            <Spacer />
            <Text size={"sm"}>
              <b>Harvest Date:</b> {harvest?.harvest_date.slice(0, 10)}
            </Text>
            <Spacer />
          </Stack>
          <Box
            borderWidth="1px"
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            maxWidth={800}
            p={6}
            m="10px auto"
          >
            {harvest && (
              <HarvestSummary
                items={harvest?.harvest_items || []}
                preventItemEdits={false}
                setItems={() => {}}
                onClose={onClose}
              />
            )}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="teal" mr={3} isDisabled>
            Save
          </Button>
          <Spacer />
          <Button colorScheme="red" mr={3} onClick={onOpen}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default HarvestDetailModal;
