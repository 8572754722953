import { Avatar, Box } from "@chakra-ui/react";
import * as React from "react";

interface AvatarProps {
  name: string;
  isClickable?: boolean;
  handleClick?: (value: string) => void;
  isSelected?: boolean;
}
const AvatarEntry = ({
  name,
  isClickable = true,
  handleClick,
  isSelected,
}: AvatarProps) => {
  const [hovering, setHovering] = React.useState(false);
  const handleOnHoverStart = () => {
    if (!isClickable) return;
    setHovering(true);
  };
  const handleOnHoverEnd = () => {
    if (!isClickable) return;
    setHovering(false);
  };

  const getShortName = () => {
    // Remove non-alphabetical characters, split on spaces, and return first two words
    const cleanedName = name.replace(/[^a-zA-Z\s]/g, " ").replace("  ", " ");
    const splitNames = cleanedName.split(" ");
    return splitNames.length > 1
      ? `${splitNames[0]} ${splitNames[1]}`
      : splitNames[0];
  };

  return (
    <Box
      bg={hovering ? "gray.300" : ""}
      display="flex"
      alignItems="center"
      onClick={() => handleClick && handleClick(name)}
      style={isClickable && hovering ? { cursor: "pointer" } : {}}
      onMouseEnter={handleOnHoverStart}
      onMouseLeave={handleOnHoverEnd}
    >
      <Avatar
        marginRight={4}
        bg={isSelected ? "green.500" : "teal.500"}
        textColor="white"
        name={isSelected ? "✓" : getShortName()}
      />
      {name}
    </Box>
  );
};

export default AvatarEntry;
