import React from "react";
import { HeroHeader } from "./HeroHeader";
import { FeatureScroll } from "./FeatureScroll";
import { Benefits } from "./Benefits";
import { FeatureSection } from "./FeatureSection";

function HomePage(): React.ReactElement {
  const countingCouple = new URL(
    "../../assets/images/counting-couple.jpg",
    import.meta.url,
  ).href;
  return (
    <>
      <HeroHeader />
      <FeatureSection image={{ src: countingCouple, alt: "team counting" }} />
      <FeatureScroll />
      <Benefits />
    </>
  );
}

export default HomePage;
