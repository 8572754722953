import { Button } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

type ImageProps = {
  src: string;
  alt?: string;
};

type StatsProps = {
  title: string;
  description: string;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  stats: StatsProps[];
  image: ImageProps;
};

export type Layout27Props = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

const manCarryingOranges = new URL(
  "../../assets/images/man-carrying-oranges.jpg",
  import.meta.url,
).href;

export const Benefits = (props: Layout27Props) => {
  const { tagline, heading, description, image, stats } = {
    ...Layout27Defaults,
    ...props,
  } as Props;

  const navigate = useNavigate();

  return (
    <section className="feature-section px-[5%] py-16 md:py-24 lg:py-48 lg:h-screen">
      <div className="text-stone-100 container">
        <div className="grid grid-cols-1 gap-y-12 md:grid-flow-row md:grid-cols-2 md:items-center md:gap-x-12 lg:gap-x-20">
          <div>
            <p className="mb-3 font-semibold md:mb-4">{tagline}</p>
            <h2 className="mb-5 text-4xl font-bold leading-[1.2] md:mb-6 md:text-5xl lg:text-6xl">
              {heading}
            </h2>
            <p className="mb-6 md:mb-8 md:text-md">{description}</p>
            <div className="grid grid-cols-1 gap-6 py-2 sm:grid-cols-2">
              {stats.map((stat, index) => (
                <div key={index}>
                  <h3 className="mb-2 text-5xl font-bold md:text-7xl lg:text-8xl">
                    {stat.title}
                  </h3>
                  <p>{stat.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <img
              src={image.src}
              className="w-full object-cover"
              alt={image.alt}
            />
          </div>
        </div>
        <div className="mt-6 flex items-center gap-x-4 md:mt-8">
          <Button
            colorScheme="teal"
            variant="outline"
            onClick={() => navigate("/request-demo")}
          >
            Request demo
          </Button>
        </div>
      </div>
    </section>
  );
};

// TODO: review stats
export const Layout27Defaults: Layout27Props = {
  tagline: "Efficiency",
  heading: "Transforming the Food & Beverage Supply Chain with Stocky AI",
  description:
    "Discover how Stocky AI's inventory management system is revolutionizing the food and beverage supply chain, reducing waste and improving order accuracy.",
  stats: [
    {
      // title: "31%",
      title: "⬇️",
      description: "Reduction in waste across the supply chain.",
    },
    {
      // title: "67%",
      title: "⬆️",
      description: "Improvement in order accuracy and delivery efficiency.",
    },
  ],
  image: {
    src: manCarryingOranges,
    alt: "Man carrying orange box",
  },
};
