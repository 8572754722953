import { Tr } from "@chakra-ui/react";
import { useState } from "react";
import * as React from "react";

interface InteractiveRowProps {
  itemIndex: number | string;
  children?: React.ReactNode[];
  onClick?: () => void;
}
export default function InteractiveRow({
  itemIndex,
  children,
  onClick,
}: InteractiveRowProps) {
  const [bgColor, setBgColor] = useState("");

  return (
    <Tr
      key={itemIndex}
      style={{ cursor: "pointer" }}
      onClick={() => {
        onClick && onClick();
        setBgColor("");
      }}
      bg={bgColor}
      onMouseEnter={() => setBgColor("lightgray")}
      onMouseLeave={() => setBgColor("")}
    >
      {/*{showSelect && (*/}
      {/*  <Td fontSize="sm">*/}
      {/*    <Checkbox*/}
      {/*      isChecked={isChecked}*/}
      {/*      colorScheme="teal"*/}
      {/*      onChange={(e) => {*/}
      {/*        e.stopPropagation();*/}
      {/*        if (setChecked) setChecked(!isChecked);*/}
      {/*        // onClose();*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Td>*/}
      {/*)}*/}
      {children}
    </Tr>
  );
}
