import {
  Badge,
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import * as React from "react";
import { Organisation } from "../../types";
import { useGetOrganisationsQuery } from "../../redux/apiSlice";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "../Autocomplete";

interface OrganisationSearchOrCreateProps {
  setOrganisationId?: (id: number | null | undefined) => void;
  setOrganisationName?: (name: string) => void;
  includeSearchIcon?: boolean;
}

const OrganisationSearchOrCreate = ({
  setOrganisationId = () => {},
  setOrganisationName = () => {},
  includeSearchIcon = false,
}: OrganisationSearchOrCreateProps): React.ReactElement => {
  const { data: organisations } = useGetOrganisationsQuery("");

  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const [newOrganisationName, setNewOrganisationName] = React.useState<
    string | undefined
  >(undefined);

  const clearSearch = () => {
    setOrganisationId(undefined);
    setOrganisationName("");
    setSearchTerm("");
  };

  return (
    <AutoComplete openOnFocus>
      <HStack>
        <InputGroup>
          {includeSearchIcon && (
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              <SearchIcon />
            </InputLeftElement>
          )}
          <AutoCompleteInput
            onItemSelect={(value) => {
              setSearchTerm(value);
              setOrganisationName(value);
              const organisation = organisations?.find(
                (org) => org.name === value,
              );
              setOrganisationId(organisation?.id);
              setNewOrganisationName(undefined);
            }}
            placeholder={includeSearchIcon ? "Search for a customer" : ""}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length === 0) {
                clearSearch();
              }
              setNewOrganisationName(undefined);
            }}
          />
          {newOrganisationName && (
            <InputRightElement marginRight={4}>
              <Badge colorScheme="green">New!</Badge>
            </InputRightElement>
          )}
        </InputGroup>
        <AutoCompleteList
          enableAddItem
          addItemLabel={"organization"}
          onAddItem={(value: string) => setNewOrganisationName(value)}
        >
          {organisations?.map((organisation: Organisation) => (
            <AutoCompleteItem
              id={`option-${organisation.id}`}
              key={`option-${organisation.id}`}
              value={organisation.name}
              textTransform="capitalize"
              onClick={() => {
                setSearchTerm(organisation.name);
                setOrganisationId(organisation.id);
                setOrganisationName(organisation.name);
                setNewOrganisationName(undefined);
              }}
            >
              {organisation.name}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </HStack>
    </AutoComplete>
  );
};

export default OrganisationSearchOrCreate;
