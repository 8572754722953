import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  useToast,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  CardHeader,
  Heading,
  Stack,
  Text,
  Spacer,
  CardBody,
  StackDivider,
  Card,
} from "@chakra-ui/react";
import {
  useGetBuyerLocationsQuery,
  useGetSupplierInvoicesQuery,
  usePostPaymentMutation,
} from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { PaymentInvoiceJunction, PaymentType } from "../../types";
import * as Sentry from "@sentry/react";
import { formatCurrency } from "../../utils/invoiceUtils";
import { paymentTypeLabels } from "../../utils/paymentUtils";
import CustomerSearch from "../../components/CustomerSearch/CustomerSearch";
import InvoicePaymentEntry from "./InvoicePaymentEntry";

interface AddPaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddPaymentModal = ({
  isOpen,
  onClose,
}: AddPaymentModalProps): React.ReactElement => {
  const toast = useToast();
  const [selectedBuyerId, setSelectedBuyerId] = useState<number>();
  const [referenceNumber, setReferenceNumber] = useState<string>("");
  const [paymentDate, setPaymentDate] = useState<string>("");
  const [amount, setAmount] = useState<string>("");

  const [amountAssigned, setAmountAssigned] = useState<number>(0);
  const [amountRemaining, setAmountRemaining] = useState<number>(0);

  const [invoiceIdToPaymentInvoice, setInvoiceIdToPaymentInvoice] = useState<
    Map<number, PaymentInvoiceJunction>
  >(new Map());
  const [currentPaymentInvoices, setCurrentPaymentInvoices] = useState<
    PaymentInvoiceJunction[]
  >([]);

  const [paymentType, setPaymentType] = useState<PaymentType>(
    PaymentType.CHEQUE,
  );
  const [lodgedDate, setLodgedDate] = useState<string>("");
  const [bouncedDate, setBouncedDate] = useState<string>("");
  const [hasCheckBounced, setHasCheckBounced] = useState<boolean>(false);

  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: buyerLocations } = useGetBuyerLocationsQuery({
    organisationId,
    locationId,
  });

  const { data: invoices } = useGetSupplierInvoicesQuery(
    {
      organisationId: organisationId,
      locationId: locationId,
      buyerLocationId: selectedBuyerId,
    },
    {
      skip: !organisationId || !locationId || !selectedBuyerId,
    },
  );

  const [postPayment, { isLoading: isSaving }] = usePostPaymentMutation();

  useEffect(() => {
    let remaining = Number(amount);
    if (currentPaymentInvoices) {
      const invoiceIdToPaymentInvoiceMap = new Map<
        number,
        PaymentInvoiceJunction
      >();
      currentPaymentInvoices.forEach((paymentInvoice) => {
        invoiceIdToPaymentInvoiceMap.set(
          paymentInvoice.invoice_id,
          paymentInvoice,
        );
      });
      setInvoiceIdToPaymentInvoice(invoiceIdToPaymentInvoiceMap);

      const assigned = currentPaymentInvoices.reduce((acc, invoicePayment) => {
        return acc + Number(invoicePayment.amount_paid);
      }, 0);
      setAmountAssigned(assigned);
      remaining -= assigned;
    }
    setAmountRemaining(remaining);
  }, [amount, currentPaymentInvoices]);

  const addPaymentInvoiceToCurrent = (
    invoiceId: number,
    amountAssigned: number,
  ) => {
    const newPaymentInvoice: PaymentInvoiceJunction = {
      payment_id: undefined, // payment_id is not required when creating a new payment invoice, BE handles
      invoice_id: invoiceId,
      amount_paid: amountAssigned,
    };
    setCurrentPaymentInvoices([...currentPaymentInvoices, newPaymentInvoice]);
  };

  const updatePaymentInvoiceAmount = (
    invoiceId: number,
    amountPaid: number,
  ) => {
    const updatedPaymentInvoices = currentPaymentInvoices.map(
      (paymentInvoice) => {
        if (paymentInvoice.invoice_id === invoiceId) {
          return { ...paymentInvoice, amount_paid: amountPaid };
        }
        return paymentInvoice;
      },
    );
    setCurrentPaymentInvoices(updatedPaymentInvoices);
  };

  const handleAddOrUpdateInvoicePayment = (
    invoiceId: number,
    amountPaid: number,
  ) => {
    if (!invoiceIdToPaymentInvoice.get(invoiceId))
      addPaymentInvoiceToCurrent(invoiceId, amountPaid);
    else updatePaymentInvoiceAmount(invoiceId, amountPaid);
  };

  const handleRecordPayment = () => {
    if (!selectedBuyerId || !paymentDate || !amount || !paymentType) {
      toast({
        title: "All fields are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const customerLocation = buyerLocations?.find(
      (buyer) => buyer.id === selectedBuyerId,
    );
    if (!customerLocation) {
      toast({
        title: "Issue loading customer details",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isSaving) {
      postPayment({
        organisation_id: Number(organisationId),
        location_id: Number(locationId),
        customer_location_id: customerLocation.id,
        customer_organisation_id: customerLocation.organisation_id,
        reference_number: referenceNumber,
        payment_date: paymentDate,
        amount: parseFloat(amount),
        payment_type: paymentType,
        lodged_date: lodgedDate && lodgedDate.length > 0 ? lodgedDate : null,
        bounced_date:
          bouncedDate && bouncedDate.length > 0 ? bouncedDate : null,
        payment_invoices: currentPaymentInvoices,
      })
        .unwrap()
        .then(() => {
          toast({
            title: "Payment recorded.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onClose();
        })
        .catch((error) => {
          try {
            Sentry.captureException(JSON.stringify(error));
          } catch {
            Sentry.captureException(error);
          }
          toast({
            title: "Failed to record payment.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Record Payment</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Account</FormLabel>
            <CustomerSearch
              setCustomerLocationId={(value) =>
                setSelectedBuyerId(Number(value))
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Payment Type</FormLabel>
            <Select
              onChange={(e) => setPaymentType(e.target.value as PaymentType)}
            >
              {Object.values(PaymentType).map((type) => (
                <option key={type} value={type}>
                  {paymentTypeLabels[type]}
                </option>
              ))}
            </Select>
          </FormControl>
          {paymentType === PaymentType.CHEQUE && (
            <FormControl mt={4}>
              <FormLabel>Reference Number</FormLabel>
              <Input
                placeholder="Reference Number"
                onChange={(e) => setReferenceNumber(e.target.value)}
              />
            </FormControl>
          )}
          <FormControl mt={4}>
            <FormLabel>Payment Date</FormLabel>
            <Input
              type="date"
              onChange={(e) => setPaymentDate(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Amount</FormLabel>
            <InputGroup>
              <InputLeftElement>$</InputLeftElement>
              <NumberInput min={0.01} precision={2}>
                <NumberInputField
                  marginLeft={"8px"}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </NumberInput>
            </InputGroup>
          </FormControl>
          {paymentType === PaymentType.CHEQUE && (
            <>
              <FormControl mt={4}>
                <FormLabel>Lodged Date</FormLabel>
                <Input
                  type="date"
                  onChange={(e) => setLodgedDate(e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
                <Checkbox
                  isChecked={hasCheckBounced}
                  onChange={(e) => setHasCheckBounced(e.target.checked)}
                >
                  Has check bounced?
                </Checkbox>
              </FormControl>
              {hasCheckBounced && (
                <FormControl mt={4}>
                  <FormLabel>Bounced Date</FormLabel>
                  <Input
                    type="date"
                    onChange={(e) => setBouncedDate(e.target.value)}
                  />
                </FormControl>
              )}
            </>
          )}
          <Card mt={8}>
            <Accordion allowToggle>
              <AccordionItem>
                <CardHeader>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Heading size="md">Invoices</Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <Stack direction={"row"} m={4}>
                    <Text>
                      <b>Amount Assigned:</b> ${formatCurrency(amountAssigned)}
                    </Text>
                    <Spacer />
                    <Text>
                      <b>Amount Remaining:</b> $
                      {formatCurrency(amountRemaining)}
                    </Text>
                  </Stack>
                </CardHeader>

                <AccordionPanel pb={4}>
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing="4">
                      {invoices?.map((invoice, index) => (
                        <Box key={index}>
                          <InvoicePaymentEntry
                            paymentInvoice={invoiceIdToPaymentInvoice.get(
                              invoice.id,
                            )}
                            invoice={invoice}
                            paymentAmountRemaining={amountRemaining}
                            updatePaymentInvoiceAmount={
                              handleAddOrUpdateInvoicePayment
                            }
                            originalAmountAssigned={"0.00"}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </CardBody>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleRecordPayment}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPaymentModal;
