import * as React from "react";
import {
  Button,
  HStack,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useGetCurrentUserQuery,
  useGetLocationsQuery,
  useGetOrganisationsQuery,
} from "../../redux/apiSlice";
import { Location } from "../../types";
import { useEffect, useState } from "react";
import {
  setChangingLocation,
  setLocation,
  setOrganisation,
  userSlice,
} from "../../redux/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

function LocationPicker() {
  const dispatch = useAppDispatch();

  const { data: currentUser } = useGetCurrentUserQuery("");

  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const currentLocationId = useAppSelector(getCurrentLocationId);
  const currentOrganisationId = useAppSelector(getCurrentOrganisationId);

  const [selectedOrganisation, setSelectedOrganisation] = useState<number>(
    Number(currentOrganisationId),
  );
  const [selectedLocation, setSelectedLocation] = useState<number>(
    Number(currentLocationId),
  );

  // TODO: restrict organisations and locations to the user's permissions
  const { data: organisations = [] } = useGetOrganisationsQuery("");

  const { data: locations } = useGetLocationsQuery("");

  const { onOpen, onClose, isOpen } = useDisclosure();

  const [orgLocationMap, setOrgLocationMap] = React.useState(
    new Map<number, Location[]>(),
  );

  useEffect(() => {
    if (locations && currentUser && !orgLocationMap.size) {
      const isAdministrator = currentUser.organisation_id === 1; // TODO: implement field for admin, permissions
      const orgLocationMap = new Map<number, Location[]>();
      locations.forEach((location) => {
        if (orgLocationMap.has(location.organisation_id)) {
          orgLocationMap.get(location.organisation_id)?.push(location);
        } else {
          if (
            isAdministrator ||
            location.organisation_id === currentUser.organisation_id
          ) {
            orgLocationMap.set(location.organisation_id, [location]);
          }
        }
      });
      setOrgLocationMap(orgLocationMap);
    }
  }, [organisations, locations, orgLocationMap, currentUser]);

  const getOrgLocations = (organisationId: number): Location[] | undefined => {
    return orgLocationMap.get(organisationId);
  };

  return (
    <MenuItem onClick={onOpen}>
      Change Location
      <Modal
        size={"5xl"}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Location</ModalHeader>
          <ModalCloseButton />
          <ModalBody minHeight={"300px"}>
            <HStack>
              {orgLocationMap && orgLocationMap.size > 1 && (
                <Select
                  placeholder="Select organisation"
                  onChange={(e) => {
                    setSelectedOrganisation(Number(e.target.value));
                  }}
                  defaultValue={currentOrganisationId}
                >
                  {organisations
                    .filter((organisation) =>
                      orgLocationMap.has(organisation.id),
                    )
                    .map((organisation) => (
                      <option key={organisation.id} value={organisation.id}>
                        {organisation.name}
                      </option>
                    ))}
                </Select>
              )}
              <Select
                placeholder="Select location"
                onChange={(e) => {
                  setSelectedLocation(Number(e.target.value));
                }}
                defaultValue={currentLocationId}
              >
                {getOrgLocations(selectedOrganisation ?? 0)?.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </Select>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              onClick={() => {
                dispatch(setChangingLocation(true));
                dispatch(
                  setLocation(
                    locations?.find(
                      (location) => location.id === selectedLocation,
                    ),
                  ),
                );
                dispatch(
                  setOrganisation(
                    organisations.find(
                      (organisation) =>
                        organisation.id === selectedOrganisation,
                    ),
                  ),
                );
                onClose();
                setTimeout(() => {
                  dispatch(setChangingLocation(false));
                }, 200);
              }}
            >
              Update
            </Button>
            <Button
              colorScheme="red"
              variant={"ghost"}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MenuItem>
  );
}

export default LocationPicker;
