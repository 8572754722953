import { Container, ContainerCostItem } from "../../types";
import { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  useToast,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { usePutContainerMutation } from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";
import ContainerCostSummary from "./ContainerCostSummary";

interface ContainerModalProps {
  container: Container;
  isOpen: boolean;
  onClose: () => void;
}

export default function ContainerModal({
  container,
  isOpen,
  onClose,
}: ContainerModalProps) {
  const toast = useToast();
  const [costItems, setCostItems] = useState<ContainerCostItem[]>(
    container.cost_items || [],
  );
  const [totalCost, setTotalCost] = useState<number>(0);
  const [expectedDate, setExpectedDate] = useState<string>(
    container.expected_arrival.slice(0, 10),
  );
  const [arrivalDate, setArrivalDate] = useState<string>(
    container.arrived_at?.slice(0, 10) || "",
  );
  const [releasedDate, setReleasedDate] = useState<string>(
    container.released_at?.slice(0, 10) || "",
  );
  const [origin, setOrigin] = useState<string>(container?.origin_country || "");

  const [putContainer, { isLoading: isContainerUpdating }] =
    usePutContainerMutation();

  const costSummaryRef = useRef<{
    getNewItemData: () => ContainerCostItem | null;
  }>(null);

  useEffect(() => {
    if (container) {
      setCostItems(container.cost_items || []);
      setTotalCost(
        container.cost_items?.reduce(
          (acc, costItem) => acc + (costItem.cost || 0),
          0,
        ) || 0,
      );
      setExpectedDate(container.expected_arrival.slice(0, 10));
      setArrivalDate(container.arrived_at?.slice(0, 10) || "");
      setReleasedDate(container.released_at?.slice(0, 10) || "");
    }
  }, [container, isOpen]);

  const handleSaveContainer = async () => {
    const updatedCostItems = [...costItems];

    // Check if there's a new item in progress and add it
    const newItem = costSummaryRef.current?.getNewItemData();
    if (newItem) {
      updatedCostItems.push(newItem);
    }

    const containerPayload: Container = {
      ...container,
      expected_arrival: expectedDate,
      arrived_at: arrivalDate && arrivalDate.length > 0 ? arrivalDate : null,
      released_at:
        releasedDate && releasedDate.length > 0 ? releasedDate : null,
      cost_items: updatedCostItems,
      origin_country: origin,
    };
    putContainer(containerPayload)
      .unwrap()
      .then(() => {
        toast({
          title: "Container updated.",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Container failed to update.",
          description: "Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal
      size={"6xl"}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader>
          <Stack direction={"row"}>
            <Heading size={"md"}>Container</Heading>
            <Spacer />
            {/*<Button*/}
            {/*  variant="solid"*/}
            {/*  colorScheme="teal"*/}
            {/*  aria-label="Add"*/}
            {/*  fontSize="20px"*/}
            {/*  marginRight={"1rem"}*/}
            {/*  // onClick={onOpen}*/}
            {/*>*/}
            {/*  Open Cost Calculator*/}
            {/*</Button>*/}
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ContainerCostSummary
            ref={costSummaryRef}
            costItems={costItems}
            container={container}
            expectedDate={expectedDate}
            arrivalDate={arrivalDate}
            releasedDate={releasedDate}
            totalCost={totalCost}
            origin={origin}
            setCostItems={setCostItems}
            setTotalCost={setTotalCost}
            setExpectedDate={setExpectedDate}
            setArrivalDate={setArrivalDate}
            setReleasedDate={setReleasedDate}
            setOrigin={setOrigin}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={handleSaveContainer}
            isLoading={isContainerUpdating}
          >
            Save
          </Button>
          <Spacer />
          <Button colorScheme="red" variant={"ghost"} mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
