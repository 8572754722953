import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

interface DataExportModalProps {
  organisationId: string | number;
  locationId: string | number;
  isOpen: boolean;
  onClose: () => void;
  label: string;
  apiQueryFn: any;
}

const DataExportModal = ({
  organisationId,
  locationId,
  isOpen,
  onClose,
  label,
  apiQueryFn,
}: DataExportModalProps): React.ReactElement => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { data, error, isLoading } = apiQueryFn(
    {
      organisationId,
      locationId,
      startDate,
      endDate,
    },
    { skip: startDate === "" || endDate === "" },
  );

  const handleExport = async () => {
    if (error) {
      try {
        Sentry.captureException(JSON.stringify(error));
      } catch {
        Sentry.captureException(error);
      }
    }
    // if loading, wait for data to be fetched. setTimeout to retry every 100ms, limit to 10s
    let retries = 0;
    while (isLoading && retries < 100) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      retries++;
    }

    if (error) {
      try {
        Sentry.captureException(JSON.stringify(error));
      } catch {
        Sentry.captureException(error);
      }
    }

    try {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${label
        .toLowerCase()
        .replace(" ", "_")}_${startDate}-${endDate}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      try {
        Sentry.captureException(JSON.stringify(error));
      } catch {
        Sentry.captureException(error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export {label}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleExport}>
            Export
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DataExportModal;
