import React from "react";

interface ChatEntryProps {
  imageSrc: string;
  name: string;
  message: string;
  messageTime?: string;
  isDelivered?: boolean;
  isResponse?: boolean;
  showOptions?: boolean;
}

const ChatEntry = ({
  imageSrc,
  name,
  message,
  messageTime = "11:46",
  isResponse,
  showOptions = false,
}: ChatEntryProps): React.ReactElement => {
  const imageUrl = new URL(imageSrc, import.meta.url).href;

  const getChatEntryStyle = () => {
    const baseStyle =
      "flex flex-col w-full max-w-[220px] lg:max-w-[320px] leading-1.5 p-4 border-gray-200 bg-gray-100 dark:bg-gray-700";
    if (isResponse) {
      return `${baseStyle} rounded-ss-xl rounded-es-xl rounded-ee-xl`;
    }
    return `${baseStyle} rounded-e-xl rounded-es-xl`;
  };

  const getImage = () => (
    <img
      className={`w-10 h-10 ${isResponse ? "" : "rounded-full"}`}
      src={imageUrl}
      alt={name}
    />
  );

  return (
    <div
      className={`flex items-start gap-2.5 md:-ml-6 md:mr-4 lg:mr-0 lg:ml-0 min-[1550px]:ml-24`}
    >
      {isResponse ? null : getImage()}
      <div className={getChatEntryStyle()}>
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <span className="text-sm font-semibold text-gray-900 dark:text-white">
            {name}
          </span>
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {messageTime}
          </span>
        </div>
        <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
          {message}
        </p>
        {isResponse && (
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400 underline">
            See more
          </span>
        )}
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {isResponse ? "" : "read"}
        </span>
      </div>
      {!isResponse ? null : getImage()}
      {showOptions && (
        <>
          <button
            id="dropdownMenuIconButton"
            data-dropdown-toggle="dropdownDots"
            data-dropdown-placement="bottom-start"
            className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600"
            type="button"
          >
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 4 15"
            >
              <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
            </svg>
          </button>
          <div
            id="dropdownDots"
            className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600"
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownMenuIconButton"
            >
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Reply
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Forward
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Copy
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Report
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Delete
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatEntry;
