import React from "react";
import StockLevels from "../feature/StockLevels/StockLevels";
import PageOverview from "../components/PageOverview/PageOverview";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import { useAppSelector } from "../redux/hooks";

function StockPage(): React.ReactElement {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  return (
    <>
      <PageOverview
        heading={"Stock levels"}
        subheading={startDate && endDate && `${startDate} - ${endDate}`}
        showDateRangePicker={true}
      />
      <StockLevels />
    </>
  );
}

export default StockPage;
