import React, { useEffect, useState } from "react";
import SalesGraph from "../feature/SalesGraph/SalesGraph";
import { Box, HStack, Skeleton } from "@chakra-ui/react";
import BasketInfo from "../feature/BasketInfo/BasketInfo";
import SalesOverview from "../feature/SalesOverview/SalesOverview";
import {
  useGetAggregatedDailyForecastsQuery,
  useGetAggregatedDailySalesQuery,
} from "../redux/apiSlice";
import { userSlice } from "../redux/userSlice";
import { useAppSelector } from "../redux/hooks";
import { filterDatesSlice } from "../redux/filterDatesSlice";

function Dashboard(): React.ReactElement {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const locationId = useAppSelector(getCurrentLocationId);
  const organisationId = useAppSelector(getCurrentOrganisationId);

  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);
  const { data: dailySales = [], isLoading: isLoadingDailySales } =
    useGetAggregatedDailySalesQuery({
      organisationId,
      locationId,
      startTime: startDate,
      endTime: endDate,
    });
  const { data: dailyForecasts = [], isLoading: isLoadingForecastsSales } =
    useGetAggregatedDailyForecastsQuery({
      organisationId,
      locationId,
      startTime: startDate,
      endTime: endDate,
    });
  const [totalSales, setTotalSales] = useState<number>(0);
  const [totalForecasts, setTotalForecasts] = useState<number>(0);

  useEffect(() => {
    if (dailySales) {
      setTotalSales(
        dailySales.reduce((acc, curr) => acc + Number(curr.total_sales), 0),
      );
    }
  }, [dailySales]);

  useEffect(() => {
    if (dailyForecasts) {
      setTotalForecasts(
        dailyForecasts.reduce((acc, curr) => acc + Number(curr.total_sales), 0),
      );
    }
  }, [dailyForecasts]);

  return (
    <>
      {isLoadingDailySales || isLoadingForecastsSales ? (
        <Skeleton height="100%" />
      ) : (
        <>
          <SalesOverview
            totalSales={totalSales}
            totalForecasted={totalForecasts}
            startDate={startDate}
            endDate={endDate}
          />
          <HStack>
            <Box w="70%" h="100%">
              <SalesGraph
                dailySales={dailySales}
                dailyForecasts={dailyForecasts}
              />
            </Box>
            <Box w="30%" h="100%">
              <BasketInfo />
            </Box>
          </HStack>
        </>
      )}
    </>
  );
}

export default Dashboard;
