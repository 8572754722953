import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import {
  useGetBuyerLocationsQuery,
  useUpdateOrderCustomerMutation,
} from "../../redux/apiSlice";
import { EditIcon } from "@chakra-ui/icons";
import { useAppSelector } from "../../redux/hooks";
import { userSlice } from "../../redux/userSlice";
import React, { useRef } from "react";
import { getLocationAndOrgName } from "../../utils/invoiceUtils";

export const EditCustomerModal = ({ orderId }: { orderId: number }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();

  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: buyerLocations = [], isLoading: isFetchingBuyerLocations } =
    useGetBuyerLocationsQuery({ organisationId, locationId });
  const [updateOrderCustomer, { isLoading: isUpdatingOrder }] =
    useUpdateOrderCustomerMutation();

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedCustomer(event.target.value);
  };

  const handleSave = (): void => {
    setIsAlertOpen(true);
  };

  const handleConfirm = async () => {
    setIsAlertOpen(false);
    const [customerOrganisationId, customerLocationId] =
      selectedCustomer.split(",");
    try {
      await updateOrderCustomer({
        orderId,
        customerOrganisationId: Number(customerOrganisationId),
        customerLocationId: Number(customerLocationId),
      }).unwrap();
      toast({
        title: "Customer updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch {
      toast({
        title: "Failed to update customer",
        description: "Please try again later",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <EditIcon
        color="teal"
        boxSize={4}
        ml={2}
        cursor={"pointer"}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Customer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isFetchingBuyerLocations ? (
              <p>Loading customers...</p>
            ) : (
              <Select
                placeholder="Select customer"
                value={selectedCustomer}
                onChange={handleCustomerChange}
              >
                {buyerLocations.map((location) => {
                  const locationName = getLocationAndOrgName(location);
                  if (locationName) {
                    return (
                      <option
                        key={location.id}
                        value={`${location.organisation_id},${location.id}`}
                      >
                        {locationName}
                      </option>
                    );
                  }
                  return null;
                })}
              </Select>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={handleSave}
              isDisabled={!selectedCustomer}
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change Customer
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to change the customer for this order?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="teal"
                onClick={handleConfirm}
                ml={3}
                isLoading={isUpdatingOrder}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
