import { HarvestItem, OrderItem } from "../../types";
import { IconButton, Input, Spacer, Stack } from "@chakra-ui/react";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import { useState } from "react";
import { CheckIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

interface OrderItemProps {
  itemIndex: number;
  orderItem: OrderItem | HarvestItem;
  unitPrice?: number;
  handleRemoveItem: (index: number) => void;
  handleQuantityChange: (index: number, quantity: number) => void;
  preventEdit?: boolean;
  enableDelete?: boolean;
  isDeleteLoading?: boolean;
}
export default function OrderItemEntry({
  itemIndex,
  orderItem,
  unitPrice,
  handleRemoveItem,
  handleQuantityChange,
  preventEdit,
  enableDelete = false,
  isDeleteLoading = false,
}: OrderItemProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editQuantity, setEditQuantity] = useState<string>(
    orderItem?.quantity.toString() || "0",
  );

  const handleConfirm = () => {
    const editQuantityNum = Number(editQuantity);
    if (editQuantityNum < 1) {
      handleRemoveItem(itemIndex);
    } else {
      setIsEditing(false);
      handleQuantityChange(itemIndex, editQuantityNum);
    }
  };

  const RemoveButton = () => (
    <IconButton
      aria-label="Remove"
      icon={<DeleteIcon />}
      colorScheme="red"
      size="xs"
      onClick={() => handleRemoveItem(itemIndex)}
      variant="outline"
      isLoading={isDeleteLoading}
    />
  );

  const itemDisplay = () => (
    <Stack direction="row">
      <span>{orderItem?.product?.name || "Error loading product..."}:</span>
      <span>
        {" "}
        {orderItem.quantity} x {getProductUnitLabel(orderItem.product_unit)}
      </span>
      <Spacer />
      {unitPrice && <span>${Number(unitPrice).toFixed(2)}</span>}
      {!preventEdit && (
        <>
          <IconButton
            aria-label="Edit"
            icon={<EditIcon />}
            colorScheme="blue"
            size="xs"
            onClick={() => setIsEditing(true)}
            variant="outline"
          />
          <RemoveButton />
        </>
      )}
      {preventEdit && enableDelete && <RemoveButton />}
    </Stack>
  );

  const editItemDisplay = () => {
    return (
      <Stack direction="row">
        <span>{orderItem?.product?.name || "Error loading product..."}:</span>
        <span>Quantity:</span>
        <Input
          rounded="md"
          size="sm"
          w="50px"
          type="number"
          value={editQuantity}
          onChange={(e) =>
            setEditQuantity(e.target.value ? e.target.value : "")
          }
        />
        <span>x {getProductUnitLabel(orderItem.product_unit)}</span>
        <Spacer />
        {unitPrice && (
          <span>${(unitPrice * orderItem.quantity).toFixed(2)}</span>
        )}
        <IconButton
          aria-label="confirm"
          icon={<CheckIcon />}
          colorScheme="teal"
          size="xs"
          onClick={handleConfirm}
          variant="outline"
        />
        <RemoveButton />
      </Stack>
    );
  };

  return <>{isEditing ? editItemDisplay() : itemDisplay()}</>;
}
