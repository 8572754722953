import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import EmailAddressForm from "../../components/EmailAddressForm/EmailAddressForm.tsx";

interface EmailDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  sendEmail: ({
    toEmails,
    ccEmails,
    bccEmails,
  }: {
    toEmails: string[];
    ccEmails: string[];
    bccEmails: string[];
  }) => void;
  isSending: boolean;
  defaultToEmails?: string[];
  defaultCcEmails?: string[];
}

export default function EmailDetailsModal({
  isOpen,
  onClose,
  sendEmail,
  isSending,
  defaultToEmails = [],
  defaultCcEmails = [],
}: EmailDetailsModalProps): React.ReactElement {
  const toast = useToast();

  const [toEmails, setToEmails] = useState<string[]>(defaultToEmails);
  const [ccEmails, setCcEmails] = useState<string[]>(defaultCcEmails);
  const [bccEmails, setBccEmails] = useState<string[]>([]);

  const handleSendEmail = () => {
    if (toEmails.length === 0) {
      toast({
        title: "Please add at least one recipient.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    // check if any emails are duplicated across to, cc, and bcc
    const allEmails = [...toEmails, ...ccEmails, ...bccEmails];
    const uniqueEmails = new Set(allEmails);
    if (uniqueEmails.size !== allEmails.length) {
      toast({
        title: "Duplicate emails found.",
        description:
          "Please ensure each email is unique, and does not appear in multiple fields.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    sendEmail({ toEmails, ccEmails, bccEmails });
  };

  return (
    <Modal
      size={"6xl"}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent p={12}>
        <ModalCloseButton />
        <ModalBody>
          <FormLabel>To</FormLabel>
          <EmailAddressForm emails={toEmails} setEmails={setToEmails} m={4} />

          <FormLabel>Cc</FormLabel>
          <EmailAddressForm emails={ccEmails} setEmails={setCcEmails} m={4} />

          <FormLabel>Bcc</FormLabel>
          <EmailAddressForm emails={bccEmails} setEmails={setBccEmails} m={4} />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            mr={3}
            onClick={handleSendEmail}
            isDisabled={toEmails.length === 0}
            isLoading={isSending}
          >
            Send
          </Button>
          <Spacer />
          <Button colorScheme="red" variant={"ghost"} mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
