import React, { useEffect, useState } from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import { useGetContainersQuery } from "../redux/apiSlice";
import { Box, useDisclosure } from "@chakra-ui/react";
import DataTabs from "../components/DataTabs/DataTabs";
import { Container } from "../types";
import Page from "../components/Page/Page";
import ContainersViewEntry from "../feature/ContainersView/ContainersViewEntry";
import AddContainerModal from "../feature/ContainersView/AddContainerModal";

function ContainersPage(): React.ReactElement {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const [sortedContainers, setSortedContainers] = useState<Container[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: containers,
    isLoading: isLoadingContainers,
    error: errorContainers,
  } = useGetContainersQuery({
    organisationId,
    locationId,
  });
  const getEffectiveDate = (container: Container) => {
    if (container.arrived_at) {
      return container.arrived_at;
    }
    return container.expected_arrival;
  };

  useEffect(() => {
    if (containers) {
      setSortedContainers(
        containers
          .slice()
          .sort((a, b) => (getEffectiveDate(a) > getEffectiveDate(b) ? 1 : -1)),
      );
    } else {
      setSortedContainers([]);
    }
  }, [containers]);

  return (
    <>
      <PageOverview heading={"Containers"} subheading={""} />
      <Page isLoading={isLoadingContainers} isError={Boolean(errorContainers)}>
        <AddContainerModal isOpen={isOpen} onClose={onClose} />
        <DataTabs
          showButton={true}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          data={[
            {
              label: "Upcoming",
              buttonLabel: "Add Container",
              headers: [
                "Number",
                "Invoices",
                "Expected Arrival",
                "Arrived On",
                "Released On",
              ],
              entries: sortedContainers
                ? sortedContainers.map((container, index) => (
                    <ContainersViewEntry
                      key={index}
                      index={index}
                      container={container}
                    />
                  ))
                : [],
            },
          ]}
        />
        {containers && containers.length === 0 && (
          <Box>No containers found - please add containers to track</Box>
        )}
      </Page>
    </>
  );
}

export default ContainersPage;
