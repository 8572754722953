import React from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import PurchaseOrdersView from "../feature/OrdersView/PurchaseOrdersView";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import { useAppSelector } from "../redux/hooks";

function PurchaseOrdersPage(): React.ReactElement {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);

  return (
    <>
      <PageOverview
        heading={"Purchases"}
        subheading={startDate && endDate && `${startDate} - ${endDate}`}
        showDateRangePicker={true}
      />
      <PurchaseOrdersView />
    </>
  );
}

export default PurchaseOrdersPage;
