import React from "react";
import PageOverview from "../components/PageOverview/PageOverview";
import { filterDatesSlice } from "../redux/filterDatesSlice";
import { useAppSelector } from "../redux/hooks";
import { userSlice } from "../redux/userSlice";
import { useGetEventSalesReconciliationQuery } from "../redux/apiSlice";
import Page from "../components/Page/Page";
import DataTabs from "../components/DataTabs/DataTabs";
import EventSalesViewEntry from "../feature/EventSalesView/EventSalesViewEntry";
import { useDisclosure } from "@chakra-ui/react";
import EventSalesRecordModal from "../feature/EventSalesView/EventSalesRecordModal.tsx";

function EventSalesPage(): React.ReactElement {
  const { getStartDate, getEndDate } = filterDatesSlice.selectors;
  const startDate = useAppSelector(getStartDate);
  const endDate = useAppSelector(getEndDate);

  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const {
    data: eventRecords,
    error: eventRecordsError,
    isLoading: eventRecordsLoading,
  } = useGetEventSalesReconciliationQuery(
    { organisationId, locationId, startTime: startDate, endTime: endDate },
    { skip: !organisationId || !locationId },
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <PageOverview
        heading={"Event Sales"}
        subheading={startDate && endDate && `${startDate} - ${endDate}`}
        showDateRangePicker={true}
      />
      {isOpen && <EventSalesRecordModal isOpen={isOpen} onClose={onClose} />}

      <Page
        isLoading={eventRecordsLoading}
        isError={Boolean(eventRecordsError)}
      >
        <>
          <DataTabs
            onOpen={onOpen}
            onClose={onClose}
            showButton={true}
            data={[
              {
                label: "Event Sales",
                buttonLabel: "Add Event Record",
                isLoading: eventRecordsLoading,
                headers: ["Date", "Event", "Reconciled At"],
                entries: eventRecords
                  ? eventRecords
                      .slice()
                      .sort(
                        (a, b) =>
                          new Date(b.event_date).getTime() -
                          new Date(a.event_date).getTime(),
                      )
                      .map((eventInfo, index) => (
                        <EventSalesViewEntry
                          index={index}
                          eventInfo={eventInfo}
                        />
                      ))
                  : [],
              },
            ]}
          />
        </>
      </Page>
    </>
  );
}

export default EventSalesPage;
