import * as React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useEffect, useState } from "react";
import { DailySales } from "../../types";

type GraphEntry = {
  date: string;
  total_sales?: string | number;
  total_forecasts?: string | number;
};

interface SalesGraphProps {
  dailySales: DailySales[];
  dailyForecasts: DailySales[];
  continuousDisplay?: boolean;
}

export default function SalesGraph({
  dailySales,
  dailyForecasts,
  continuousDisplay = true,
}: SalesGraphProps): React.ReactElement {
  const [graphData, setGraphData] = useState<GraphEntry[]>([]);

  useEffect(() => {
    let sortedDailySales: GraphEntry[] = [];
    const forecastsMap = new Map(
      dailyForecasts.map((entry) => [
        entry.date,
        Number(entry.total_sales).toFixed(2),
      ]),
    );

    if (dailySales && dailySales.length > 0) {
      const copyOfDailySales = [...dailySales];
      sortedDailySales = copyOfDailySales.sort((a, b) => {
        return a.date.localeCompare(b.date);
      });
      sortedDailySales = sortedDailySales.map((sale) => {
        const entry = {
          date: sale.date,
          total_sales: Number(sale.total_sales).toFixed(2),
          total_forecasts: forecastsMap.get(sale.date),
        } as DailySales;
        if (!continuousDisplay && entry.total_sales === "0.00") {
          // cuts off the graph if there are no sales - should update to read nulls
          delete entry.total_sales;
        }
        return entry;
      });
      setGraphData(sortedDailySales);
    }
  }, [dailySales, dailyForecasts]);

  const tickY = {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Oxygen-Sans, Helvetica Neue, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    fill: "rgba(252,252,255,0.75)",
  };

  const tickX = {
    ...tickY,
    fontWeight: 600,
  };

  return (
    <div style={{ height: "100%", width: "100%", margin: "1rem" }}>
      <ResponsiveContainer width="100%" height={480}>
        <AreaChart
          width={800}
          height={700}
          data={graphData}
          margin={{
            top: 10,
            right: 30,
            left: 30,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeWidth="1" stroke="#8884d8" vertical={false} />
          <XAxis
            dataKey="date"
            padding={{ left: 20 }}
            tick={tickX}
            tickLine={false}
            axisLine={{ stroke: "rgba(0,0,3,0.32)", strokeWidth: 0.5 }}
            dy={20}
            height={60}
          />
          <YAxis
            tick={tickY}
            tickLine={false}
            axisLine={false}
            orientation="left"
            tickFormatter={(value) => `$${value}`}
          />
          <Tooltip
            labelFormatter={(value) => `Date: ${value}`}
            formatter={(value) => `$${value}`}
          />
          <Area
            type="monotone"
            dataKey="total_sales"
            label="Total Sales"
            stackId="1"
            stroke="#087E86FF"
            fill="#18BAC6"
          />
          <Area
            type="monotone"
            dataKey="total_forecasts"
            label="Total Forecasts"
            stackId="2"
            stroke="#C94AF3FF"
            fill="#C94AF351"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
