import type { ButtonProps } from "@relume_io/relume-ui";
import { RxChevronRight } from "react-icons/rx";

type ImageProps = {
  src: string;
  alt?: string;
};

type SubHeadingProps = {
  title: string;
  description: string;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  subHeadings: SubHeadingProps[];
  buttons: ButtonProps[];
  image: ImageProps;
};

export type Layout4Props = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const FeatureSection = (props: Layout4Props) => {
  const { tagline, heading, description, image, subHeadings } = {
    ...Layout4Defaults,
    ...props,
  } as Props;
  return (
    <section className="feature-section px-[5%] py-16 md:py-24 lg:py-28">
      <div className="text-stone-100 container">
        <div className="grid grid-cols-1 gap-y-12 md:grid-flow-row md:grid-cols-2 md:items-center md:gap-x-12 lg:gap-x-20">
          <div className="w-full h-[20rem] md:h-[30rem] lg:h-[40rem]">
            <img
              src={image.src}
              className="w-full h-full object-cover"
              alt={image.alt}
            />
          </div>
          <div>
            <p className="mb-3 font-semibold md:mb-4">{tagline}</p>
            <h2 className="mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl">
              {heading}
            </h2>
            <p className="mb-6 md:mb-8 md:text-md">{description}</p>
            <div className="grid grid-cols-1 gap-6 py-2 md:grid-cols-2">
              {subHeadings.map((subHeading, index) => (
                <div key={index}>
                  <h6 className="mb-3 text-md font-bold leading-[1.4] md:mb-4 md:text-xl">
                    {subHeading.title}
                  </h6>
                  <p>{subHeading.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Layout4Defaults: Layout4Props = {
  tagline: "",
  heading:
    "Streamline Your Inventory Management with Stocky AI's Powerful System",
  description:
    "Stocky AI's AI-powered First-In-First-Out inventory management system is designed to revolutionize the food and beverage industry. " +
    "With our extensive product and produce database, integrated ordering and invoicing system, and real-time data sharing capabilities, " +
    "businesses in the supply chain can reduce waste, improve order accuracy, and increase efficiency. " +
    "Enhance your traceability by managing lot numbers, expiration dates, and triggering product recalls if necessary.",
  subHeadings: [
    {
      title: "Benefits",
      description:
        "Manage inventory, reduce waste, improve order accuracy, and increase efficiency with Stocky AI. " +
        "Get accurate, real-time insights into your inventory across complex business needs.",
    },
    {
      title: "Features",
      description:
        "Extensive product and produce database, integrated ordering and invoicing system, real-time data sharing, FIFO and traceability.",
    },
  ],
  buttons: [
    { title: "Button", variant: "secondary" },
    {
      title: "Button",
      variant: "link",
      size: "link",
      iconRight: <RxChevronRight />,
    },
  ],
  image: {
    src: "https://relume-assets.s3.amazonaws.com/placeholder-image.svg",
    alt: "Placeholder image",
  },
};
