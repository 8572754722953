import { HarvestItem, Product, ProductUnit } from "../../types";
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import OrderItemEntry from "../OrdersView/OrderItemEntry";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch";
import AddUnitSelectField from "../../components/AddUnitSelectField/AddUnitSelectField";

const HarvestForm = ({
  productList,
  inventoryUnitIds,
  harvestItems,
  setHarvestItems,
  harvestDate,
  setHarvestDate,
  showErrors,
}: {
  productList: Product[];
  inventoryUnitIds: Set<number>;
  harvestItems: HarvestItem[];
  setHarvestItems: (value: HarvestItem[]) => void;
  harvestDate: string;
  setHarvestDate: (value: string) => void;
  showErrors: boolean;
}): React.ReactElement => {
  const toast = useToast();
  const [selectedProductId, setSelectedProductId] = useState<
    number | null | undefined
  >(null);
  const [selectedProductName, setSelectedProductName] = useState<string>("");
  const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const [currentUnits, setCurrentUnits] = useState<ProductUnit[]>([]);

  useEffect(() => {
    if (productList && productList.length > 0 && selectedProductId) {
      const currentProduct = productList.find(
        (product) => product.id === selectedProductId,
      );
      if (currentProduct) {
        const filteredUnits = currentProduct.product_units.filter((unit) =>
          inventoryUnitIds.has(unit.id),
        );
        setCurrentUnits(filteredUnits);
        setSelectedUnitId(filteredUnits?.[0]?.id);
        if (!filteredUnits?.[0]) {
          toast({
            title: "No units available!",
            description:
              "This product does not have any units available for harvest. Please add units to create an order.",
            status: "error",
            isClosable: true,
          });
        }
      } else {
        setCurrentUnits([]);
      }
    } else {
      setCurrentUnits([]);
    }
  }, [selectedProductId]);

  const handleAddToOrder = () => {
    if (selectedProductId && selectedQuantity && selectedUnitId) {
      const product = productList.find(
        (product) => product.id === selectedProductId,
      );
      const productUnit = product?.product_units.find(
        (unit) => unit.id === selectedUnitId,
      );
      if (!product || !productUnit) {
        return;
      }
      // check if item unit already in order, update quantity
      const existingItem = harvestItems.find(
        (item) =>
          item.product_id === product.id &&
          item.product_unit_id === productUnit.id,
      );
      if (existingItem) {
        const newQuantity = existingItem.quantity + selectedQuantity;
        const newOrderItems = harvestItems.map((item) => {
          if (
            item.product_id === product.id &&
            item.product_unit_id === productUnit.id
          ) {
            return {
              product_id: product.id,
              product: product,
              quantity: newQuantity,
              product_unit_id: productUnit.id,
              product_unit: productUnit,
            };
          }
          return item;
        });
        setHarvestItems(newOrderItems);
        return;
      }
      setHarvestItems([
        ...harvestItems,
        {
          product: product,
          quantity: selectedQuantity,
          product_unit: productUnit,
          product_id: product.id,
          product_unit_id: productUnit.id,
        },
      ]);
    }
  };

  const handleEdit = (index: number, quantity: number) => {
    const newOrderItems = harvestItems.map((harvestItem, i) => {
      if (i === index) {
        return {
          ...harvestItem,
          quantity: quantity,
        };
      }
      return harvestItem;
    });
    setHarvestItems(newOrderItems);
  };

  const handleRemove = (index: number) => {
    setHarvestItems(harvestItems.filter((_, i) => i !== index));
  };

  return (
    <Stack spacing={6}>
      <FormControl>
        <FormLabel>Harvest Date</FormLabel>
        <Input
          type="datetime-local"
          value={harvestDate}
          onChange={(e) => setHarvestDate(e.target.value)}
          isInvalid={showErrors && (!harvestDate || harvestDate.length < 1)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Product</FormLabel>
        <InventoryProductSearch
          setProductFilterId={setSelectedProductId}
          setProductName={setSelectedProductName}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Units</FormLabel>
        <HStack>
          <Input
            type="number"
            placeholder="Quantity"
            value={selectedQuantity || ""}
            onChange={(e) => setSelectedQuantity(Number(e.target.value))}
          />
          <AddUnitSelectField
            selectedProductId={selectedProductId}
            selectedProductName={selectedProductName}
            selectedUnitId={selectedUnitId}
            setSelectedUnitId={setSelectedUnitId}
            units={currentUnits}
            setUnits={setCurrentUnits}
          />
        </HStack>
      </FormControl>

      <Button
        isDisabled={!selectedProductId || !selectedUnitId || !selectedQuantity}
        onClick={handleAddToOrder}
      >
        Add to Harvest
      </Button>

      <ul style={{ marginLeft: "1rem" }}>
        {harvestItems.map((item, index) => {
          return (
            <li key={index} style={{ padding: "8px" }}>
              <OrderItemEntry
                itemIndex={index}
                orderItem={item}
                handleQuantityChange={handleEdit}
                handleRemoveItem={handleRemove}
                // preventEdit={preventItemEdits}
              />
            </li>
          );
        })}
      </ul>
    </Stack>
  );
};

export default HarvestForm;
