import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Logo } from "../components/Logo/Logo";
import React, { useState } from "react";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import { usePostRequestResetPasswordMutation } from "../redux/apiSlice";

const PasswordResetRequestPage = (): React.ReactElement => {
  const [email, setEmail] = useState("");
  const toast = useToast();
  const [postResetRequest] = usePostRequestResetPasswordMutation();

  const handleSubmit = () => {
    posthog.capture("password reset request", { username: email });
    postResetRequest({ email })
      .unwrap()
      .then(() => {
        toast({
          title:
            "If an account exists with this email, a password reset email has been sent",
          description: "Please check your email",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        posthog.capture("password reset failed", { username: email });
        toast({
          title:
            "If an account exists with this email, a password reset email has been sent",
          description: "Please check your email",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Container w={"100%"} h={"100%"}>
      <Card
        marginTop={"2rem"}
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
        bg={useColorModeValue("gray.100", "gray.900")}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Logo />
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>
                Request a password reset
              </Heading>
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
              </Stack>
              <Stack spacing="6">
                <Button onClick={handleSubmit} isDisabled={email.length === 0}>
                  Request Reset
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Card>
    </Container>
  );
};

export default PasswordResetRequestPage;
