import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import StackEntry from "../../components/StackEntry/StackEntry";
import StockReconciliationListInput from "./StockReconciliationListInput";
import {
  EventSalesReconciliation,
  EventSalesReconciliationItem,
} from "../../types";
import {
  useGetHarvestedProductsQuery,
  useGetInventoryProductsQuery,
  usePostEventSalesReconciliationMutation,
  usePutEventSalesReconciliationMutation,
} from "../../redux/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import InventoryProductSearch from "../../components/InventoryProductSearch/InventoryProductSearch.tsx";

interface EventSalesRecordModalProps {
  isOpen: boolean;
  onClose: () => void;
  isUpdate?: boolean;
  existingRecord?: EventSalesReconciliation;
}

const EventSalesRecordModal = ({
  isOpen,
  onClose,
  isUpdate = false,
  existingRecord,
}: EventSalesRecordModalProps) => {
  const toast = useToast();
  const { getCurrentLocationId, getCurrentOrganisationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const [selectedProductId, setSelectedProductId] = useState<
    number | null | undefined
  >(null);

  const [cachedItems, setCachedItems] = useState<
    EventSalesReconciliationItem[]
  >(existingRecord?.items || []);

  const [date, setDate] = useState<string>("");
  const [eventName, setEventName] = useState<string>("Market Sales");
  const [hasReconciled, setHasReconciled] = useState<boolean>(
    !!existingRecord?.reconciled_at,
  );
  const [reconciledAt, setReconciledAt] = useState<string | null>(
    existingRecord?.reconciled_at || null,
  );

  const [isPastDate, setIsPastDate] = useState<boolean>(false);

  const { data: products, isLoading: productsLoading } =
    useGetInventoryProductsQuery(organisationId ?? skipToken);

  const [postEventSalesRecord, { isLoading: postEvenLoading }] =
    usePostEventSalesReconciliationMutation();

  const [putEventSalesRecord, { isLoading: putEventLoading }] =
    usePutEventSalesReconciliationMutation();

  const [reconciliationItems, setReconciliationItems] = React.useState<
    EventSalesReconciliationItem[]
  >([]);

  const { data: harvestedProductUnits } = useGetHarvestedProductsQuery(
    { organisationId, locationId },
    { skip: !organisationId || !locationId },
  );

  const [harvestedProductIds, setHarvestedProductIds] = useState<Set<number>>(
    new Set(),
  );

  useEffect(() => {
    if (harvestedProductUnits) {
      const prevHarvestedProductIds = new Set([...harvestedProductIds]);
      harvestedProductUnits.forEach((product) => {
        prevHarvestedProductIds.add(product.id);
      });
      setHarvestedProductIds(prevHarvestedProductIds);
    }
  }, [harvestedProductUnits]);

  useEffect(() => {
    let items: EventSalesReconciliationItem[] = [...cachedItems];
    let productUnitIdToItemMap: Map<number, EventSalesReconciliationItem> =
      new Map(items.map((item) => [item.product_unit_id, item]));
    if (selectedProductId) {
      // if filtered by product, remove cached items that are not for the selected product from display
      items = items.filter((item) => item.product_id === selectedProductId);
      productUnitIdToItemMap = new Map(
        items.map((item) => [item.product_unit_id, item]),
      );
    }
    if (products) {
      products.forEach((product) => {
        if (selectedProductId && product.id !== selectedProductId) {
          return;
        }
        product.product_units.forEach((unit) => {
          if (productUnitIdToItemMap.has(unit.id)) {
            return;
          }
          items.push({
            product,
            product_id: product.id,
            product_unit: unit,
            product_unit_id: unit.id,
          } as EventSalesReconciliationItem);
        });
      });
      setReconciliationItems(
        items.slice().sort((a, b) => {
          const aInHarvest = harvestedProductIds.has(a.product_id);
          const bInHarvest = harvestedProductIds.has(b.product_id);

          if (aInHarvest && !bInHarvest) {
            return -1;
          }
          if (!aInHarvest && bInHarvest) {
            return 1;
          }
          if (a.product && b.product) {
            return a.product.name.localeCompare(b.product.name);
          }
          return a.product_unit_id - b.product_unit_id;
        }),
      );
    } else {
      setReconciliationItems([]);
    }
  }, [
    products,
    existingRecord,
    selectedProductId,
    cachedItems,
    harvestedProductIds,
  ]);

  const handleSetCachedItems = (items: EventSalesReconciliationItem[]) => {
    const cacheUpdate = [...cachedItems];
    items.forEach((item) => {
      const existingItemIndex = cacheUpdate.findIndex(
        (cachedItem) => cachedItem.product_unit_id === item.product_unit_id,
      );
      if (existingItemIndex >= 0) {
        cacheUpdate[existingItemIndex] = item;
      } else {
        cacheUpdate.push(item);
      }
    });
    setCachedItems(cacheUpdate);
  };

  useEffect(() => {
    if (existingRecord) {
      setDate(existingRecord.event_date);
      setEventName(existingRecord.event_name || "");
      setHasReconciled(!!existingRecord.reconciled_at);
      const currentDate = new Date();
      const selectedDate = new Date(existingRecord.event_date);
      setIsPastDate(selectedDate < currentDate);
      setReconciledAt(existingRecord.reconciled_at || "");
    }
  }, [existingRecord]);

  useEffect(() => {
    if (date && date !== existingRecord?.event_date) {
      const currentDate = new Date();
      const selectedDate = new Date(date);
      setIsPastDate(selectedDate < currentDate);
    }
  }, [date]);

  const stepsInitial = [
    { title: "First", description: "Event Information" },
    { title: "Second", description: "Opening Stock" },
  ];

  const [steps, setSteps] = useState(stepsInitial);
  const [sectionOpen, setSectionOpen] = React.useState(true);
  const [isStepOneValid, setIsStepOneValid] = useState<boolean>(false);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    if (hasReconciled) {
      setSteps([
        ...stepsInitial.slice(0, 2),
        { title: "Third", description: "Reconcile" },
      ]);
    } else {
      setSteps(stepsInitial);
    }
  }, [hasReconciled]);

  useEffect(() => {
    setIsStepOneValid(
      !!date && !!eventName && (hasReconciled ? !!reconciledAt : true),
    );
  }, [date, eventName, hasReconciled, reconciledAt]);

  const handleSectionChange = (index: number) => {
    if (index !== activeStep) {
      setSectionOpen(false);
      setTimeout(() => {
        setActiveStep(index);
        setSectionOpen(true);
      }, 400);
    }
  };

  const nextStep = () => {
    if (activeStep < steps.length) {
      handleSectionChange(activeStep + 1);
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      handleSectionChange(activeStep - 1);
    }
  };

  const handleChangeStep = (index: number) => {
    if (index !== activeStep) {
      if (index > activeStep) {
        nextStep();
      } else {
        prevStep();
      }
    }
  };

  const handleSave = () => {
    const payload = {
      location_id: Number(locationId),
      organisation_id: Number(organisationId),
      event_date: date,
      event_name: eventName,
      reconciled_at: hasReconciled ? reconciledAt : null,
      items: cachedItems.filter(
        (item) =>
          !isNaN(Number(item.opening_quantity)) &&
          Number(item.opening_quantity) > 0,
      ),
    } as EventSalesReconciliation;
    if (isUpdate && existingRecord) {
      payload.id = Number(existingRecord.id);
      putEventSalesRecord({ event: payload, items: payload.items })
        .unwrap()
        .then(() => {
          toast({
            title: "Success",
            description: "Event record saved successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          onClose();
        })
        .catch((error) => {
          toast({
            title: "Error - failed to save event record",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
      return;
    } else if (!isUpdate) {
      postEventSalesRecord(payload)
        .unwrap()
        .then(() => {
          toast({
            title: "Success",
            description: "Event record saved successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          onClose();
        })
        .catch((error) => {
          toast({
            title: "Error - failed to save event record",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"5xl"}
      scrollBehavior={"inside"}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent height="100vh" display="flex" flexDirection="column">
        <ModalCloseButton />
        <ModalHeader>{isUpdate ? "" : "Add "}Event Record</ModalHeader>
        <ModalBody
          flex="1"
          overflow="hidden"
          display="flex"
          flexDirection="column"
        >
          <Stepper
            index={activeStep}
            colorScheme={"teal"}
            width={"95%"}
            gap="0"
            marginBottom={"16px"}
            marginX={"auto"}
          >
            {steps.map((step, index) => (
              <Step key={index} onClick={() => handleChangeStep(index)}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          <Collapse in={sectionOpen} animateOpacity>
            {activeStep !== 0 && (
              <InventoryProductSearch
                setProductFilterId={setSelectedProductId}
              />
            )}
            <Box p={[0, 0, 8]}>
              {activeStep === 0 ? (
                <>
                  <Flex direction={["column", "row"]} gap={4}>
                    <StackEntry mb={8}>
                      <FormLabel>Date</FormLabel>
                      <Input
                        type="date"
                        value={date || ""}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </StackEntry>
                    <StackEntry mb={8}>
                      <FormLabel>Event Name</FormLabel>
                      <Input
                        value={eventName || ""}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                    </StackEntry>
                  </Flex>
                  {isPastDate && (
                    <>
                      <Stack direction={"row"} mb={8}>
                        <FormLabel>Has event been reconciled?</FormLabel>
                        <Checkbox
                          isChecked={hasReconciled}
                          onChange={(e) => setHasReconciled(e.target.checked)}
                        />
                      </Stack>
                      {hasReconciled && (
                        <StackEntry mb={8}>
                          <FormLabel>Reconciled At</FormLabel>
                          <Input
                            type="datetime-local"
                            value={reconciledAt || ""}
                            onChange={(e) => setReconciledAt(e.target.value)}
                          />
                        </StackEntry>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {productsLoading ? (
                    <Spinner />
                  ) : (
                    <StockReconciliationListInput
                      reconciliationItems={reconciliationItems}
                      setReconciliationItems={handleSetCachedItems}
                      isOpening={activeStep === 1}
                      productsInStock={harvestedProductIds}
                    />
                  )}
                </>
              )}
            </Box>
          </Collapse>
        </ModalBody>
        <ModalFooter flexDirection="column" width="100%" px={4} py={6}>
          <Flex
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={2}
          >
            <Button
              colorScheme="gray"
              onClick={prevStep}
              flex={{ base: "1 1 100%", sm: "0 1 auto" }}
              mb={{ base: 2, sm: 0 }}
            >
              Back
            </Button>
            <Flex
              gap={2}
              flex={{ base: "1 1 100%", sm: "0 1 auto" }}
              justifyContent={{ base: "space-between", sm: "flex-end" }}
            >
              {activeStep < steps.length - 1 ? (
                <Button
                  onClick={nextStep}
                  isDisabled={!isStepOneValid}
                  flex={{ base: 1, sm: "0 1 auto" }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  colorScheme="teal"
                  onClick={handleSave}
                  isLoading={postEvenLoading || putEventLoading}
                  flex={{ base: 1, sm: "0 1 auto" }}
                >
                  Submit
                </Button>
              )}
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EventSalesRecordModal;
