import {
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import * as React from "react";
import { Location } from "../../types";
import { useEffect } from "react";
import { useGetBuyerLocationsQuery } from "../../redux/apiSlice";
import { userSlice } from "../../redux/userSlice";
import { useAppSelector } from "../../redux/hooks";
import { RiDeleteBack2Line } from "react-icons/ri";
import { getLocationAndOrgName } from "../../utils/invoiceUtils";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "../Autocomplete";

interface CustomerSearchProps {
  setCustomerLocationId?: (id: number | null | undefined) => void;
  setCustomerOrganisationId?: (id: number | null | undefined) => void;
  setCustomerName?: (name: string) => void;
  includeSearchIcon?: boolean;
}

const CustomerSearch = ({
  setCustomerLocationId = () => {},
  setCustomerOrganisationId = () => {},
  setCustomerName = () => {},
  includeSearchIcon = false,
}: CustomerSearchProps): React.ReactElement => {
  const { getCurrentOrganisationId, getCurrentLocationId } =
    userSlice.selectors;
  const organisationId = useAppSelector(getCurrentOrganisationId);
  const locationId = useAppSelector(getCurrentLocationId);

  const { data: buyerLocations, isLoading: isLoadingBuyerLocations } =
    useGetBuyerLocationsQuery({ organisationId, locationId });

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [customerList, setCustomerList] = React.useState<Location[]>([]);

  const [isHoveringDelete, setIsHoveringDelete] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (
      !isLoadingBuyerLocations &&
      buyerLocations &&
      buyerLocations.length > 0
    ) {
      setCustomerList(
        buyerLocations
          .slice()
          .sort((a, b) =>
            getLocationAndOrgName(a).localeCompare(getLocationAndOrgName(b)),
          ),
      );
    } else {
      setCustomerList([]);
    }
  }, [buyerLocations, isLoadingBuyerLocations]);

  const clearSearch = () => {
    setCustomerLocationId(undefined);
    setCustomerOrganisationId(undefined);
    setCustomerName("");
    setSearchTerm("");
  };

  return (
    <AutoComplete openOnFocus>
      <HStack>
        <InputGroup>
          {includeSearchIcon && (
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              <SearchIcon />
            </InputLeftElement>
          )}
          <AutoCompleteInput
            onItemSelect={(value) => {
              setSearchTerm(value);
              setCustomerName(value);
              const location = customerList.find(
                (customer) => getLocationAndOrgName(customer) === value,
              );
              setCustomerLocationId(location?.id);
              setCustomerOrganisationId(location?.organisation_id);
            }}
            placeholder={includeSearchIcon ? "Search for a customer" : ""}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length === 0) {
                clearSearch();
              }
            }}
          />
          <InputRightElement>
            <RiDeleteBack2Line
              style={{ cursor: "pointer", opacity: isHoveringDelete ? 1 : 0.5 }}
              onClick={clearSearch}
              onMouseEnter={() => setIsHoveringDelete(true)}
              onMouseLeave={() => setIsHoveringDelete(false)}
            />
          </InputRightElement>
        </InputGroup>
        <AutoCompleteList>
          {customerList.map((customer: any) => (
            <AutoCompleteItem
              id={`option-${customer.id}`}
              key={`option-${customer.id}`}
              value={getLocationAndOrgName(customer)}
              textTransform="capitalize"
              onClick={() => {
                setSearchTerm(getLocationAndOrgName(customer));
                setCustomerLocationId(customer.id);
                setCustomerOrganisationId(customer.organisation_id);
                setCustomerName(getLocationAndOrgName(customer));
              }}
            >
              {getLocationAndOrgName(customer)}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </HStack>
    </AutoComplete>
  );
};

export default CustomerSearch;
