import { PUBLIC_ROUTES } from "../index";

export const checkPath = (navLocation: any, path: string) => {
  return navLocation.pathname.toLowerCase() === path.toLowerCase();
};

export const checkIsLoginPath = (navLocation: any) => {
  return checkPath(navLocation, "/login");
};

export const checkIsHarvestPath = (navLocation: any) => {
  return checkPath(navLocation, "/harvest");
};

export const checkIsDashboardPath = (navLocation: any) => {
  return checkPath(navLocation, "/dashboard");
};

export const checkIsProductPath = (navLocation: any) => {
  return checkPath(navLocation, "/products");
};

export const checkIsPublicPath = (navLocation: any) => {
  const publicPaths = PUBLIC_ROUTES.map((route) => route.path);
  return publicPaths.includes(navLocation.pathname.toLowerCase());
};
