import { HarvestItem } from "../../types";
import OrderItemEntry from "../OrdersView/OrderItemEntry";
import { Stack, useToast } from "@chakra-ui/react";
import { useDeleteHarvestRecordItemMutation } from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";

interface HarvestSummaryProps {
  items: HarvestItem[];
  setItems: (value: HarvestItem[]) => void;
  preventItemEdits?: boolean;
  onClose: () => void;
}

export default function HarvestSummary({
  items,
  setItems,
  onClose,
}: HarvestSummaryProps) {
  const toast = useToast();
  const [deleteHarvestItem, { isLoading }] =
    useDeleteHarvestRecordItemMutation();

  const handleEdit = (index: number, quantity: number) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          quantity: quantity,
        };
      }
      return item;
    });
    setItems(newItems);
  };

  const handleRemove = (index: number) => {
    deleteHarvestItem(items[index])
      .unwrap()
      .then(() => {
        toast({
          title: "Harvest record deleted.",
          status: "success",
          duration: 1000,
          isClosable: true,
        });
        if (items.length === 1) {
          onClose();
        }
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        toast({
          title: "Delete failed.",
          description:
            "Please check your inputs and try again. If the issue persists please contact support.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    setItems(items.filter((_, i) => i !== index));
  };

  return (
    <>
      <ul style={{ marginLeft: "1rem" }}>
        {items.map((item, index) => {
          return (
            <li key={index} style={{ padding: "8px" }}>
              <OrderItemEntry
                itemIndex={index}
                orderItem={item}
                handleQuantityChange={handleEdit}
                handleRemoveItem={handleRemove}
                preventEdit={true}
                enableDelete={true}
                isDeleteLoading={isLoading}
              />
            </li>
          );
        })}
      </ul>
      <Stack direction="row" style={{ marginTop: "1rem" }}></Stack>
    </>
  );
}
