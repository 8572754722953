import React, { Fragment } from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Invoice, InvoiceItem, Organisation } from "../../types";
import { getProductUnitLabel } from "../../utils/productUnitUtils";
import {
  formatCurrency,
  getInvoiceStatusLabel,
} from "../../utils/invoiceUtils";

interface InvoicePdfProps {
  logo: string;
  title: string;
  invoiceNo: string;
  customerName: string;
  address: string[];
  deliveryAddress: string;
  invoiceDate: string;
  invoice: Invoice;
  organisation: Organisation;
  supplierPhone: string;
  supplierEmail: string;
  buyerPONumber?: string;
  isPickup?: boolean;
  deliveryNote?: string;
}

const InvoicePdf = ({
  logo,
  title,
  invoiceNo,
  customerName,
  address,
  deliveryAddress,
  invoice,
  organisation,
  supplierPhone,
  supplierEmail,
  buyerPONumber,
  deliveryNote,
}: InvoicePdfProps): React.ReactElement => {
  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },

    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },

    alignEnd: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      color: "#3E3E3E",
    },

    alignStart: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      color: "#3E3E3E",
    },

    titleContainer: { flexDirection: "row", marginTop: 24, marginBottom: 0 },

    logo: { width: 140 },

    reportTitle: { fontSize: 14, fontStyle: "bold", textAlign: "center" },

    addressTitle: { fontSize: 12, fontStyle: "bold" },

    invoice: { fontWeight: "bold", fontSize: 20 },

    invoiceNumber: { fontSize: 11, fontWeight: "bold" },

    address: { fontWeight: 400, fontSize: 10 },

    theader: {
      marginTop: 20,
      fontSize: 10,
      fontStyle: "bold",
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: "#DEDEDE",
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    total: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1.5,
      borderColor: "whitesmoke",
      borderBottomWidth: 1,
    },

    tbody2: { flex: 2, borderRightWidth: 1 },
  });

  const InvoiceTitle = () => (
    <View style={styles.titleContainer}>
      <View style={styles.alignStart}>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={styles.alignEnd}>
        <View>
          <Text style={styles.invoice}>Invoice </Text>
          <Text style={styles.invoiceNumber}>Invoice number: {invoiceNo} </Text>
          {buyerPONumber && (
            <Text style={styles.invoiceNumber}>PO number: {buyerPONumber}</Text>
          )}
          <Text style={styles.invoiceNumber}>
            Date: {invoice.delivery_date.slice(0, 10)}
          </Text>
          <Text style={styles.invoiceNumber}>
            Payment Status: {getInvoiceStatusLabel(invoice.status, true)}
          </Text>
        </View>
      </View>
    </View>
  );

  const Address = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.addressTitle}>{title}</Text>
          {address
            ? address.map((line, index) => (
                <Text key={index} style={styles.address}>
                  {line}
                </Text>
              ))
            : null}
          <Text style={styles.address}>{supplierPhone}</Text>
          <Text style={styles.address}>{supplierEmail}</Text>
        </View>
      </View>
    </View>
  );

  const UserAddress = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{ maxWidth: 200 }}>
          <Text style={styles.addressTitle}>Bill to:</Text>
          <Text style={styles.address}>
            {customerName}
            {deliveryNote && deliveryNote.length > 0 ? `, ${deliveryNote}` : ""}
            {deliveryAddress}
          </Text>
        </View>
      </View>
    </View>
  );

  const TableHead = () => (
    <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
      <View style={[styles.theader, styles.theader2]}>
        <Text>Item</Text>
      </View>
      <View style={styles.theader}>
        <Text>Notes</Text>
      </View>
      <View style={styles.theader}>
        <Text>Invoice Qty</Text>
      </View>
      <View style={styles.theader}>
        <Text>Received</Text>
      </View>
      <View style={styles.theader}>
        <Text>Unit</Text>
      </View>
      <View style={styles.theader}>
        <Text>Price</Text>
      </View>
      <View style={styles.theader}>
        <Text>Amount</Text>
      </View>
      <View style={styles.theader}>
        <Text>Credit</Text>
      </View>
    </View>
  );

  const TableBody = () => (
    <>
      {invoice.invoice_items.map((item: InvoiceItem) => (
        <Fragment key={item.id}>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <View style={[styles.tbody, styles.tbody2]}>
              <Text>{item.product.name}</Text>
            </View>
            <View style={styles.tbody}>
              <Text>{item.supplier_notes}</Text>
            </View>
            <View style={styles.tbody}>
              <Text>{item.invoiced_quantity}</Text>
            </View>
            <View style={styles.tbody}>
              {/*<Text>{item.received_quantity === item.invoiced_quantity ? "0" : Number(item.invoiced_quantity) - Number(item.received_quantity)}</Text>*/}
              <Text>{item.received_quantity}</Text>
            </View>
            <View style={styles.tbody}>
              <Text>{getProductUnitLabel(item.product_unit)} </Text>
            </View>
            <View style={styles.tbody}>
              <Text>${formatCurrency(item.price)}</Text>
            </View>
            <View style={styles.tbody}>
              <Text>${formatCurrency(item.total)}</Text>
            </View>
            <View style={styles.tbody}>
              <Text>${formatCurrency(item.credit)}</Text>
            </View>
          </View>
        </Fragment>
      ))}
    </>
  );

  const TableTotal = () => (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={styles.total}>
        <Text></Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.tbody}>
        <Text>Total</Text>
      </View>
      <View style={styles.tbody}>
        <Text>${formatCurrency(invoice.total)}</Text>
      </View>
    </View>
  );

  const TableCredit = () => (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={styles.total}>
        <Text></Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.tbody}>
        <Text>Credit</Text>
      </View>
      <View style={styles.tbody}>
        <Text>${formatCurrency(invoice.credit)}</Text>
      </View>
    </View>
  );

  const TableTotalDue = () => (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={styles.total}>
        <Text></Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.tbody}>
        <Text>Net Total</Text>
      </View>
      <View style={styles.tbody}>
        <Text>${formatCurrency(invoice.total_due || invoice.total)}</Text>
      </View>
    </View>
  );

  const TableBalance = () => (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={styles.total}>
        <Text></Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.tbody}>
        <Text>Balance Due</Text>
      </View>
      <View style={styles.tbody}>
        <Text>${formatCurrency(invoice.balance || 0)}</Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle />
        <Address />
        <UserAddress />
        <TableHead />
        <TableBody />
        <TableTotal />
        <TableCredit />
        <TableTotalDue />
        <TableBalance />

        <View style={styles.tbody}>
          {organisation && organisation.name.includes("Klippers") && (
            <Text>
              *All products are organically grown by Klippers Organics
              Pacs#16-347
            </Text>
          )}
          <Text>Thank you for your business.</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;
