import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/layout";
import React from "react";
import { useAutoCompleteContext } from "../autocomplete-context";

export interface EmptyStateProps extends BoxProps {
  enableAddItem?: boolean;
  addItemLabel?: string;
  onAddItem?: (value: string) => void;
}

export const EmptyState = (props: EmptyStateProps): React.ReactElement => {
  const { enableAddItem, addItemLabel, onAddItem } = props;
  const context = useAutoCompleteContext();
  const { getEmptyStateProps, defaultEmptyStateProps, onClose } = context;

  const style = {
    ...emptyStyles,
    cursor: enableAddItem ? "pointer" : "default",
  };

  const emptyState = getEmptyStateProps(
    <Flex
      {...style}
      {...defaultEmptyStateProps}
      onClick={() => {
        if (onAddItem) {
          onAddItem(context.query);
        }
        onClose();
      }}
    >
      {enableAddItem
        ? `No option found for ${context.query} - \n
        Add a new ${addItemLabel ? addItemLabel : "item"}?`
        : "No options found!"}
    </Flex>,
  );

  return <Box {...props}>{emptyState}</Box>;
};

const emptyStyles: FlexProps = {
  fontSize: "sm",
  align: "center",
  justify: "center",
  fontWeight: "bold",
  fontStyle: "italic",
};
