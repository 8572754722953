import { ComponentMultiStyleConfig } from "@chakra-ui/react";

export const CalendarControl: ComponentMultiStyleConfig = {
  parts: ["controls", "button"],

  baseStyle: {
    controls: {
      position: "absolute",
      p: 4,
      w: "100%",
      justifyContent: "space-between",
    },

    button: {
      h: 8,
      w: 8,
      p: 0,
      lineHeight: 0,
      fontSize: "lg",
      rounded: "md",
      bg: "teal.400",
      color: "white",
      _hover: {
        bg: "teal.300",
      },
    },
  },
};
