import * as React from "react";
import { Box, Container, Heading, HStack, VStack } from "@chakra-ui/react";
import StatsCard from "../../components/StatsCard/StatsCard";
// import SimpleSlider from "./BasketItemCarousel";

// const topSellers = [
//   // {
//   //   name: "Jasmine - Assorted Baklava",
//   //   isSpecial: false,
//   //   isSeasonal: false,
//   //   imageURL:
//   //     "https://storage.googleapis.com/images-sof-prd-9fa6b8b.sof.prd.v8.commerce.mi9cloud.com/product-images/detail/00690813100457.jpg",
//   //   price: 5.99,
//   //   numBaskets: 11,
//   //   unitsSold: 16,
//   // },
//   {
//     name: "Lemon (1pc)",
//     isSpecial: false,
//     isSeasonal: false,
//     imageURL:
//       "https://images.unsplash.com/photo-1572635148818-ef6fd45eb394?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fExlbW9ufGVufDB8fDB8fHww",
//     price: 0.5,
//     numBaskets: 20,
//     unitsSold: 32,
//   },
//   {
//     name: "Asparagus (bunch)",
//     isSpecial: true,
//     specialLabel: "3 for $4",
//     isSeasonal: true,
//     imageURL:
//       "https://images.unsplash.com/photo-1592571799051-f953b68c8ca6?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     price: 1.5,
//     numBaskets: 34,
//     unitsSold: 42,
//   },
//   {
//     name: "Garlic (3pcs)",
//     isSpecial: false,
//     isSeasonal: false,
//     imageURL:
//       "https://images.unsplash.com/photo-1600728255546-742559a92dd0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzh8fGdhcmxpYyUyMGJ1bmNofGVufDB8fDB8fHww",
//     price: 2.0,
//     numBaskets: 25,
//     unitsSold: 38,
//   },
// ];

export default function BasketInfo(): React.ReactElement {
  return (
    <Container py={5} maxW={"container.md"} maxH={"container.md"}>
      <VStack>
        <Heading textColor={"rgba(255,255,255,0.87)"} size={"sm"} as={"h4"}>
          Avg. Basket Value
        </Heading>
        <HStack>
          <StatsCard title={"WTD"} stat={"$ 34.00"} />
          <StatsCard title={"Yesterday"} stat={"$ 32.50"} />
          <StatsCard title={"Today"} stat={"$ 33.00"} />
        </HStack>

        <Heading textColor={"rgba(255,255,255,0.87)"} size={"sm"} as={"h4"}>
          Top Sellers
        </Heading>
      </VStack>
      <Box p={2}>{/*<SimpleSlider items={topSellers} />*/}</Box>
    </Container>
  );
}
