import { Link, useRouteError } from "react-router-dom";
import { Button, Center, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Root from "./Root";

interface ErrorPageProps {
  includeRoot?: boolean;
}

export default function ErrorPage({
  includeRoot = true,
}: ErrorPageProps): React.ReactElement {
  const error404 = new URL("../assets/images/404_page.png", import.meta.url)
    .href;

  const error: unknown = useRouteError();
  if (error) console.error(error);

  return (
    <div id="error-page">
      <Stack>
        {includeRoot && <Root />}
        <Center>
          <Image src={error404} />
        </Center>
        <Center>
          <Text m={4} textColor={"white"}>
            Oops! an unexpected error has occurred.
          </Text>
          <Link to={"/"}>
            <Button>Return to homepage</Button>
          </Link>
        </Center>
      </Stack>
    </div>
  );
}
