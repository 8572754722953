import React, { useState } from "react";
import {
  Box,
  Button,
  Link,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  HStack,
  Checkbox,
  Card,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Logo } from "../../components/Logo/Logo";
import { PasswordField } from "../../components/PasswordField/PasswordField";
import { setIsLoggingIn, userSlice } from "../../redux/userSlice";
import { useAppDispatch } from "../../redux/hooks";
import { usePostUserLoginMutation } from "../../redux/apiSlice";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "../../types";

function LoginPage(): React.ReactElement {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [postLogin] = usePostUserLoginMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  const navigate = useNavigate();

  const handleLoginTelemetry = (email: string, response: LoginResponse) => {
    try {
      posthog.capture("login success", { username: email });
      posthog?.identify(response.user.id.toString(), {
        email: email,
      });
      posthog?.group("organisation", response.organisation.id.toString());
    } catch (error) {
      try {
        Sentry.captureException(JSON.stringify(error));
      } catch {
        Sentry.captureException(error);
      }
    }
  };

  const handleSubmit = () => {
    dispatch(setIsLoggingIn(true));
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    posthog.capture("login attempt", { username: email });
    postLogin(formData)
      .unwrap()
      .then((response) => {
        dispatch(userSlice.actions.login(response));
        handleLoginTelemetry(email, response);
      })
      .catch((error) => {
        try {
          Sentry.captureException(JSON.stringify(error));
        } catch {
          Sentry.captureException(error);
        }
        posthog.capture("login failure", { username: email });
        toast({
          title: "Login failed",
          description: "Please check your email and password",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoginError(true);
        setTimeout(() => {
          setLoginError(false);
        }, 5000);
      })
      .finally(() => {
        dispatch(setIsLoggingIn(false));
      });
  };

  return (
    <Container w={"100%"} h={"100%"}>
      <Card
        marginTop={"2rem"}
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
        bg={useColorModeValue("gray.100", "gray.900")}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Logo />
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>
                Log in to your account
              </Heading>
              <Text>
                Don't have an account?{" "}
                <Link href="/register">
                  {" "}
                  <span className={"underline"}>Sign up</span>
                </Link>
              </Text>
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={loginError}
                    onFocus={() => setLoginError(false)}
                  />
                </FormControl>
                <PasswordField
                  isInvalid={loginError}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  onFocus={() => setLoginError(false)}
                />
              </Stack>
              <HStack justify="space-between">
                <Checkbox defaultChecked>Remember me</Checkbox>
                <Button variant="text" size="sm">
                  <Link onClick={() => navigate("/password-reset")}>
                    Forgot password?
                  </Link>
                </Button>
              </HStack>
              <Stack spacing="6">
                <Button
                  onClick={handleSubmit}
                  isDisabled={email.length === 0 || password.length === 0}
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Card>
    </Container>
  );
}

export default LoginPage;
